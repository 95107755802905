import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AccessTime } from './CheckInOut.styles';
import { Divider } from '@fctg-ds/core';

const to12HourFormat = (time: string) => {
  // converts 14:00 to 2:00 pm
  return dayjs(time, 'HH:mm').format('ha');
};

export interface CheckInOutProps {
  id: string;
  checkInTime: string;
  checkOutTime: string;
}

const CheckInOut: React.FC<CheckInOutProps> = ({ id, checkInTime, checkOutTime }) => {
  const checkInTime12Hr = useMemo(() => to12HourFormat(checkInTime), [checkInTime]);
  const checkOutTime12Hr = useMemo(() => to12HourFormat(checkOutTime), [checkOutTime]);
  const theme = useTheme();
  const iconColor = theme.token.color.neutral.icon[' '].value;
  const textColor = theme.token.color.neutral.text[' '].value;

  return (
    <Stack direction="row" alignItems="center" id={id} data-testid={id}>
      <AccessTime fontSize="small" style={{ color: iconColor }} />
      <Typography data-testid={`${id}-check-in`} variant="bodySm" color={textColor}>
        Check in: {checkInTime12Hr}
      </Typography>
      <Divider style={{ margin: 7 }} variant="dot" color={iconColor} />
      <Typography data-testid={`${id}-check-out`} variant="bodySm" color={textColor}>
        Check out: {checkOutTime12Hr}
      </Typography>
    </Stack>
  );
};

export default CheckInOut;
