import React from 'react';
import { Card, CardMedia, useMediaQuery, Stack } from '@mui/material';
import { Carousel } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import StaysDetailsInfo from '@stays/StaysDetailsInfo';
import { StayContent } from '@src/types/StayContent.types';

interface StayDetailsProps {
  stayContent?: StayContent;
}

const StayDetails: React.FC<StayDetailsProps> = ({ stayContent }) => {
  const screenLg = useMediaQuery(fc.breakpoints.up('md'));
  const MAX_CAROUSEL_IMAGES = 8;

  if (!stayContent) return null;

  return (
    <Stack data-testid="stay-details">
      <Carousel>
        {stayContent.images.slice(0, MAX_CAROUSEL_IMAGES).map((img, i) => (
          <Card key={`stay-details-img-${i}`}>
            <CardMedia component="img" height={screenLg ? '300' : '185'} image={img.url} />
          </Card>
        ))}
      </Carousel>
      <StaysDetailsInfo fullWidth />
    </Stack>
  );
};

export default StayDetails;
