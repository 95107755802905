import React, { useEffect, useRef, useState } from 'react';
import { Box, TypographyProps } from '@mui/material';
import { CustomTypography, ReadMoreBtn, TextExpandStyleProps } from './TextExpand.styles';

export interface TextExpandProps extends Omit<TextExpandStyleProps, 'isExpanded'> {
  id?: string;
  content: string | string[];
  variant: TypographyProps['variant'];
}

const TextExpand: React.FC<TextExpandProps> = ({ id, content, variant, maxRows }) => {
  const [isTruncated, setIsTruncated] = useState(false); // determines whether we show button
  const [isExpanded, setIsExpanded] = useState(false); // controls text rows
  const textRef = useRef<HTMLParagraphElement>();

  useEffect(() => {
    if (textRef.current) {
      // offsetHeight and scrollHeight is equal if the text is not truncated when the
      // component is initially rendered
      setIsTruncated(textRef.current.offsetHeight !== textRef.current.scrollHeight);
    }
  }, [textRef.current, setIsTruncated]);

  return (
    <Box>
      <CustomTypography
        ref={textRef}
        id={id}
        data-testid={id}
        maxRows={maxRows}
        isExpanded={isExpanded}
        variant={variant}
      >
        {Array.isArray(content) ? content.join(' ') : content}
      </CustomTypography>

      {isTruncated && (
        <ReadMoreBtn data-testid={`${id}-read-button`} size="medium" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </ReadMoreBtn>
      )}
    </Box>
  );
};

export default TextExpand;
