import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Typography } from '@mui/material';
import { Flex } from '@common/Flex';
import { ProductRoomRatePricing } from '@src/types/StayDetailsResponse';
import { formatPrice } from '@components/stays/StayRoomOptions/StaysRoomOptionsCard/helpers/utils';

interface PriceSummaryProps {
  pricing: ProductRoomRatePricing;
  nights?: number;
  isRetailStay?: boolean;
  priceDifference?: number;
}

export const PriceSummary: React.FC<PriceSummaryProps> = ({ pricing, priceDifference, nights, isRetailStay }) => {
  const price = priceDifference !== undefined ? priceDifference : pricing?.total;
  const formattedPrice = formatPrice(price, pricing?.currency, priceDifference !== undefined ? 'exceptZero' : 'never');

  return (
    <Flex column data-testid="roomPriceSummary">
      {price >= 0 ? (
        <Typography variant={isRetailStay ? 'priceMd' : 'priceLg'}>{formattedPrice}</Typography>
      ) : (
        <TextLowerPrice variant={isRetailStay ? 'priceMd' : 'priceLg'}>{formattedPrice}</TextLowerPrice>
      )}
      {!isRetailStay && <TextGrey variant="captionSm">{`Total price for ${nights} nights`}</TextGrey>}
    </Flex>
  );
};

const TextLowerPrice = styled(Typography)(({ theme }) => ({
  color: theme.token.color.success.text[' '].value,
}));

const TextGrey = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));
