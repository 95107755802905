import React from 'react';
import store from '@src/store';
import { hydrate } from 'react-dom';
import { Bootstrap } from './bootstrap.types';
import { Provider } from 'react-redux';

import { CacheProvider } from '@emotion/react';
import createEmotionCache from '@src/utils/createEmotionCache';
import { FCThemeProvider } from '@fctg-ds/theme';

const bootstrap: Bootstrap = async (list, { clients, noConfig = false }) => {
  const config = noConfig ? {} : await clients.bff.config();
  const components = document.querySelectorAll<HTMLElement>(`*[data-microui=${SERVICE_SDK_NAME}]`);

  components.forEach((el) => {
    const props = JSON.parse(el.dataset.microuiProps || '{}');
    const component = (el.dataset.microuiComponent as string) || '';
    const ReactComponent = list[component.toLowerCase()];
    if (!ReactComponent) throw Error(`Component ${ReactComponent} was not found`);
    hydrate(
      <Provider store={store}>
        <CacheProvider value={createEmotionCache()}>
          <FCThemeProvider cssBaseline={false}>
            <ReactComponent config={config} {...props} />
          </FCThemeProvider>
        </CacheProvider>
      </Provider>,
      el,
    );
  });
  window.dispatchEvent(new CustomEvent('MicroFrontendLoaded', { detail: { name: SERVICE_SDK_NAME, config } }));
  return config;
};

export default bootstrap;
