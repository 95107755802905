import React from 'react';
import Typography from '@mui/material/Typography';

import CheckInOut from '@components/ds-candidates/CheckInOut';
import useStyles, { StayTermsAndConditionsStyleProps } from './StayTermsAndConditions.styles';
import { Grid, Stack } from '@mui/material';
import { useBreakpointMapper } from '@fctg-ds/core';
import { Title } from './components/Title';
import { Cancellation } from './components/Cancellation';
import { mappedDescriptions } from './mapper/TermsAndConditionsTitleMapper';
import { useContentContext } from '@src/context/ContentContext';

/**
 * @deprecated
 */
const StaysTermsAndConditions: React.FC<StayTermsAndConditionsStyleProps> = ({ fullWidth = false }) => {
  const { classes } = useStyles({ fullWidth });
  const { stayContent } = useContentContext();

  const [gridBreakpointValue] = useBreakpointMapper({
    xs: { columnWidth: 12, width: '100%' },
    sm: { columnWidth: 12, width: '100%' },
    md: { columnWidth: 12, width: '100%' },
    lg: { columnWidth: 12, width: '100%' },
    xl: { columnWidth: 12, width: '50%' },
  });

  const haveKnowBeforeYouGo: boolean =
    (stayContent?.mappedDescriptions && stayContent?.mappedDescriptions['know before you go'] !== undefined) || false;

  const generateDescriptions = Object.keys(mappedDescriptions).map((key) => {
    const filteredDescriptions = stayContent?.mappedDescriptions ? stayContent?.mappedDescriptions[key] : [];

    const descriptionsComponent = filteredDescriptions?.map((description, index) => (
      <Typography key={`stays-tc-desc-key-${index}`} variant={'bodySm'}>{`${description}${
        description.endsWith('.') ? '' : '.'
      }`}</Typography>
    ));

    return (
      filteredDescriptions?.length > 0 && (
        <Grid item xs={gridBreakpointValue.columnWidth} key={`grid-item-${key}`} mb={4} style={{ paddingLeft: 0 }}>
          <Stack spacing={2}>
            <Title title={mappedDescriptions[key]} />
            {haveKnowBeforeYouGo && mappedDescriptions[key] === 'Know before you go' && (
              <CheckInOut
                id="stays-checkin-checkout"
                checkInTime={stayContent.checkInTime}
                checkOutTime={stayContent.checkOutTime}
              />
            )}
            {descriptionsComponent}
          </Stack>
        </Grid>
      )
    );
  });

  if (!stayContent?.hotelId) return <></>;
  return (
    <Stack data-testid="stays-tc" className={classes.root} spacing={1} style={{ width: gridBreakpointValue.width }}>
      <Typography variant={'headingMd'} data-testid="stays-tc-policy-header">
        Policies &amp; essential info
      </Typography>

      {!haveKnowBeforeYouGo && (
        <CheckInOut
          id="stays-checkin-checkout"
          checkInTime={stayContent.checkInTime}
          checkOutTime={stayContent.checkOutTime}
        />
      )}
      <Grid container data-testid="stays-tandc-grid-container" spacing={1} mt={0} ml={0}>
        {generateDescriptions}

        <Grid item xs={gridBreakpointValue.columnWidth} style={{ paddingLeft: 0 }}>
          <Cancellation
            title={'Cancellation policies'}
            description={
              'Cancellation policies vary depending on the room type and airfare chosen. Please check the cancellation terms that apply to your room and flight when making your selection.'
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default StaysTermsAndConditions;
