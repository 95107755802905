import React from 'react';
import { TContentState, useContentContext } from './ContentContext';
import { TShoppingState, TShoppingStateAPI, useShoppingAPI, useShoppingContext } from './ShoppingContext';
import { TFlowState, TFlowAPI, useFlowAPI, useFlowContext } from './FlowContext';
import { TOfferingState, TOfferingAPI, useOfferingAPI, useOfferingContext } from './OfferingContext';
import useSDK from '@src/hooks/useSDK';
export enum Contexts {
  ALL,
  SHOPPING,
  SHOPPING_API,
  CONTENT,
  FLOW,
  FLOW_API,
  OFFERING,
  OFFERING_API,
}

type TPropState = {
  shopping: TShoppingState;
  content: TContentState;
  flow: TFlowState;
  offering: TOfferingState;
  api: {
    shopping: TShoppingStateAPI;
    flow: TFlowAPI;
    offering: TOfferingAPI;
  };
  stapiReady: boolean;
};

interface IProps {
  children: (props: TPropState) => React.ReactNode;
  enabled: Contexts[];
}

const ContextToProps: React.FC<IProps> = ({ children, enabled }) => {
  const [stapiSdk, stapiState, stapiReady] = useSDK('stapi');
  const contentState = useContentContext();
  const shoppingState = useShoppingContext();
  const shoppingApi = useShoppingAPI();
  const flowState = useFlowContext();
  const flowApi = useFlowAPI();
  const offeringState = useOfferingContext();
  const offeringApi = useOfferingAPI();

  const props: TPropState = {
    shopping: enabled.includes(Contexts.SHOPPING || Contexts.ALL) ? shoppingState : undefined,
    content: enabled.includes(Contexts.CONTENT || Contexts.ALL) ? contentState : undefined,
    flow: enabled.includes(Contexts.FLOW || Contexts.ALL) ? flowState : undefined,
    offering: enabled.includes(Contexts.OFFERING || Contexts.ALL) ? offeringState : undefined,
    api: {
      shopping: enabled.includes(Contexts.SHOPPING_API) ? shoppingApi : undefined,
      flow: enabled.includes(Contexts.FLOW_API) ? flowApi : undefined,
      offering: enabled.includes(Contexts.OFFERING_API) ? offeringApi : undefined,
    },
    stapiReady,
  };

  return <>{children(props)}</>;
};

export default ContextToProps;
