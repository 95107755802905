import Typography from '@mui/material/Typography';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const LabelText = styled(Typography)(({ theme }) => ({
  marginLeft: `${theme.spacing(1)} !important`,
  [theme.breakpoints.down('md')]: {
    fontSize: `${theme.typography.bodySm.fontSize}px !important`,
  },
  whiteSpace: 'nowrap',
}));
const BoxWrapper = styled(Box)<{ grow: boolean }>(({ grow, theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  color: theme.token.color.neutral.text[' '].value,
  ['p']: {
    flexGrow: grow ? 1 : 'inherit',
  },
}));

export { LabelText, BoxWrapper };
