import React, { useEffect } from 'react';
import ResultsPage, { ResultsPageProps } from './ResultsPage';
import dayjs from 'dayjs';
import ContextToProps, { Contexts } from '@src/context/ContextToProps';
import ShoppingProvider from '@src/context/ShoppingContext';
import FlowProvider, { IDestinationServiceContent } from '@src/context/FlowContext';

interface IResultsPageContainerProps extends ResultsPageProps {
  content: IDestinationServiceContent;
  selectedStay: string;
  queryParams?: Record<string, string | string[]>;
}

/**
 * @deprecated
 */
const ResultsPageContainer: React.FC<IResultsPageContainerProps> = ({
  onAction,
  queryParams,
  content,
  selectedStay,
}) => {
  return (
    <FlowProvider dsContent={content} productId={selectedStay} expedia={queryParams?.expedia}>
      <ShoppingProvider>
        <ContextToProps enabled={[Contexts.SHOPPING, Contexts.SHOPPING_API, Contexts.FLOW, Contexts.FLOW_API]}>
          {({
            shopping: {
              preselectedFlight,
              stayContent,
              isContentLoading,
              isAvailabilityLoading,
              filters,
              sort,
              availability,
            },
            flow: {
              departureAirport,
              destination,
              startDate,
              nights,
              travellers,
              coords,
              isFetchingPreselectedFlight,
              geoId,
              selectedStayId,
            },
            api: {
              shopping: {
                filters: {
                  getStarsResultCount,
                  setStars,
                  getPriceResultCount,
                  setPriceRange,
                  getPriceStats,
                  getChartData,
                  getWeightedAmenities,
                  setAmenities,
                },
                sort: { setSortType },
              },
              flow: { setDepartureAirport, setStartDate, setEndDate, setNights, setTravellers, setCabinClass },
            },
            stapiReady,
          }) => {
            useEffect(() => {
              const startDate: string | string[] = queryParams?.startDate;
              const nights: string | string[] = queryParams?.nights;
              const adults: string | string[] = queryParams?.adults;
              const origin: string | string[] = queryParams?.origin;

              let _nights: number;
              let _adults: number;
              const rawStartDate = Array.isArray(startDate) ? startDate?.[0] : startDate;
              const _startDate = dayjs(rawStartDate).isValid() ? rawStartDate : undefined;
              const _originParam = Array.isArray(origin) ? origin?.[0] : origin;

              try {
                if (nights) _nights = parseInt(Array.isArray(nights) ? nights[0] : nights);
                if (adults) _adults = parseInt(Array.isArray(adults) ? adults[0] : adults);
              } catch (error) {
                if (process.env.NODE_ENV === 'development') console.error(error);
              }

              if (_startDate) setStartDate(_startDate);
              if (_nights) setNights(_nights);
              if (_adults) setTravellers({ adult: _adults });
              if (_originParam) setDepartureAirport(_originParam);
            }, [stapiReady]);

            return (
              <ResultsPage
                onAction={onAction}
                stayContent={stayContent}
                geoId={geoId}
                travellers={travellers}
                availability={availability}
                isContentLoading={isContentLoading}
                preselectedFlight={preselectedFlight}
                departureAirport={departureAirport}
                destination={destination}
                startDate={startDate}
                nights={nights}
                coords={coords}
                selectedStayId={selectedStayId}
                isAvailabilityLoading={isAvailabilityLoading}
                isFetchingPreselectedFlight={isFetchingPreselectedFlight}
                filters={filters}
                getStarFilterResultCount={getStarsResultCount}
                setFilterStars={setStars}
                getPriceFilterResultCount={getPriceResultCount}
                setFilterPriceRange={setPriceRange}
                getFilterPriceStats={getPriceStats}
                getChartData={getChartData}
                getWeightedAmenities={getWeightedAmenities}
                setAmenities={setAmenities}
                sort={sort}
                setSortType={setSortType}
                setDepartureAirport={setDepartureAirport}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setNights={setNights}
                setTravellers={setTravellers}
                setCabinClass={setCabinClass}
              />
            );
          }}
        </ContextToProps>
      </ShoppingProvider>
    </FlowProvider>
  );
};

export default ResultsPageContainer;
