// @IMPORTANT! Keep this at the top always
// this will ensure that any chunked/split files will be imported from the correct URL
const bff = new URL(((document as Document)?.currentScript as HTMLScriptElement)?.src || '').origin;
__webpack_public_path__ = bff + '/';

import store from '@src/store';
import getEndpointsClient from '@endpoints/client/getEndpointsClient';
import createMicroFrontend from '@libs/serverless-micro-ui/createMicroFrontend';
import { SDKClients } from '@src/sdk.types';

// MICRO FRONTEND COMPONENTS
import ProductPage from '@public/ProductPage';
import ResultsPage from '@public/ResultsPage';

import ImageGalleryModal from '@public/ImageGalleryModal';
import InclusionsList from '@public/InclusionsList';
import StaysSummary from '@public/StaysSummary';
import StayAmenities from '@public/StayAmenities';
import StaysDetailDrawer from '@public/StaysDetailDrawer';
import StaysDetailModal from '@public/StaysDetailModal';

import RoomDetails from '@public/RoomDetails';
import StayDetailsView from '@public/StaysDetailsView';
import StaysDetails from '@public/StaysDetails';
import RoomOptionsView from '@components/stays/RoomOptionsView';
import StayAlternativesSingleton from '@public/RetailStayOptions';

// PUBLIC COMPONENT LIST
// The list of components this micro frontend exposes
export const components = {
  ProductPage,
  ResultsPage,
  ImageGalleryModal,
  InclusionsList,
  StaysSummary,
  StayAmenities,
  StaysDetailDrawer,
  StaysDetailModal,
  StaysDetails,
  RoomDetails,
  StayDetailsView,
  RoomOptionsView,
  StayAlternativesSingleton,
};

// UI TO API CLIENT SETUP
// Normally this should just be the backend for frontend client
export const clients: SDKClients = {
  bff: getEndpointsClient({ url: bff }),
};

createMicroFrontend(SERVICE_SDK_NAME, { components, store, clients, options: { noConfig: true } });
