import { makeStyles } from 'tss-react/mui';

export interface StayTermsAndConditionsStyleProps {
  fullWidth?: boolean;
}

const useStyles = makeStyles<StayTermsAndConditionsStyleProps>()((theme, { fullWidth }) => ({
  root: {
    maxWidth: fullWidth ? '100%' : theme.breakpoints.values.lg,
  },
}));

export default useStyles;
