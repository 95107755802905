import React from 'react';
import { fc, styled } from '@fctg-ds/theme';
import { FormControl, FormControlLabel, FormLabel, RadioGroup, Stack, Typography } from '@mui/material';
import { Radio } from '@fctg-ds/core';
import { formatPrice, getLabel } from '../helpers/utils';
import { ProductRoomRate } from '@src/types/StayDetailsResponse';
import { ProductRoomAttributeKey } from '../../StaysRoomOptions.types';

interface IRoomAttributeItem {
  onSelect: (rateKey: ProductRoomAttributeKey, choiceKey: string) => void;
  defaultValue: string;
  rateKey: ProductRoomAttributeKey;
  attributes: ProductRoomRate[];
  title?: string;
}

/**
 * @deprecated
 */
export const RoomAttributeItem: React.FC<IRoomAttributeItem> = ({
  title,
  rateKey,
  attributes = [],
  defaultValue,
  onSelect,
}) => {
  const priceDiff = (rate: ProductRoomRate) => {
    const diff = rate.choicePriceDifference !== undefined ? rate.choicePriceDifference : rate.pricing.total;
    const formattedDiff = formatPrice(diff, rate.pricing.currency);

    return formattedDiff;
  };

  return (
    <Stack spacing={fc.spacing(1)}>
      <FormControl>
        <FormLabel id={`${rateKey}-attribute`}>
          {title && <Typography variant="captionMd">{title}</Typography>}
        </FormLabel>
        <RadioGroup
          aria-labelledby={`${rateKey}-attribute`}
          onChange={(e) => onSelect(rateKey, e.target.value)}
          name={`${rateKey}-attribute`}
        >
          {attributes.map((att) => (
            <Stack direction="row" key={`Stack-${rateKey}-${att.choiceKey}`}>
              <CustomFormControlLabel
                control={
                  <Radio
                    size="small"
                    label={getLabel(att, rateKey)}
                    value={att.choiceKey}
                    checked={att.choiceKey === defaultValue}
                    name={`radio-${att.choiceKey}`}
                  />
                }
                label={<CustomTypography variant="bodySmStrong">{priceDiff(att)}</CustomTypography>}
              />
            </Stack>
          ))}
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

const CustomFormControlLabel = styled(FormControlLabel)({
  width: '100%',
  justifyContent: 'space-between',
  margin: 0,
});

const CustomTypography = styled(Typography)({
  whiteSpace: 'nowrap',
});
