import { makeStyles } from 'tss-react/mui';

const clickable = (theme) => ({});

const useStyles = makeStyles()((theme) => ({
  listStyle: {
    columnCount: 2,
    columnGap: 20,
    [theme.breakpoints.only('xs')]: {
      columnCount: 1,
    },
  },
  labelText: {},
  descriptionText: {
    color: theme.token.color.text.weak[' '].value,
  },
  textClickable: {
    padding: '0 !important',
    marginLeft: `${theme.token.spacing[1].value} !important`,
    display: 'inline-flex !important',
  },
  seeAllInclusionsBtn: {
    minHeight: '40px',
    borderRadius: theme.spacing(1.5),
    borderColor: theme.token.color.border[' '].value,
    textTransform: 'unset',
    top: theme.spacing(8),
    margin: `${theme.spacing(4)} !important`,
    [theme.breakpoints.up('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '49%',
    },
  },
  textBox: {
    marginLeft: `${theme.spacing(4)} !important`,
  },
}));

export default useStyles;
