import { Button } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const AmenitiesContainer = styled(Box)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`,
  marginBottom: theme.spacing(2),
}));

const ReadMoreBtn = styled(Button)(({ theme }) => ({
  backgroundColor: `transparent !important`,
  paddingLeft: `${0}px !important`,
  paddingRight: `${0}px !important`,
}));

export { AmenitiesContainer, ReadMoreBtn };
