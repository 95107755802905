import React from 'react';
import MicroComponent from '@src/../dev/common/MicroComponent';
import { ActionEvents, OnActionType } from '@src/types';
import { FlightInfoContainer } from './FlightInfoStyles';
import FlightState from '@stays/FlightState';
import { TFlightData } from '@src/types/flights.types';

export interface FlightInfoProps {
  onAction: OnActionType;
  isDIYPackage?: boolean;
  preselectedFlight: TFlightData;
  departureAirport: string;
  destination: string;
  startDate: string;
  nights: number;
  isFetchingPreselectedFlight: boolean;
  onEmptyFlightStateBtnClick: OnActionType;
}

/**
 * @deprecated
 */
const FlightInfo: React.FC<FlightInfoProps> = ({
  onAction,
  isDIYPackage = true,
  preselectedFlight,
  departureAirport,
  destination,
  startDate,
  nights,
  isFetchingPreselectedFlight,
  onEmptyFlightStateBtnClick,
}) => {
  const flightState = {
    hasAirports: !!departureAirport && !!destination,
    hasDates: !!(startDate && nights),
    isFetchingPreselectedFlight,
    hasPreselectedFlight: !!preselectedFlight && isDIYPackage,
  };

  if (!preselectedFlight) {
    return <FlightState onAction={onEmptyFlightStateBtnClick} {...flightState} />;
  }

  return (
    <FlightInfoContainer>
      <MicroComponent
        product={preselectedFlight.frankFlightObject}
        micro={{ sdkName: 'OmniFlightsShoppingUI', componentName: 'FlightInfo' }}
        onAction={() => onAction({ type: ActionEvents.FLIGHT_CLICK, data: 'flightClick' })}
      />
      <MicroComponent
        product={preselectedFlight.frankFlightObject}
        micro={{ sdkName: 'OmniFlightsShoppingUI', componentName: 'FlightBaggageSummary' }}
        onAction={() => onAction({ type: ActionEvents.FLIGHT_CLICK, data: 'flightClick' })}
      />
    </FlightInfoContainer>
  );
};

export default FlightInfo;
