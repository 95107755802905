import { Box, CardMedia, Stack } from '@mui/material';
import { styled } from '@fctg-ds/theme';

const OptionImage = styled(CardMedia)<{ height?: string | number }>(({ theme, height }) => ({
  borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0 0`,
  height: height ?? theme.spacing(30),
}));

const ImageContainer = styled(Box)<{ height?: number | string }>(({ theme, height }) => ({
  height: height ?? theme.spacing(30),
}));

const OptionContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== 'animate' })<{
  fullWidth?: boolean;
}>(({ theme, fullWidth }) => ({
  cursor: 'pointer',
  transition: 'all 0.5s ease',

  borderRadius: theme.token.radius.base.value,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: theme.token.color.border.disabled.value,

  minWidth: 270,
  maxWidth: fullWidth ? '100%' : 400,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  borderRadius: `0 0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export { OptionImage, ImageContainer, OptionContainer, ContentContainer };
