import React, { useMemo, useState } from 'react';
import { ScrollList, useBreakpointMapper, ResponsiveList } from '@fctg-ds/core';
import { Box, Typography } from '@mui/material';
import StaysRoomOptionsCard from '@stays/StayRoomOptions/StaysRoomOptionsCard';
import type { ProductRoomChoice, ProductRoomRate, ProductStay } from '@src/types/StayDetailsResponse';
import type { StayContent } from '@src/types/StayContent.types';
import { ActionEvents, OnActionType } from '@src/types';
import RoomOptionsFooter from './RoomOptionsFooter';

interface RoomOptionsViewProps {
  product: ProductStay;
  stayContent: StayContent;
  onAction: OnActionType;
}

/**
 * @deprecated Not being utilized on production
 */
const RoomOptionsView: React.FC<RoomOptionsViewProps> = ({ product, stayContent, onAction }) => {
  const [bpValues] = useBreakpointMapper({
    xs: { isMobile: true, isXs: true },
    sm: { isMobile: true },
    md: { isTablet: true },
    lg: {},
    xl: {},
  });
  const productRoomSelection = product.rooms.selected?.[0];

  if (!productRoomSelection) {
    console.error('Unable to find selected room info');
    return null;
  }

  const isSelected = (room: ProductRoomChoice, choiceKey: string) =>
    room.rates.some((roomRate) => roomRate.choiceKey === choiceKey);

  const selectedRoom: ProductRoomChoice = product.rooms.choices.find((room) =>
    isSelected(room, productRoomSelection.choiceKey),
  );

  if (!selectedRoom) {
    console.error('Unable to find selected room');
    return null;
  }

  const [localSelectedRate, setLocalSelectedRate] = useState(
    selectedRoom.rates.find((rate) => rate.choiceKey === productRoomSelection.choiceKey),
  );

  const notSelectedRooms = useMemo(
    () =>
      product.rooms.choices.filter((room) =>
        room.rates.every((rate) => rate.choiceKey !== productRoomSelection.choiceKey),
      ),
    [product, productRoomSelection],
  );

  const onSelect: OnActionType = ({ type, data }) => {
    switch (type) {
      case ActionEvents.STAY_ROOM_RATE_CLOSE:
        onAction({ type: ActionEvents.STAY_ROOM_RATE_CLOSE });
        break;
      case ActionEvents.STAY_ROOM_RATE_SELECTION:
        onAction({
          type: ActionEvents.STAY_ROOM_RATE_SELECTION,
          data: { productId: product.productId, choiceKey: (data as ProductRoomRate).choiceKey },
        });
        break;
      case ActionEvents.STAY_ROOM_ATTRIBUTES_SELECTION:
        onAction({ type: ActionEvents.STAY_ROOM_ATTRIBUTES_SELECTION, data: (data as ProductRoomRate).choiceKey });
        break;
      case ActionEvents.STAY_ROOM_OPEN:
        onAction({
          type: ActionEvents.STAY_ROOM_OPEN,
          data: { product, choiceKey: (data as ProductRoomRate).choiceKey },
        });
        break;

      default:
        break;
    }
  };

  if (bpValues.isMobile) {
    return (
      <Box>
        <StaysRoomOptionsCard
          key={selectedRoom.roomId}
          fallbackImage={stayContent.images?.[0].url}
          nights={product.trip.duration.value}
          initalSelection={productRoomSelection}
          stayRoom={selectedRoom}
          selectedBorder={isSelected(selectedRoom, localSelectedRate.choiceKey)}
          onSelectRate={setLocalSelectedRate}
          onAction={onSelect}
        />
        <Typography
          component="p"
          variant="bodyLgStrong"
          sx={(theme) => ({
            margin: `${theme.spacing(4)} 0`,
          })}
        >
          {`${notSelectedRooms.length} alternative stay option${notSelectedRooms.length !== 1 ? 's' : ''}`}
        </Typography>
        <ScrollList autoWidth spacing={bpValues.isXs && 3}>
          {notSelectedRooms.map((room) => (
            <StaysRoomOptionsCard
              key={room.roomId}
              fallbackImage={stayContent.images?.[0].url}
              nights={product.trip.duration.value}
              initalSelection={productRoomSelection}
              stayRoom={room}
              selectedBorder={isSelected(room, localSelectedRate.choiceKey)}
              onSelectRate={setLocalSelectedRate}
              onAction={onSelect}
            />
          ))}
        </ScrollList>
        <RoomOptionsFooter
          selected={localSelectedRate}
          onAction={onSelect}
          pendingChange={localSelectedRate.choiceKey !== productRoomSelection.choiceKey}
        />
      </Box>
    );
  }

  return (
    <Box>
      <ResponsiveList
        displayMd={bpValues.isTablet && 1}
        displayLg={!bpValues.isTablet && 2}
        displayXl={!bpValues.isTablet && 2}
        peek={bpValues.isTablet ? 32 : 16}
        peekAll
      >
        {[...product.rooms.choices]
          // Sorts by whether or not a room is selected in the cart. (puts the selected room at the head)
          .sort((a, b) => +(b.roomId === selectedRoom.roomId) - +(a.roomId === selectedRoom.roomId))
          .map((room) => (
            <StaysRoomOptionsCard
              key={room.roomId}
              fallbackImage={stayContent.images?.[0].url}
              nights={product.trip.duration.value}
              initalSelection={productRoomSelection}
              stayRoom={room}
              selectedBorder={isSelected(room, localSelectedRate.choiceKey)}
              onSelectRate={setLocalSelectedRate}
              onAction={onSelect}
            />
          ))}
      </ResponsiveList>
      <RoomOptionsFooter
        selected={localSelectedRate}
        onAction={onSelect}
        pendingChange={localSelectedRate.choiceKey !== productRoomSelection.choiceKey}
      />
    </Box>
  );
};

export default RoomOptionsView;
