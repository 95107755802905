import { TLayoutDef, TImage } from './ImageQuilt.types';

// returns the layout that either matches the input or is the closest to (floor)
// i.e. [1, 3, 5] -> 1 || 2 = 1, 3 || 4 = 3, 100 = 5 ... and so on
export const findClosestConfiguredLayoutThatFitsAllImages = (
  layoutDef: TLayoutDef[],
  imagesCount: number,
  imagesToDisplay?: number,
  overrides?: TLayoutDef[],
): TLayoutDef => {
  const _imagesToDisplay = imagesToDisplay ?? imagesCount;
  const _layoutDef = [
    ...new Map(layoutDef.concat(overrides ?? []).map((item: TLayoutDef) => [item.imageCount, item])).values(),
  ];
  return _layoutDef.reduce((prev: TLayoutDef, cur: TLayoutDef) => {
    if (Math.abs(cur.imageCount - _imagesToDisplay) <= Math.abs(prev.imageCount - _imagesToDisplay)) {
      return cur.imageCount <= _imagesToDisplay ? cur : prev;
    }
    return prev;
  });
};

export const getImage = (images: TImage[], index: number) => {
  if (index > images.length - 1) {
    return { url: 'https://via.placeholder.com/1024x690.png?text=no image data', description: 'No Image Data' };
  }
  return images[index];
};
