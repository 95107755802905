import React from 'react';
import { SelectedBorder, SelectedLabel } from './SelectedStayBorder.styles';

interface SelectedStayWrapperProps {
  children: React.ReactNode;
  selected?: boolean;
  label?: string;
  hasBorder?: boolean;
}

const SelectedStayWrapper: React.FC<SelectedStayWrapperProps> = ({
  children,
  selected = true,
  label = 'Selected stay',
  hasBorder,
}) => {
  if (!selected) return <>{children}</>;

  return (
    <SelectedBorder hasBorder={hasBorder}>
      <SelectedLabel variant="subheadingSm" textAlign="center">
        {label}
      </SelectedLabel>
      {children}
    </SelectedBorder>
  );
};

export default SelectedStayWrapper;
