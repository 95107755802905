import React from 'react';
import { Typography, Box } from '@mui/material';
import { SectionHeading, ProductDetailsBox, StyledList } from './NotesContent.styles';
import { StayDetailsResponse } from '@src/types/StayDetailsResponse';
import { stripHtmlTags } from '@src/utils/helpers';

type NotesContentProps = Pick<StayDetailsResponse, 'notes'>;

const NotesContent = ({ notes }: NotesContentProps) => {
  const trimText = (note) => note?.heading?.toLowerCase().replaceAll('_', ' ');
  return (
    <ProductDetailsBox data-testid="product-notes-content">
      {notes?.map((heading, index) => {
        return (
          <Box key={index}>
            <SectionHeading variant="bodySmStrong" color="text.primary">
              {trimText(heading)}
            </SectionHeading>
            <StyledList>
              {heading?.sections &&
                heading.sections.map((section, index) => (
                  <li key={index}>
                    <Typography variant="bodySm">{stripHtmlTags(section)}</Typography>
                  </li>
                ))}
            </StyledList>
          </Box>
        );
      })}
    </ProductDetailsBox>
  );
};
export default NotesContent;
