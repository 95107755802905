import React, { useMemo } from 'react';
import MicroComponent from '@src/../dev/common/MicroComponent';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import { TFlowAPI, TFlowState } from '@context/FlowContext';
import { ResultsSearchContainer } from './ResultsSearchStyles';
import { ActionEvents } from '@src/types';
import dayjs from 'dayjs';

interface ResultsSearchProps extends TFlowAPI, Omit<TFlowState, 'endDate' | 'geoId'> {
  emptyFlightBtnClickWhileNoOriginAndDates: boolean;
  emptyFlightBtnCallback: () => void;
}

/**
 * @deprecated
 */
const ResultsSearch: React.FC<ResultsSearchProps> = ({
  setDepartureAirport,
  setStartDate,
  setNights,
  setTravellers,
  setCabinClass,
  startDate,
  travellers,
  cabinClass,
  departureAirport,
  destination,
  nights,
  emptyFlightBtnClickWhileNoOriginAndDates,
  emptyFlightBtnCallback,
}) => {
  const onAction = ({ type, data }) => {
    switch (type) {
      case ActionEvents.SEARCH_UPDATE_ORIGIN:
        setDepartureAirport(data?.value);
        if (!cabinClass) {
          setCabinClass('economy');
        }
        if (emptyFlightBtnClickWhileNoOriginAndDates) {
          emptyFlightBtnCallback();
          document.getElementById('omni-search-mofo/stay-search-form-fixed/date-textfield')?.click();
        }
        break;
      case ActionEvents.SEARCH_UPDATE_DESTINATION:
        break;
      case ActionEvents.SEARCH_UPDATE_DATES:
        setStartDate(data?.departDate);
        if (data?.departDate && data?.returnDate) {
          setNights(dayjs(data.returnDate).diff(data.departDate, 'days'));
        } else {
          setNights(undefined);
        }
        break;
      case ActionEvents.SEARCH_UPDATE_TRAVELLERS:
        break;
      case ActionEvents.SEARCH_UPDATE_TRAVELLERS_SUBMIT:
        setTravellers(data);
        break;
      case ActionEvents.SEARCH_UPDATE_CABIN_CLASS:
        setCabinClass(data?.cabinClass);
        break;
      case ActionEvents.SEARCH_SUBMIT:
        // This work will need to be done when omni-search has implemented
        // harmonious search (1-2 weeks hopefully)
        // redirectToResults({ ...flowState, destination });
        break;
      default:
        break;
    }
  };

  const { width } = useWindowDimensions();

  const calculateReturnDate = (startDate: string, nights: number) => {
    if (!startDate || !nights || nights < 0) return undefined;
    const start = dayjs.utc(startDate, 'YYYY-MM-DD');
    if (!start.isValid()) return undefined;
    const diff = start.add(nights, 'days');
    if (!diff.isValid()) return undefined;
    return diff.toISOString();
  };

  const defaultReturnDate = useMemo(() => calculateReturnDate(startDate, nights), [startDate, nights]);

  return (
    <ResultsSearchContainer width={width}>
      <MicroComponent
        micro={{ sdkName: 'OmniSearchUI', componentName: 'StaySearchFormFixed' }}
        onAction={onAction}
        travellers={travellers}
        airportCode={departureAirport}
        destination={destination}
        cabinClass={cabinClass}
        departureDate={startDate}
        returnDate={defaultReturnDate}
      />
    </ResultsSearchContainer>
  );
};

export default ResultsSearch;
