import React, { useMemo, useState } from 'react';

import { DetailsLink } from './components';
import { RoomAttributes } from './components/RoomAttributes';
import { Box } from '@mui/material';
import { ContentContainer, ImageContainer, OptionContainer, OptionImage } from './StaysRoomOptionsCard.styles';
import { ProductRoomChoice, ProductRoomRate, ProductRoomSelection } from '@src/types/StayDetailsResponse';
import PricingOverview from './components/PricingOverview';
import { OnActionArgs, ActionEvents } from '@src/types';
import { getCheapestRate, getRatesToDisplayByAttribute } from '@src/utils/calculateRoomAttributes';
import SelectedStayWrapper from '@shared/SelectedStayWrapper';
import { StaysSummaryInclusions } from '@public/StaysSummary/StaysSummaryInclusions';

type StaysRoomOptionsCardProps = {
  stayRoom: ProductRoomChoice;
  nights: number;
  onSelectRate: (rate: ProductRoomRate) => void;
  onAction?: ({ type, data }: OnActionArgs) => void;
  fallbackImage?: string;
  initalSelection: ProductRoomSelection;
  selectedBorder?: boolean;
  displayOnly?: boolean;
  fullWidth?: boolean;
  imgHeight?: string | number;
};

const StaysRoomGroupCard: React.FC<StaysRoomOptionsCardProps> = ({
  stayRoom,
  initalSelection,
  nights,
  fallbackImage,
  onSelectRate,
  onAction,
  selectedBorder = false,
  displayOnly = false,
  fullWidth = false,
  imgHeight,
}) => {
  const roomImage = stayRoom.images.length > 0 ? stayRoom.images[0] : fallbackImage;
  const defaultRate =
    stayRoom.rates.find((rate) => rate.choiceKey === initalSelection?.choiceKey) ?? getCheapestRate(stayRoom.rates);
  const [selectedRate, setSelectedRate] = useState<ProductRoomRate>(defaultRate);

  const rates = useMemo(() => getRatesToDisplayByAttribute(stayRoom.rates, selectedRate), [selectedRate]);
  const handleMoreDetailsClick = () =>
    onAction({ type: ActionEvents.STAY_ROOM_OPEN, data: { choiceKey: stayRoom?.rates?.[0]?.choiceKey } });

  const onRateSelection = (roomRate: ProductRoomRate) => {
    setSelectedRate(roomRate);
    if (onSelectRate) onSelectRate(roomRate);
  };

  return (
    <OptionContainer data-testid="roomOptionCard" fullWidth={fullWidth} onClick={() => onRateSelection(defaultRate)}>
      <SelectedStayWrapper selected={selectedBorder} label="Selected room">
        {roomImage && (
          <ImageContainer height={imgHeight ?? undefined}>
            <OptionImage image={roomImage} height={imgHeight ?? undefined} />
          </ImageContainer>
        )}
        <ContentContainer direction="column">
          <Box mb={(theme) => ({ marginBottom: theme.spacing(2) })}>
            <StaysSummaryInclusions stayRoom={stayRoom} selectedRate={selectedRate} />
          </Box>
          {stayRoom.amenities && stayRoom.amenities.length > 1 && (
            <Box sx={(theme) => ({ marginBottom: theme.spacing(2) })}>
              <DetailsLink onClick={handleMoreDetailsClick} />
            </Box>
          )}
          {!displayOnly && <RoomAttributes selected={selectedRate} attributes={rates} onSelect={onRateSelection} />}
        </ContentContainer>
        <PricingOverview roomRate={selectedRate} nights={nights} />
      </SelectedStayWrapper>
    </OptionContainer>
  );
};

export default StaysRoomGroupCard;
