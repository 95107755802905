import { useEffect, useState } from 'react';

// USAGE
// const [stapiSdk, stapiState, stapiReady] = useSDK('stapi');

const noop = () => {
  return undefined;
};

const win =
  typeof window !== 'undefined'
    ? window
    : {
        removeEventListener: noop,
        addEventListener: noop,
      };

const useSDK = (name: string) => {
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState(undefined);
  useEffect(() => {
    // If the platform already exists, we are safe to set mounted to true
    if ((win as unknown)?.[name]) setMounted(true);
    else {
      // handler function to watch for the platform to load
      const onPlatformLoaded = (e) => {
        // look for the platform name matching our target platform to be detected
        // set mounted to true as the target platform is now ready for use
        if (e.detail.name === name) setMounted(true);
      };
      // all platforms will broadcast MicroPlatformLoaded window event on loaded
      // add a listener to wait for our required platform to be loaded
      win.addEventListener('MicroPlatformLoaded', onPlatformLoaded);
      // return a clean up function should this hook be unmounted
      // this should only happen when components using this hook unmount
      return () => {
        // remove the platform loaded event watcher
        win.removeEventListener('MicroPlatformLoaded', onPlatformLoaded);
      };
    }
  }, []);
  useEffect(() => {
    // if the platform has not yet been mounted return out
    if (!mounted) return undefined;
    // do a quick initial update of the state
    setState((win as unknown)?.[name]?.current());
    // otherwise if the platform is mounted
    // subscribe to the platform state changes
    // return the unsubscriber function for clean up
    return (win as unknown)?.[name]?.subscribe(() => {
      // whenever the platform state changes update the local state
      setState((win as unknown)?.[name]?.current());
    });
  }, [mounted]);
  return [(win as unknown)?.[name] || {}, state, !!state && Object.keys(state?.config ?? {}).length > 0];
};

export default useSDK;
