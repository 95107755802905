import React, { useEffect, useMemo, useState } from 'react';
import useStyles from './MapLabel.styles';

import Box from '@mui/material/Box';
import IconAndLabel from '@src/components/ds-candidates/IconAndLabel';
import { Divider } from '@fctg-ds/core';

import PlaceIcon from '@mui/icons-material/Place';
import { Flight } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { getDestinationAirportCoordinates, getDistanceFromLatLonInKm, round } from '@src/utils/destinationCalcs';
import { TFlightData } from '@src/types/flights.types';
import { StayContent } from '@src/types/StayContent.types';

export interface MapLabelProps {
  preselectedFlight?: TFlightData;
  stayContent?: StayContent;
}

const FlightIcon = () => {
  return <SvgIcon component={Flight} sx={{ transform: 'rotate(45deg)' }} data-testid="flight-icon" />;
};

/**
 * @deprecated
 */
const MapLabel: React.FC<MapLabelProps> = ({ preselectedFlight, stayContent }) => {
  const { classes } = useStyles();

  const [airportLabelData, setHasFlights] = useState(null);
  const location = useMemo(
    () =>
      stayContent
        ? `${stayContent.address.line_1}, ${stayContent.address.city} ${stayContent.address.state} ${stayContent.address.postcode}`
        : '',
    [stayContent],
  );

  useEffect(() => {
    if (preselectedFlight && stayContent) {
      const airportCoordinates = getDestinationAirportCoordinates(preselectedFlight.frankFlightObject);
      setHasFlights(() => {
        const distance = getDistanceFromLatLonInKm(
          stayContent.location.coordinates.lat,
          stayContent.location.coordinates.lon,
          airportCoordinates.lat,
          airportCoordinates.lon,
        );

        const destination = preselectedFlight.frankFlightObject.summary.destination.city;
        const destinationAirport = preselectedFlight.frankFlightObject.airportsMap.find((airport) => {
          return airport.cityCode === destination;
        });
        const roundedDistance = round(distance, 1);

        return { name: destinationAirport.name, distance: roundedDistance };
      });
    }
  }, [preselectedFlight, stayContent]);

  return (
    <Box data-testid="test-map-label" sx={{ width: '100%' }} className={classes.root}>
      <IconAndLabel Icon={PlaceIcon} label={`${location}`} />
      {airportLabelData && (
        <>
          <Divider style={{ margin: 13 }} variant="dot" />
          <IconAndLabel Icon={FlightIcon} label={`${airportLabelData.distance}km from ${airportLabelData.name}`} />
        </>
      )}
    </Box>
  );
};

export default MapLabel;
