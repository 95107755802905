import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const NoAvailabiltyBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 400,
  borderWidth: 1,
  borderColor: theme.token.color.border[' '].value,
  borderStyle: 'solid',
  borderRadius: 20,
}));

export { NoAvailabiltyBox };
