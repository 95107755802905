import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const StayContainer = styled(Box)(({ theme }) => ({
  maxHeight: '250px',
  [theme.breakpoints.up('lg')]: {
    width: '270px',
  },
  width: '100%',
  cursor: 'pointer',
  userSelect: 'none',
  borderRadius: theme.token.radius.base.value,
}));

const StayContent = styled(Box, {
  shouldForwardProp: (prop) => !['isSupportingText', 'isTravellerDetails'].includes(prop as string),
})<{
  isSupportingText?: boolean;
  isTravellerDetails?: boolean;
}>(({ theme, isSupportingText, isTravellerDetails }) => ({
  color: isSupportingText ? theme.token.color.neutral.icon[' '].value : undefined,
  fontSize: isTravellerDetails ? '14px' : '16px',
  padding: '2px 8px 2px 8px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
}));

const StayHeroImage = styled('img', {
  shouldForwardProp: (prop) => !['selected'].includes(prop as string),
})<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  width: '100%',
  height: '130px',
  objectFit: 'cover',
  borderRadius: selected
    ? `${theme.token.radius.md.value} ${theme.token.radius.md.value} 0 0`
    : theme.token.radius.base.value,
}));

export { StayContainer, StayContent, StayHeroImage };
