import React from 'react';
import { RoomAttributeItem } from './RoomAttributeItem';

import { Stack, Box } from '@mui/material';

import { Divider, SkeletonLoader } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';

import type { ProductRoomRate } from '@src/types/StayDetailsResponse';
import { ProductRoomAttributeKey } from '../../StaysRoomOptions.types';
import { isAllTruthy } from '@src/utils';

interface IRoomAttribute {
  onSelect: (rate: ProductRoomRate) => void;
  attributes?: Record<ProductRoomAttributeKey, ProductRoomRate[]>;
  selected?: ProductRoomRate;
  noTopBorder?: boolean;
}

// checks each key if they have any unique values, if there is pass the key and all its unique values to an array.
export const RoomAttributes = ({ attributes, selected, onSelect, noTopBorder = false }: IRoomAttribute) => {
  if (!isAllTruthy(selected, attributes))
    return (
      <SkeletonLoader
        variant="rectangular"
        height={100}
        width={'100%'}
        sx={(theme) => ({ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) })}
      />
    );
  if (Object.values(attributes).flat().length <= 2) return <React.Fragment />;

  const handleSelect = (rateKey: ProductRoomAttributeKey, choiceKey: string) =>
    onSelect(attributes[rateKey].find((att) => att.choiceKey === choiceKey));

  return (
    <Stack sx={{ marginBottom: fc.spacing(3) }} data-testid="roomAttributes">
      {Object.keys(ProductRoomAttributeKey)
        .filter((rateKey) => attributes[rateKey].length > 1)
        .map((rateKey, i) => (
          <Box key={`attribute-${rateKey}`}>
            {(!noTopBorder || i !== 0) && <Divider variant="line" />}
            <RoomAttributeItem
              attributes={attributes[rateKey]}
              defaultValue={selected.choiceKey}
              onSelect={handleSelect}
              rateKey={ProductRoomAttributeKey[rateKey]}
            />
          </Box>
        ))}
    </Stack>
  );
};
