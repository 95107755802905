import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const DynamicMapContainer = styled(Box, {
  shouldForwardProp: (prop) => !['width'].includes(prop as string),
})<{ width: number }>(({ theme, width }) => ({
  [theme.breakpoints.up('lg')]: {
    width: `calc(${width}px - 636px)`,
    height: '600px',
  },
  marginRight: theme.spacing(2),
  width: '100%',
  height: '200px',
}));
