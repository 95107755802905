import React from 'react';
import { styled } from '@fctg-ds/theme';
import { Typography } from '@mui/material';
import { Hotel } from '@mui/icons-material';
import { Flex } from '@common/Flex';

interface BeddingProps {
  bedding: string;
}

/**
 * @deprecated
 */
export const Bedding: React.FC<BeddingProps> = ({ bedding }) => {
  if (!bedding || bedding === 'unknown') return <React.Fragment />;

  return (
    <Flex
      data-testid="roomBedding"
      sx={(theme) => ({ alignItems: 'center', maxHeight: '20px', marginBottom: theme.spacing(1) })}
    >
      <BedGrey />
      <TextGrey variant="bodySm">{bedding}</TextGrey>
    </Flex>
  );
};

const BedGrey = styled(Hotel)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
  paddingRight: theme.spacing(1),
}));

const TextGrey = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
}));
