// Context to handle the product availability fetch / state
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useSDK from '@src/hooks/useSDK';
import { StaySearchProduct } from '@src/types/StaySearchProduct';
import { ProductStay } from '@src/types/StayDetailsResponse';
import { isAllTruthy } from '@src/utils';
import { StayContent } from '@src/types/StayContent.types';
import { ITraveller, useFlowContext } from '@src/context/FlowContext';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { TFlightData } from '@src/types/flights.types';

dayjs.extend(utc);

export type TPriceRange = {
  min: number | undefined;
  max: number | undefined;
};

export type TPriceStats = {
  lowest: number;
  highest: number;
  average: number;
};

export type TChartDataBar = { label: number; count: number };
export type TChartData = TChartDataBar[];

export type TFilters = {
  stars?: boolean[];
  price?: TPriceRange;
  amenities?: string[];
};

export enum SortTypes {
  recommended,
  highest_price,
  lowest_price,
  closest_to_airport,
  closest_to_destination,
  most_stars,
}

export type TSort = {
  sort: SortTypes;
};

// TYPES
export type TShoppingState = {
  // loading states
  isContentLoading: boolean;
  isFlightLoading: boolean;
  isAvailabilityLoading: boolean;

  // data
  availability: StaySearchProduct[];
  product: ProductStay;
  stayContent: StayContent[];
  preselectedFlight: TFlightData;
  filters: TFilters;
  sort: TSort;
  usingExpedia: boolean;
};

export type TShoppingStateAPI = {
  filters: {
    setStars: (selected: boolean[]) => void;
    getStarsResultCount: (selected: boolean[]) => number;
    setPriceRange: (range: TPriceRange) => void;
    getPriceResultCount: (range: TPriceRange) => number;
    getPriceStats: () => TPriceStats; // maybe combine these two
    getChartData: () => TChartData;
    setAmenities: (amenity: string) => void;
    getWeightedAmenities: () => string[];
  };
  sort: {
    setSortType: (sortType: string) => void;
  };
  addOfferingToCart: (offerId: string, selected: string) => Promise<string>;
};

type TShoppingProvider = {
  // raw params
  stayIds?: string[];
  productId?: string;
  coords?: string;
  staySearchOfferId?: string;

  // passthrough data
  contentResults?: StayContent[];
  preselectedFlight?: TFlightData;
  availability?: StaySearchProduct[];
  product?: ProductStay;

  // flow - dont think this is needed here - obtained from flowcontext
  startDate?: string;
  endDate?: string;
  travellers?: ITraveller;
  nights?: number;
  origin?: string;
  destination?: string;
};

// PROVIDER
const ShoppingProvider: React.FC<TShoppingProvider> = ({
  children,
  stayIds: _stayIds,
  productId,
  staySearchOfferId,

  // passthrouggh
  contentResults,
  preselectedFlight,
  availability,
  product,

  // flow state props (if no flow provider is used)
  coords,
  startDate,
  endDate,
  travellers,
  nights,
  origin,
  destination,
}) => {
  const flowState = useFlowContext();

  const [stapiSdk, stapiState, stapiReady] = useSDK('stapi');
  const [checkoutSdk] = useSDK('checkout');
  const [isContentLoading, setContentLoading] = useState<boolean>(false);
  const [isFlightLoading, setFlightLoading] = useState<boolean>(false);
  const [isAvailabilityLoading, setAvailabilityLoading] = useState<boolean>(false);
  const [stayIds, setStayIds] = useState<string[]>(_stayIds);

  const { stays, results, filters, sort } = stapiReady && stapiState;

  const _availability = availability ?? stays?.availability;
  const _contentResults = contentResults ?? results?.contentResults;
  const _preselectedFlight: TFlightData = preselectedFlight ?? stays?.preselectedFlight;
  const _product = product ?? stays?.selectedStayRooms;

  const _startDate = flowState?.startDate ?? startDate;
  const _endDate = flowState?.endDate ?? endDate;
  const _nights = flowState?.nights ?? nights;
  const _adults = flowState?.travellers?.adult ?? travellers?.adult;
  const _children = flowState?.travellers?.child ?? travellers?.child;
  const _origin = flowState?.departureAirport ?? origin;
  const _destination = flowState?.destination ?? destination;
  const _coords = flowState?.coords ?? coords;
  const _fareKey = flowState?.fareKey ?? null;
  const _usingExpedia = flowState?.isExpedia ?? false;

  // addOfferingToCart
  const addOfferingToCart = async (offeringId: string, choiceKey: string): Promise<string> => {
    const offering = await stapiSdk.stayUpsell({ offeringId, choiceKey });
    const { sid } = await checkoutSdk.session.create({ brand: 'fc', region: 'au' });
    await checkoutSdk.cart.people.update({
      travellerIds: offering.travellerIds,
      bookerId: offering.travellerIds[0],
      collection: offering.products[0].travellers.map((traveller) => ({
        personId: traveller.id,
        personal: traveller,
      })),
    });
    const cartableOffering = {
      offeringId: offering.offeringId,
      offeringSelections: [], // this seems to be an unknown at the moment by all!
      offeringSource: 'TBX',
      offeringType: 'STAY',
      travellerIds: offering.travellerIds,
    };
    await checkoutSdk.cart.offering.add(cartableOffering);
    return sid;
  };

  // preSelectedFlight
  useEffect(() => {
    if (_usingExpedia) return;
    if (isAllTruthy(stapiReady, _startDate, _adults, _nights, _origin)) {
      setFlightLoading(true);
      stapiSdk.getPreselectedFlight({
        startDate: _startDate,
        adults: _adults,
        nights: _nights,
        origin: _origin,
        destination: _destination,
      });
    }
  }, [stapiReady, _startDate, _adults, _nights, _origin, _destination]);

  // contentByCoords
  useEffect(() => {
    if (isAllTruthy(stapiReady, _coords)) {
      // without coords check it makes the call on the product page.
      setContentLoading(true);
      if (_usingExpedia) {
        if (flowState?.slug)
          stapiSdk.getContentByCoords({
            // coords: _coords,
            seoUrl: flowState.slug,
          });
      } else {
        stapiSdk.getContentByCoords({
          coords: _coords,
        });
      }
    }
  }, [stapiReady, flowState?.slug]);

  // staySearch
  useEffect(() => {
    if (isAllTruthy(stapiReady, stayIds, _startDate, _adults, _nights, flowState?.slug, _destination)) {
      setAvailabilityLoading(true);
      if (_usingExpedia) {
        stapiSdk.getStaySearch({
          seoUrl: flowState.slug,
          hotelIds: stayIds,
          startDate: _startDate,
          adults: _adults,
          nights: _nights,
          origin: _origin,
          destination: _destination, // for preselected flight call,
          fareKey: _fareKey || null,
        });
      } else {
        stapiSdk.getStaySearch({
          hotelIds: stayIds,
          startDate: _startDate,
          adults: _adults,
          nights: _nights,
        });
      }
    }
  }, [stapiReady, stayIds, _startDate, _adults, _nights, flowState?.slug, _destination, _origin]);

  // stayDetails
  useEffect(() => {
    if (isAllTruthy(stapiReady, staySearchOfferId)) {
      stapiSdk.getStayDetails({
        offerId: staySearchOfferId,
      });
    } else if (isAllTruthy(stapiReady, productId, _startDate, _adults, _nights, flowState?.slug)) {
      setAvailabilityLoading(true);

      if (_usingExpedia) {
        stapiSdk.getStayDetails({
          hotelIds: productId,
          seoUrl: flowState.slug,
          startDate: _startDate,
          adults: _adults,
          nights: _nights,
          origin: _origin,
          destination: _destination, // for preselected flight call
          fareKey: _fareKey || null,
        });
      } else {
        stapiSdk.getStayDetails({
          hotelIds: productId,
          startDate: _startDate,
          adults: _adults,
          nights: _nights,
        });
      }
    }
  }, [stapiReady, staySearchOfferId, productId, _startDate, _adults, _nights, _availability, _origin]);

  useEffect(() => {
    if (_contentResults && !!_contentResults.length) {
      const filteredResults = stapiSdk.results();
      setStayIds(filteredResults.map((content: StayContent) => content.hotelId));
    }
  }, [_contentResults, sort]);

  useEffect(() => {
    if (_availability) {
      setAvailabilityLoading(false);
    }

    if (_preselectedFlight) {
      setFlightLoading(false);
    }

    if (_contentResults && !!_contentResults.length) {
      setContentLoading(false);
    }
  }, [_availability, _preselectedFlight, _contentResults]);

  const api = useMemo<TShoppingStateAPI>(() => {
    return {
      filters: {
        setStars: (selected: boolean[]) => stapiSdk?.filters?.stars.set(selected),
        getStarsResultCount: (selected: boolean[]) => stapiSdk?.filters?.stars.resultsCount(selected),
        setPriceRange: (range: TPriceRange) => stapiSdk?.filters?.price.set(range),
        getPriceResultCount: (range: TPriceRange) => stapiSdk?.filters?.price.resultsCount(range),
        getPriceStats: () => stapiSdk?.filters?.price.getStats(),
        getChartData: () => stapiSdk?.filters?.price.getChartData(),
        getWeightedAmenities: () => stapiSdk?.filters?.amenities.getWeightedList(),
        setAmenities: (amenity: string) => stapiSdk?.filters?.amenities.set(amenity),
      },
      sort: {
        setSortType: (sortType: string) => stapiSdk?.sort.set(sortType),
      },
      addOfferingToCart,
    };
  }, []);

  const state = useMemo<TShoppingState>(() => {
    return {
      availability: _availability,
      preselectedFlight: _preselectedFlight,
      product: _product,
      stayContent: stapiReady && stapiSdk?.results?.(),
      isContentLoading,
      isFlightLoading,
      isAvailabilityLoading,
      filters: filters ?? {
        stars: [false, false, false, false, false],
        price: {
          min: 0,
          max: 1,
        },
        amenities: [],
      },
      sort: sort ?? {
        sort: 'recommended',
      },
      usingExpedia: _usingExpedia,
    };
  }, [
    stapiReady,
    _preselectedFlight,
    _availability,
    _product,
    _contentResults,
    isContentLoading,
    isFlightLoading,
    isAvailabilityLoading,
    filters,
    sort,
    flowState?.slug,
  ]);

  return (
    <ShoppingStateAPI.Provider value={api}>
      <ShoppingStateContext.Provider value={state}>{children}</ShoppingStateContext.Provider>
    </ShoppingStateAPI.Provider>
  );
};

// CONTEXT
const ShoppingStateAPI = createContext<TShoppingStateAPI>(undefined);
const ShoppingStateContext = createContext<TShoppingState>(undefined);

// HOOKS / EXPORTS
export const useShoppingContext = () => useContext(ShoppingStateContext);
export const useShoppingAPI = () => useContext(ShoppingStateAPI);

export default ShoppingProvider;
