import React from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import { useBreakpointMapper } from '@fctg-ds/core';
import { TLayoutDef, TImage } from './ImageQuilt.types';
import { StyledImage } from './ImageQuilt.styles';
import { getImage, findClosestConfiguredLayoutThatFitsAllImages } from './helpers';

// defaults
const DEFAULT_GAP = 6; // px
const DEFAULT_ROW_HEIGHT = 360; // px
const DEFAULT_GRID_COLUMNS = 12;
const DEFAULT_LAYOUT_DEFINITION: TLayoutDef[] = [
  {
    imageCount: 1,
    rows: 1,
    cols: 12,
  },
  {
    imageCount: 2,
    rows: 1,
    cols: 6,
  },
  {
    imageCount: 3,
    colDef: [
      {
        cols: 7,
      },
      {
        cols: 5,
        rowDef: [12, 12],
      },
    ],
  },
  {
    imageCount: 5,
    colDef: [
      {
        cols: 7,
      },
      {
        cols: 5,
        rowDef: [
          [6, 6],
          [6, 6],
        ],
      },
    ],
  },
];

export interface ImageQuiltProps {
  /**
   * image data
   */
  images: TImage[];
  /**
   * the height to assign to a full row in px
   * @default 360px
   */
  rowHeight?: number;
  /**
   * size in px for the gap between the image elements
   * @default 6px
   */
  gap?: number;
  /**
   * number of images desired to display, used to target a specific template
   * @default xl : 5 | md : 3 | sm : 1
   */
  imagesToDisplay?: number;
  /**
   * any layout overrides
   */
  layoutOverrides?: TLayoutDef[];
  /**
   * click handler for each image in the component
   */
  onImageSelect?: (image: TImage) => void;
}

const renderDef = (
  layout: TLayoutDef,
  images: TImage[],
  rowHeight: number,
  gap: number,
  onClick: (image: TImage) => void,
) => {
  let imageIndex = 0;
  const { colDef } = layout;

  if (!colDef) {
    const cols = DEFAULT_GRID_COLUMNS / (layout.cols ?? DEFAULT_GRID_COLUMNS);
    const rows = layout.rows ?? 1;
    return images.slice(0, cols * layout.rows).map((img: TImage, idx: number) => (
      <ImageListItem
        data-testid={`image-quilt-column-${idx}`}
        key={`non-col-${idx}`}
        cols={layout.cols}
        rows={layout.rows}
      >
        <StyledImage
          data-testid={'image-quilt-image'}
          src={img.url}
          rows={rows}
          rowHeight={rowHeight}
          gap={gap}
          onClick={() => onClick(img)}
        />
      </ImageListItem>
    ));
  }

  return colDef.map((layoutDef: TLayoutDef, colIdx: number) => {
    const { rowDef, cols } = layoutDef;

    if (rowDef) {
      return (
        <ImageListItem data-testid={`image-quilt-column-${colIdx}`} key={`col-container-${colIdx}`} cols={cols}>
          <ImageList data-testid={'image-quilt-column-container'} gap={gap} cols={DEFAULT_GRID_COLUMNS}>
            {rowDef.map((row: number | number[], rowIdx: number) => {
              const _rows = rowDef.length;
              if (Array.isArray(row)) {
                return row.map((col: number, innerColIdx: number) => {
                  const image = getImage(images, imageIndex);
                  imageIndex++;
                  return (
                    <ImageListItem
                      data-testid={`image-quilt-column-${colIdx}-row-${rowIdx}-innerCol-${innerColIdx}`}
                      key={`col-${colIdx}-row-${rowIdx}-innerCol-${innerColIdx}}`}
                      cols={col}
                      rows={rowIdx}
                    >
                      <StyledImage
                        data-testid={'image-quilt-image'}
                        src={image.url}
                        rows={_rows}
                        rowHeight={rowHeight}
                        gap={gap}
                        onClick={() => onClick(image)}
                      />
                    </ImageListItem>
                  );
                });
              }

              const image = getImage(images, imageIndex);
              imageIndex++;
              return (
                <ImageListItem
                  data-testid={`image-quilt-column-${colIdx}-row-${rowIdx}`}
                  key={`col-${colIdx}-row-${rowIdx}`}
                  cols={row}
                  rows={rowIdx}
                >
                  <StyledImage
                    data-testid={'image-quilt-image'}
                    src={image.url}
                    rows={_rows}
                    rowHeight={rowHeight}
                    gap={gap}
                    onClick={() => onClick(image)}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
        </ImageListItem>
      );
    }

    const image = getImage(images, imageIndex);
    imageIndex++;
    return (
      <ImageListItem data-testid={`image-quilt-column-${colIdx}`} key={`col-${colIdx}`} cols={cols} rows={1}>
        <StyledImage
          data-testid={'image-quilt-image'}
          src={image.url}
          rowHeight={rowHeight}
          gap={gap}
          onClick={() => onClick(image)}
        />
      </ImageListItem>
    );
  });
};

/**
 * @deprecated
 */
const ImageQuilt: React.FC<ImageQuiltProps> = (props: ImageQuiltProps) => {
  const { images, rowHeight, gap, imagesToDisplay, layoutOverrides, onImageSelect } = props;

  const [breakpointValue] = useBreakpointMapper({
    xs: 1,
    sm: 1,
    md: 3,
    lg: 5,
    xl: 5,
  });

  const layout = findClosestConfiguredLayoutThatFitsAllImages(
    DEFAULT_LAYOUT_DEFINITION,
    images.length,
    imagesToDisplay ?? breakpointValue,
    layoutOverrides,
  );

  const onClickHandler = (image: TImage) => {
    if (onImageSelect) {
      onImageSelect(image);
    }
  };

  const _gap = gap ?? DEFAULT_GAP;

  return (
    <ImageList
      data-testid={'image-quilt-column-container'}
      gap={_gap}
      cols={DEFAULT_GRID_COLUMNS}
      sx={{ borderRadius: '16px' }}
    >
      {renderDef(layout, images, rowHeight ?? DEFAULT_ROW_HEIGHT, _gap, onClickHandler)}
    </ImageList>
  );
};

export default ImageQuilt;
