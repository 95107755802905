import React from 'react';
import MicroComponent from '@src/../dev/common/MicroComponent';
import { ConditionalWrapper, useBreakpointMapper } from '@fctg-ds/core';
import StickyBox from '@src/components/ds-candidates/StickyWrapper';
import { useFlowAPI, useFlowContext } from '@src/context/FlowContext';
import { ActionEvents, OnActionArgs } from '@src/types';

/**
 * @deprecated
 */
const SideBar: React.FC = () => {
  const { setDepartureAirport, setStartDate, setNights, setTravellers, setCabinClass } = useFlowAPI();
  const { departureAirport, travellers, startDate, nights } = useFlowContext();
  const [breakpointValues] = useBreakpointMapper({
    xs: { columns: 1 },
    sm: { columns: 1 },
    md: { columns: 1 },
    lg: { columns: 2 },
    xl: { columns: 2 },
  });

  const handleOnAction = async ({ type, data }: OnActionArgs) => {
    switch (type) {
      case ActionEvents.CUSTOMISETRIP_UPDATE_ORIGIN:
        setDepartureAirport(data?.value);
        break;
      case ActionEvents.CUSTOMISETRIP_UPDATE_CHECKIN:
        setStartDate(data);
        break;
      case ActionEvents.CUSTOMISETRIP_UPDATE_NIGHTS:
        setNights(data);
        break;
      case ActionEvents.CUSTOMISETRIP_UPDATE_TRAVELLERS:
        setTravellers(data);
        break;
      case ActionEvents.CUSTOMISETRIP_UPDATE_CABIN_CLASS:
        setCabinClass(data);
        break;

      case ActionEvents.CUSTOMISETRIP_UPDATE_CHECKOUT:
        break;

      default:
        break;
    }
  };

  return (
    <ConditionalWrapper
      condition
      wrapper={(children) => {
        if (breakpointValues.columns === 2) return <StickyBox>{children}</StickyBox>;
        else return <>{children}</>;
      }}
    >
      <MicroComponent
        micro={{
          sdkName: 'OmniSearchUI',
          componentName: 'CustomiseTrip',
        }}
        airportCode={departureAirport}
        travellers={travellers}
        checkIn={startDate}
        nights={nights}
        saved={undefined}
        isMobile={breakpointValues.columns === 1}
        onAction={handleOnAction}
      />
    </ConditionalWrapper>
  );
};

export default SideBar;
