import { StayContent } from '@src/types/StayContent.types';
import { InclusionType } from '@src/components/public/InclusionsList';

export const getStayInclusions = (stayContent: StayContent, nights: number) => [
  {
    label: `Stay at ${stayContent.hotelName}`,
    description: `Stay up to ${nights ?? 'X'} nights in this ${stayContent.rating} star resort`,
    icon: InclusionType.Stay,
    learnMore: undefined,
  },
  {
    label: 'Return flights (optional)',
    description: 'Add a departure airport for prices with flights',
    icon: InclusionType.Flights,
    learnMore: undefined,
  },
];
