import React from 'react';
import { ProductPageProps } from './ProductPage.types';
import { ConditionalWrapper, Divider, useBreakpointMapper } from '@fctg-ds/core';
import { Box, Stack, Typography } from '@mui/material';
import { useFlowContext } from '@context/FlowContext';
import { useContentContext } from '@context/ContentContext';

import StarRating from '@components/ds-candidates/StarRating';
import TextExpand from '@components/ds-candidates/TextExpand';
import { GreyTypography, RatingBox, StayInformation } from './ProductPage.styles';
import { ActionEvents, OnActionArgs, OnActionType } from '@src/types';
import { getStayInclusions } from './helpers';
import ImageGalleryModal from '@public/ImageGalleryModal';
import InclusionsList from '@public/InclusionsList';
import StayAmenities from '@public/StayAmenities/StayAmenities';
import StaysTermsAndConditions from '@stays/StayTermsAndConditions';
import StayRoomOptions from '@stays/StayRoomOptions';
import FlightInfo from '@wrappers/FlightInfo';
import StaticMap from '@wrappers/StaticMap';
import SideBar from '@wrappers/SideBar';
import { useShoppingContext } from '@src/context/ShoppingContext';

/**
 * @deprecated
 */
const ProductPage: React.FC<ProductPageProps> = ({ redirectToCheckout }) => {
  const { nights, isFetchingPreselectedFlight, startDate, destination, departureAirport } = useFlowContext();
  const { preselectedFlight } = useShoppingContext();
  const { stayContent } = useContentContext();

  const [breakpointValues] = useBreakpointMapper({
    xs: { columns: 1 },
    sm: { columns: 1 },
    md: { columns: 1 },
    lg: { columns: 2 },
    xl: { columns: 2 },
  });

  const handleOnAction = async ({ type, data }: OnActionArgs) => {
    switch (type) {
      case ActionEvents.FLIGHT_INFO:
      case ActionEvents.FLIGHT_BAGGAGE_SUMMARY:
        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onEmptyFlightStateBtnClick: OnActionType = () => {};

  return (
    <Stack
      spacing={6}
      justifyContent="center"
      sx={(theme) => ({ maxWidth: theme.breakpoints.values.xl })}
      data-testid="stays-product-page"
    >
      <ImageGalleryModal images={stayContent.images} />
      <ConditionalWrapper
        condition
        wrapper={(children) => {
          if (breakpointValues.columns === 1) return <Stack direction="column">{children}</Stack>;
          else if (breakpointValues.columns === 2)
            return (
              <Stack direction="row-reverse" justifyContent="space-between" spacing={6}>
                {children}
              </Stack>
            );
          else return <>{children}</>;
        }}
      >
        <Box sx={(theme) => ({ minWidth: 300, maxWidth: theme.breakpoints.values.lg })}>
          <SideBar />
        </Box>
        <StayInformation spacing={6} sx={(theme) => ({ maxWidth: theme.breakpoints.values.lg })}>
          <Box>
            <Typography variant="headingLg" component="p">
              {stayContent.hotelName}
            </Typography>
            <RatingBox direction="row">
              <StarRating id="stay-details-rating" value={stayContent.rating} />
              <Divider style={{ margin: 13 }} variant="dot" />
              <GreyTypography variant="bodyMd">{`${stayContent.address.city}, ${stayContent.address.state}`}</GreyTypography>
            </RatingBox>
          </Box>
          <InclusionsList inclusions={getStayInclusions(stayContent, nights)} />
          <TextExpand
            id="description"
            maxRows={3}
            content={stayContent.descriptions.map((d) => d.detail).join(' ')}
            variant={'button'}
          />
          <FlightInfo
            onAction={handleOnAction}
            preselectedFlight={preselectedFlight}
            departureAirport={departureAirport}
            destination={destination}
            startDate={startDate}
            nights={nights}
            isFetchingPreselectedFlight={isFetchingPreselectedFlight}
            onEmptyFlightStateBtnClick={onEmptyFlightStateBtnClick}
          />
          <StayAmenities />
          <StayRoomOptions fallbackImage={stayContent?.images?.[0].url} redirectToCheckout={redirectToCheckout} />
        </StayInformation>
      </ConditionalWrapper>
      <StaticMap />
      <StaysTermsAndConditions />
    </Stack>
  );
};

export default ProductPage;
