import React from 'react';
import { RootBox } from './StaysResultsCardList.styles';
import StayCardSkeleton from '@stays/StaysResultsCardList/StaysCard/StayCardSkeleton';

const LOADERS_TO_DISPLAY = 12;

export interface StayDetailsSkeletonProps {
  isContentLoading: boolean;
}

/**
 * @deprecated
 */
const StayDetailsSkeleton: React.FC<StayDetailsSkeletonProps> = ({ isContentLoading }) => {
  const loaders = new Array(LOADERS_TO_DISPLAY).fill(StayCardSkeleton);

  return <RootBox>{isContentLoading && loaders.map((Loader, index: number) => <Loader key={index} />)}</RootBox>;
};

export default StayDetailsSkeleton;
