import React from 'react';
import FlowProvider from '@src/context/FlowContext';
import ShoppingProvider from '@src/context/ShoppingContext';
import ContentProvider from '@src/context/ContentContext';
import OfferingProvider from '@src/context/OfferingContext';
import ProductPage from './ProductPage';
import { ProductPageContextProps } from './ProductPage.types';

/**
 * @deprecated
 */
const ProductPageContainer: React.FC<ProductPageContextProps> = (props) => {
  return (
    <FlowProvider {...props}>
      <ShoppingProvider productId={props.productId}>
        <ContentProvider stayContent={props.productContent} stayId={props.productId}>
          <OfferingProvider>
            <ProductPage redirectToCheckout={props.redirectToCheckout} />
          </OfferingProvider>
        </ContentProvider>
      </ShoppingProvider>
    </FlowProvider>
  );
};

export default ProductPageContainer;
