import React from 'react';
import { BoxWrapper, LabelText } from './IconAndLabel.styles';

export interface IconAndLabelProps {
  Icon: React.FC;
  label: string;
  grow?: boolean;
}

const IconAndLabel: React.FC<IconAndLabelProps> = ({ Icon, label, grow = false }) => {
  return (
    <BoxWrapper grow={grow}>
      <Icon />
      <LabelText variant="bodySm">{label}</LabelText>
    </BoxWrapper>
  );
};

export default IconAndLabel;
