import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import StarIcon from '@mui/icons-material/Star';
import { Flex } from '@common/Flex';

interface IProps {
  selected: boolean;
  count: number;
  onClick: () => unknown;
}

enum EStarRating {
  'Basics',
  'Standard',
  'Comfort',
  'Superior',
  'Luxury',
}

const StyledBox = styled(Box)<{ selected: boolean }>(({ selected, theme }) => {
  const selectedState = selected ? 'active' : 'inactive';

  const stateStyles = {
    inactive: {
      borderColor: theme.token.color.border[' '].value,
    },
    active: {
      border: `${theme.spacing(0.5)} solid`,
      borderColor: theme.token.color.border.active.value,
    },
  };

  const baseStyles = {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.token.color.background[' '].value,
    border: `${theme.spacing(0.25)} solid`,
    borderRadius: theme.token.radius.base.value,
    color: theme.token.color.text[' '].value,
    display: 'flex',
    width: '80px',
    height: '64px',
    padding: '4px 8px',
    margin: '4px',
  };

  return {
    ':hover': {
      borderColor: theme.token.color.border.hovered.value,
      cursor: 'pointer',
    },
    ':focus': {
      ...stateStyles.active,
    },
    ...baseStyles,
    ...stateStyles[selectedState],
  };
});

/**
 * @deprecated
 */
const StarBox: React.FC<IProps> = ({ onClick, selected, count }) => {
  return (
    <StyledBox onClick={onClick} selected={selected} flexDirection={'column'}>
      <Flex sx={{ flex: 0, alignItems: 'center', justifyContent: 'center', color: '#212121' }}>
        <StarIcon sx={{ marginRight: '2px', paddingBottom: '1px' }} fontSize="inherit" />
        <Typography fontWeight={500} fontSize={'14px'}>
          {count + 1}
        </Typography>
      </Flex>
      <Typography variant="captionMd" color={selected ? 'inherit' : '#757575'}>
        {EStarRating[count]}
      </Typography>
    </StyledBox>
  );
};

export default StarBox;
