import { styled } from '@fctg-ds/theme';
import { Box, Stack, Typography } from '@mui/material';

const StayInformation = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const RatingBox = styled(Stack)(() => ({
  alignItems: 'center',
}));

const GreyTypography = styled(Typography)(({ theme }) => ({
  color: theme.token.color.text.weak[' '].value,
}));

const FlightInfoContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    marginTop: theme.spacing(4),
  },
  marginBottom: theme.spacing(4),
}));

export { StayInformation, RatingBox, GreyTypography, FlightInfoContainer };
