import React, { useState, useMemo, useEffect } from 'react';
import { Chip, Popover, Drawer, IconButton } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import StarFilterFooter from './StarFilterFooter';
import StarFilterBody from './StarFilterBody';
import { TFilters } from '@src/context/ShoppingContext';
import { FilterChipContainer } from '@src/components/stays/SortAndFilters/Shared';

const DEFAULT_FILTER_STATE = [false, false, false, false, false];

export type StarFilterProps = {
  defaultLabel: string;
  filters: TFilters;
  getResultCount: (selected: boolean[]) => number;
  setStars: (selected: boolean[]) => void;
};

/**
 * @deprecated
 */
const StarFilter: React.FC<StarFilterProps> = ({ defaultLabel, filters, getResultCount, setStars }) => {
  const [active, setActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [labelText, setLabelText] = useState<string>(defaultLabel);
  const [rating, setRating] = useState<boolean[]>(DEFAULT_FILTER_STATE);

  const isMobile = useMediaQuery(fc.breakpoints.down('md'));

  const hasFilters: boolean = useMemo(() => rating.some((star: boolean) => star === true), [rating]);
  const resultsCount: number = useMemo(() => getResultCount(rating) ?? 0, [rating, active, getResultCount]);

  useEffect(() => {
    if (filters?.stars) {
      setRating(filters?.stars);
    }
  }, [filters, active]);

  const handleOpen = (event: React.SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    setActive(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setActive(false);
    setAnchorEl(null);
  };

  const handleSelectionChange = (idx: number) => {
    const stars = [...rating];
    stars.splice(idx, 1, !stars[idx]);
    setRating(stars);
  };

  const generateLabel = (selected: boolean[]): string | null => {
    if (selected.every((val) => !val)) return null;

    const result = [];
    selected.forEach((val: boolean, idx: number) => {
      if (val) result.push(idx + 1);
    });

    const hasFourOrMoreConsecutives = (result: number[]): boolean => {
      if (result.length < 4) return false;
      let cur = result[0];
      const validate: boolean[] = [];
      for (let i = 1; i < result.length; i++) {
        validate.push(result[i] === cur + 1 ? true : false);
        cur = result[i];
      }
      return validate.every((val) => val === true);
    };

    if (hasFourOrMoreConsecutives(result)) {
      return `${result.shift()} - ${result.pop()} star`;
    }

    if (result.length > 1) {
      if (result.length > 2) {
        result.splice(1, 0, ', ');
      }

      if (result.length > 4) {
        result.splice(3, 0, ', ');
      }
      result.splice(result.length - 1, 0, ' or ');
      return `${result.join('')} star`;
    }

    return `${result} star`;
  };

  const handleFilterResults = () => {
    handleClose();
    setStars(rating);
    setLabelText(generateLabel(rating) ?? defaultLabel);
  };

  const handleFilterReset = () => {
    setRating(DEFAULT_FILTER_STATE);
  };

  const show = Boolean(anchorEl);

  return (
    <FilterChipContainer>
      <Chip label={labelText} isActive={active} onClick={handleOpen} endIcon={<ExpandMoreOutlinedIcon />} />
      {isMobile ? (
        <Drawer
          open={show}
          onOpen={() => undefined}
          onClose={handleClose}
          drawerHeaderTitle="Star rating"
          leftActionBtn={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
          maxHeight={'80%'}
          footer={
            <StarFilterFooter
              onFilterResults={handleFilterResults}
              onFilterReset={handleFilterReset}
              resultsCount={resultsCount}
              hasFilters={hasFilters}
            />
          }
        >
          <StarFilterBody onSelectionChange={handleSelectionChange} rating={rating} />
        </Drawer>
      ) : (
        <Popover
          open={show}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
          footer={
            <StarFilterFooter
              onFilterResults={handleFilterResults}
              onFilterReset={handleFilterReset}
              resultsCount={resultsCount}
              hasFilters={hasFilters}
            />
          }
          sx={{
            '& .MuiPopover-paper': {
              height: 'unset',
            },
          }}
        >
          <StarFilterBody onSelectionChange={handleSelectionChange} rating={rating} />
        </Popover>
      )}
    </FilterChipContainer>
  );
};

export default StarFilter;
