import { styled } from '@fctg-ds/theme';

export const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'rows' && prop !== 'rowHeight' && prop !== 'gap',
})<{
  rows?: number;
  rowHeight: number;
  gap: number;
}>(({ rows, rowHeight, gap }) => {
  const _rows = rows ?? 1;
  const _height = rowHeight / _rows;
  const _adjustedHeight = rowHeight / _rows - gap / _rows;
  return { width: '100%', height: _rows > 1 ? _adjustedHeight : _height, objectFit: 'cover' };
});
