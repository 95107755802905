import React, { useCallback, useEffect, useState } from 'react';
import {
  ScrollList,
  useBreakpointMapper,
  ResponsiveList,
  LoadingButton,
  Divider,
  SecondaryButton,
} from '@fctg-ds/core';
import StaySummaryContainer from '@src/components/public/StaysSummary/StaySummaryContainer';
import { Route, RouterDrawer, RouterModal, RouterModalProvider, useRouterModal } from '@fctg-ds/lab';
import { Button, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OnActionType, ActionEvents } from '@src/types';
import { ProductStay } from '@src/types/StayDetailsResponse';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StayDetailsView from '@src/components/public/StaysDetailsView/StayDetailsView';
import { PriceSummary } from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/components';
import NotesContent from '@src/components/shared/Notes/NotesContent';

interface RetailStayOptionsProps {
  currentStay: ProductStay;
  alternatives?: ProductStay[];
  open: boolean;
  loadingId?: string | undefined;
  onAction: OnActionType;
}

const RetailQuoteFooterContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledDividerBottom = styled(Divider)(({ theme }) => ({
  marginBottom: `${theme.spacing(0)}`,
}));

const RetailStayOptions: React.FC<RetailStayOptionsProps> = ({
  currentStay,
  alternatives,
  open,
  loadingId,
  onAction,
}) => {
  const { setRoute, title, setTitle, route, closeModal } = useRouterModal();
  const theme = useTheme();

  const isTabletView = useMediaQuery(theme.breakpoints.up('md'));
  const stayOptions = [currentStay, ...alternatives];
  const [bpValues] = useBreakpointMapper({
    xs: { isMobile: true },
    sm: { isMobile: true },
    md: { isMobile: true },
    lg: {},
    xl: {},
  });
  const [stayContent, setStayContent] = useState(undefined);
  const [retailStayProduct, setRetailStayProduct] = useState(undefined);
  const isRetailAlternativeStay = retailStayProduct?.altProductPriceDifference !== undefined;
  const hasNotes = retailStayProduct?.notes?.length > 0;

  const shouldDisplayFooter = (route) => {
    const footerRoutes = ['/StayAlternatives', '/StayAlternatives/notes'];
    return !footerRoutes.includes(route);
  };

  const onStayDetailsOpen = (stay, product) => {
    setStayContent(stay);
    setRetailStayProduct(product);
    setRoute('/StayAlternatives/detail');
  };

  const onClose = () => {
    onAction({ type: ActionEvents.STAY_RETAIL_ALTERNATIVES_CLOSE, data: null });
  };

  const onSelectStaysAlt = (altOfferingId: string) => () => {
    onAction({
      type: ActionEvents.STAY_RETAIL_ALTERNATIVES_SELECT,
      data: {
        offeringId: currentStay?.offeringId,
        newOfferingId: altOfferingId,
      },
    });
  };

  const renderLeftActionButton = () => {
    if (isTabletView || route === '/StayAlternatives') {
      return (
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      );
    }

    return (
      <Button
        onClick={handleGoBack}
        startIcon={<ChevronLeftIcon />}
        sx={{ color: theme.token.color.text[' '].value }}
      />
    );
  };

  const handleRightActionBtn = (route) => {
    switch (route) {
      case '/StayAlternatives/detail':
        setRoute('/StayAlternatives');
        break;

      case '/StayAlternatives/notes':
        setRoute('/StayAlternatives/detail');
        setTitle('Stay alternatives');
        break;

      default:
        break;
    }
  };
  const resetAltsState = (): void => {
    setRetailStayProduct(undefined);
    setStayContent(undefined);
  };

  useEffect(() => {
    resetAltsState();
  }, []);

  useEffect(() => {
    if (open) {
      setTitle(`Stay alternatives`);
      setRoute(`/StayAlternatives`);
    }
  }, [open]);

  const handleGoBack = useCallback(() => {
    setRoute(route === '/StayAlternatives/detail' ? '/StayAlternatives' : '/StayAlternatives/detail');
    setTitle('Stay alternative');
  }, [route]);

  const handleNotesOpen = () => {
    if (!hasNotes) return;
    setTitle('Notes');
    setRoute('/StayAlternatives/notes');
  };
  const AlternativeFooter = () => {
    if (!isRetailAlternativeStay && !hasNotes) return <></>;
    return (
      <>
        <StyledDividerBottom />
        <RetailQuoteFooterContainer>
          <Stack sx={{ alignItems: 'flex-end' }}>
            <PriceSummary
              pricing={retailStayProduct?.summary?.pricing}
              priceDifference={retailStayProduct?.altProductPriceDifference}
              isRetailStay
            />
            <Typography variant="bodySm">Total</Typography>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: '12px' }}>
            {hasNotes && (
              <SecondaryButton disabled={!hasNotes} onClick={handleNotesOpen}>
                View notes
              </SecondaryButton>
            )}
            {isRetailAlternativeStay && (
              <LoadingButton
                fullWidth
                variant="primary"
                data-testid="alternative-select-button"
                loadingText="Loading..."
                loading={loadingId === retailStayProduct.offeringId}
                onClick={onSelectStaysAlt(retailStayProduct.offeringId)}
                disabled={!isRetailAlternativeStay}
              >
                Select
              </LoadingButton>
            )}
          </Stack>
        </RetailQuoteFooterContainer>
      </>
    );
  };

  const renderStaySummaryCard = (item, isSelectedStay) => {
    return (
      <StaySummaryContainer
        key={item.stayId}
        isRetailAlternative
        stayId={item.stayId}
        product={item}
        onAction={onAction}
        showPricing
        hasMargin
        loadingId={loadingId}
        onStayDetailsOpen={onStayDetailsOpen}
        isSelectedStay={isSelectedStay}
        onSelectStaysAlt={onSelectStaysAlt}
      />
    );
  };

  const StayAlternativeRoute = () => {
    if (bpValues.isMobile) {
      return (
        <>
          {renderStaySummaryCard(currentStay, true)}
          <Typography
            component="p"
            variant="bodyLgStrong"
            sx={(theme) => ({
              margin: `${theme.spacing(4)} 0`,
            })}
          >
            {`${alternatives.length} alternative stay option${alternatives.length !== 1 ? 's' : ''}`}
          </Typography>
          <ScrollList autoWidth spacing={3}>
            {alternatives.map((item) => renderStaySummaryCard(item, false))}
          </ScrollList>
        </>
      );
    }
    return (
      <>
        <ResponsiveList
          title={`${alternatives.length} alternative stay option${alternatives.length !== 1 ? 's' : ''}`}
          ariaLabel="Display"
          displayLg={2}
          displayMd={1}
          displayXl={2}
          peek={16}
          peekAll
        >
          {stayOptions.map((item, index) => renderStaySummaryCard(item, index === 0))}
        </ResponsiveList>
      </>
    );
  };

  return isTabletView ? (
    <RouterModal
      open={open}
      maxHeight="85vh"
      maxWidth={`lg`}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      closeIcon={renderLeftActionButton()}
      title={title}
      footer={shouldDisplayFooter(route) ? <AlternativeFooter /> : null}
      data-testid="stay-alternatives-singleton"
      moreText={route !== '/StayAlternatives' ? 'back' : ''}
      onMoreClick={() => handleRightActionBtn(route)}
    >
      <Route path="StayAlternatives">
        <Route index element={<StayAlternativeRoute />} />
        <Route path="detail" element={<StayDetailsView stay={stayContent} product={retailStayProduct} />} />
        <Route path="notes" element={<NotesContent notes={retailStayProduct?.notes} />} />
      </Route>
    </RouterModal>
  ) : (
    <RouterDrawer
      open={open}
      onClose={closeModal}
      onOpen={() => undefined}
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      leftActionBtn={renderLeftActionButton()}
      footer={shouldDisplayFooter(route) ? <AlternativeFooter /> : null}
      drawerHeaderTitle={title}
      data-testid="stay-alternatives-singleton"
    >
      <Route path="StayAlternatives">
        <Route index element={<StayAlternativeRoute />} />
        <Route path="detail" element={<StayDetailsView stay={stayContent} product={retailStayProduct} />} />
        <Route path="notes" element={<NotesContent notes={retailStayProduct?.notes} />} />
      </Route>
    </RouterDrawer>
  );
};

const StayAlternativesSingleton = (props) => {
  return (
    <RouterModalProvider>
      <RetailStayOptions {...props} />
    </RouterModalProvider>
  );
};

export default StayAlternativesSingleton;
