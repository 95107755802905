/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import '@fctg-dc/fe-eventbus';
import { Divider } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import StarRating from '@components/ds-candidates/StarRating';
import IconAndLabel from '@components/ds-candidates/IconAndLabel';
import dayjs from 'dayjs';
import { Typography, useMediaQuery, Stack } from '@mui/material';
import { DarkModeOutlined as NightsIcon } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FmdGoodIcon from '@mui/icons-material/FmdGoodOutlined';
import { useContentContext } from '@src/context/ContentContext';
import { useShoppingContext } from '@src/context/ShoppingContext';

interface StaysSummaryDetailsProps {
  isRetailAlternative?: boolean;
}
export const StaysSummaryDetails: React.FC<StaysSummaryDetailsProps> = ({ isRetailAlternative }) => {
  const { stayContent } = useContentContext();
  const { product } = useShoppingContext();
  const screenLg = useMediaQuery(fc.breakpoints.up('md'));

  const { startDate, endDate } = product.trip;

  const nights = useMemo(() => dayjs(endDate).diff(startDate, 'days'), [startDate, endDate]);
  const formattedStartDate = dayjs(startDate).format('DD MMM YYYY');
  const formattedEndDate = dayjs(endDate).format('DD MMM YYYY');

  const DateRangeLabel = () => (
    <IconAndLabel Icon={DateRangeIcon} label={`Stay ${formattedStartDate} - ${formattedEndDate}`} />
  );

  return (
    <Stack gap={(theme) => theme.spacing(2)}>
      <Typography component="h4" variant="bodyMdStrong" data-testid="stay-details-header">
        {stayContent.hotelName}
      </Typography>
      <Stack direction="row" alignItems="center">
        <StarRating id="stay-details-rating" value={stayContent.rating} />
        {nights && (
          <>
            <Divider style={{ margin: 7 }} variant="dot" />
            <IconAndLabel Icon={NightsIcon} label={`${nights} nights`} />
          </>
        )}
        {screenLg && !isRetailAlternative && (
          <>
            <Divider style={{ margin: 7 }} variant="dot" />
            <DateRangeLabel />
          </>
        )}
      </Stack>
      {isRetailAlternative && <DateRangeLabel />}
      {!screenLg && !isRetailAlternative && <DateRangeLabel />}

      {screenLg && stayContent?.address?.city && stayContent?.address?.state && (
        <IconAndLabel Icon={FmdGoodIcon} label={`${stayContent.address.city}, ${stayContent.address.state}`} />
      )}
    </Stack>
  );
};
