// Context to handle the product availability fetch / state
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import useSDK from '@src/hooks/useSDK';
import dayjs from 'dayjs';
import { StayLocation } from '@src/types/location.types';
import { isAllTruthy } from '@src/utils';
import utc from 'dayjs/plugin/utc';
import { StayContent } from '@src/types/StayContent.types';
dayjs.extend(utc);

export interface ITraveller {
  adult: number;
  child?: number;
  infant?: number;
}

export type TFlowState = {
  startDate: string;
  endDate: string;
  nights: number;
  departureAirport: string;
  destination?: string;
  travellers?: ITraveller;
  cabinClass?: 'economy' | 'premiumEconomy' | 'businessClass' | 'firstClass';
  selectedStayId?: string;
  coords?: string;
  lat?: string;
  lon?: string;
  slug?: string;
  isFetchingPreselectedFlight: boolean;
  geoId: number;
  isExpedia: boolean;
  fareKey: string | null;
};

export interface IDestinationServiceContent {
  destination: StayLocation;
  destinationContent: any;
}

export type TFlowAPI = {
  setDepartureAirport: (airportIata: string) => void;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  setNights: (nights: number) => void;
  setTravellers: (travellers: ITraveller) => void;
  setCabinClass: (cabinClass: string) => void;
};

type TProps = {
  // destination service
  dsContent?: IDestinationServiceContent;
  // optional flow params
  startDate?: string;
  endDate?: string;
  travellers?: ITraveller;
  nights?: number;
  adults?: number;
  originParam?: string;
  destinationParam?: string;
  expedia?: string | string[];
  // selected product
  productId?: string;
  productContent?: StayContent;

  queryParams?: Record<string, string | string[]>;
};

/**
 * @deprecated
 */
const FlowProvider: React.FC<TProps> = ({ children, ...props }) => {
  const [stapiSdk, stapiState, stapiReady] = useSDK('stapi');
  // need some prop lookup methods
  // i.e. origin & originParam

  // assign values to the redux flow state based on the following by order of precedence
  // query params (deep linking)
  // props (from aurora redirect)
  // defaults
  useEffect(() => {
    const { productId, startDate, travellers, nights, adults, originParam, endDate, expedia, queryParams } = props;
    const destination = props?.dsContent?.destination ?? null;

    if (isAllTruthy(stapiReady)) {
      if (destination) stapiSdk.destination.set(destination);
      const params = new URLSearchParams(window.location.search);

      const _startDate = params.get('startDate') ?? startDate;
      const _endDate = params.get('endDate') ?? endDate;
      let _nights = params.get('nights') ?? nights;
      const _origin = params.get('originParam') ?? originParam;
      const _adults = params.get('adults') ?? adults ?? travellers?.adult ?? 2;
      const _fareKey = params.get('fareKey') || undefined;

      stapiSdk.setStayFlow.startDate(_startDate);
      stapiSdk.setStayFlow.endDate(_endDate);
      stapiSdk.setStayFlow.departureAirport(_origin);
      stapiSdk.setStayFlow.travellers({
        adult: typeof _adults === 'string' ? parseInt(_adults, 10) : _adults,
        child: travellers?.child ?? 0,
        infant: travellers?.infant ?? 0,
      });
      stapiSdk.setStayFlow.selectedStayId(productId);
      stapiSdk.setStayFlow.fareKey(_fareKey);
      if (_nights) {
        if (typeof _nights === 'string') {
          _nights = parseInt(_nights);
        }
        stapiSdk.setStayFlow.nights(_nights);
      }
    }
  }, [stapiReady]);

  // setters
  const api = useMemo<TFlowAPI>(() => {
    const setDepartureAirport = (departureIata: string) => stapiSdk.setStayFlow?.departureAirport(departureIata);
    const setStartDate = (startDate: string) => {
      stapiSdk.setStayFlow?.startDate(dayjs.utc(startDate).toISOString());
    };
    const setEndDate = (endDate: string) => {
      stapiSdk.setStayFlow?.endDate(dayjs.utc(endDate).toISOString());
    };
    const setNights = (nights: number) => stapiSdk.setStayFlow?.nights(nights);
    const setTravellers = (travellers: ITraveller) => stapiSdk.setStayFlow?.travellers(travellers);
    const setCabinClass = (cabinClass: string) => stapiSdk.setStayFlow?.cabinClass(cabinClass);

    return { setDepartureAirport, setStartDate, setEndDate, setNights, setTravellers, setCabinClass };
  }, [stapiReady]);

  // selectors
  const state = useMemo<TFlowState>(() => {
    const { dsContent, destinationParam, productContent, queryParams, expedia } = props;
    const stayFlowState = stapiState && stapiState.stays.stayFlowState;
    return {
      ...stayFlowState,
      destination: dsContent?.destination.iata ?? destinationParam,
      coords: dsContent?.destination.coordinates,
      lat: dsContent?.destination.coordinates.split(',')[0],
      lon: dsContent?.destination.coordinates.split(',')[1],
      slug: dsContent?.destination.slug ?? productContent?.slug,
      isFetchingPreselectedFlight: stapiState?.stays.isFetchingPreselectedFlight,
      geoId: dsContent?.destination.full.geonames_id,
      isExpedia: queryParams?.expedia ?? !!expedia,
    };
  }, [stapiReady, stapiState, props]);

  return (
    <FlowStateApi.Provider value={api}>
      <FlowStateContext.Provider value={state}>{children}</FlowStateContext.Provider>
    </FlowStateApi.Provider>
  );
};

// CONTEXT
const FlowStateApi = createContext<TFlowAPI>(undefined);
const FlowStateContext = createContext<TFlowState>(undefined);

// HOOKS / EXPORTS
export const useFlowContext = () => useContext(FlowStateContext);
export const useFlowAPI = () => useContext(FlowStateApi);

export default FlowProvider;
