import React from 'react';
import { Typography } from '@mui/material';
import { mappedDescriptions } from '../mapper/TermsAndConditionsTitleMapper';

interface TitleProps {
  title: string;
}

// This will need to have a conditional render based on available content
export const Title = (props: TitleProps) => {
  const { title } = props;

  return <Typography variant={'bodyMdStrong'}>{mappedDescriptions[title] || title}</Typography>;
};
