import React from 'react';
import ContentProvider from '@context/ContentContext';
import ShoppingProvider from '@context/ShoppingContext';
import StaySummary from './StaysSummary';
import { OnActionType } from '@src/types';
import { ProductStay, ProductRoomRatePricing } from '@src/types/StayDetailsResponse';
import { StayContent } from '@src/types/StayContent.types';

import ContextToProps, { Contexts } from '@src/context/ContextToProps';
import { Tag } from '@fctg-ds/core';

interface IProps {
  product: ProductStay;
  statusTag?: {
    color: React.ComponentProps<typeof Tag>['color'];
    text: string;
  };
  stay?: StayContent;
  stayId?: string;
  onAction: OnActionType;
  fullWidth?: boolean;
  features?: { [featureFlag: string]: boolean };
  showPricing?: boolean;
  hasMargin?: boolean;
  isRetailAlternative?: boolean;
  isSelectedStay?: boolean;
  loadingId?: string | undefined;
  onStayDetailsOpen?: (stay: StayContent, product: ProductStay) => void;
  onSelectStaysAlt?: (altOfferingId?: string) => () => void;
  pricing?: ProductRoomRatePricing;
  isConfirmationPage?: boolean;
}

const StaySummaryContainer: React.FC<IProps> = ({
  product: p,
  statusTag,
  stay,
  stayId,
  onAction,
  fullWidth,
  features,
  showPricing = false,
  hasMargin = true,
  isRetailAlternative = false,
  onStayDetailsOpen,
  loadingId,
  isSelectedStay = false,
  onSelectStaysAlt,
  pricing,
  isConfirmationPage,
}) => {
  return (
    <ShoppingProvider product={p}>
      <ContentProvider stayContent={stay} stayId={stayId}>
        <ContextToProps enabled={[Contexts.CONTENT, Contexts.SHOPPING]}>
          {({ content: { stayContent }, shopping: { product } }) => (
            <StaySummary
              stayContent={stayContent}
              product={product}
              statusTag={statusTag}
              onAction={onAction}
              fullWidth={fullWidth}
              features={features}
              showPricing={showPricing}
              hasMargin={hasMargin}
              isRetailAlternative={isRetailAlternative}
              onStayDetailsOpen={onStayDetailsOpen}
              loading={loadingId === product.offeringId}
              isSelectedStay={isSelectedStay}
              onSelect={onSelectStaysAlt}
              pricing={pricing}
              isConfirmationPage={isConfirmationPage}
            />
          )}
        </ContextToProps>
      </ContentProvider>
    </ShoppingProvider>
  );
};

export default StaySummaryContainer;
