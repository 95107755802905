import React, { useState } from 'react';
import { styled } from '@fctg-ds/theme';
import { Box, Stack, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { Flex } from '@common/Flex';
import dayjs from 'dayjs';
import { ProductRoomChoice, ProductRoomSelection } from '@src/types/StayDetailsResponse';
import { Divider, Link, Popover } from '@fctg-ds/core';

interface RefundableProps {
  room: ProductRoomChoice;
  initialSelection?: ProductRoomSelection;
  displayOnly?: boolean;
}

export const Refundable: React.FC<RefundableProps> = ({ room, initialSelection, displayOnly = false }) => {
  const [openNonRefundableRules, setOpenNonRefundableRules] = useState(false);
  const [openRefundableRules, setOpenRefundableRules] = useState(false);
  const [defaultAnchorEl, setDefaultAnchorEl] = useState(null);
  if (
    room.rates.every((r, i, arr) => arr[0].additionalInfo.nonRefundable === r.additionalInfo.nonRefundable) ||
    displayOnly
  ) {
    const addInfo = displayOnly
      ? room.rates.find((room) => room.rateId === initialSelection.rateId).additionalInfo
      : room.rates[0].additionalInfo;

    const handleOnOpen = (event, isRefundable: boolean) => {
      isRefundable ? setOpenRefundableRules(true) : setOpenNonRefundableRules(true);
      setDefaultAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setOpenNonRefundableRules(false);
      setOpenRefundableRules(false);
      setDefaultAnchorEl(null);
    };

    const renderRefundRules = (isRefundable?: boolean) => {
      return (
        <Popover
          open={isRefundable ? openRefundableRules : openNonRefundableRules}
          anchorEl={defaultAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <PopoverContent>
            <Stack pt={4} px={4}>
              <Typography variant="priceSm">{isRefundable ? 'Refundable:' : 'Non-refundable:'}</Typography>
              {isRefundable ? (
                <Typography>
                  {`
                  Cancel your reservation before ${dayjs(addInfo.refundableDescription).format(
                    'DD MMM YYYY',
                  )} at ${dayjs(addInfo.refundableDescription).format(
                    'hh:mma',
                  )}, and you'll get a full refund. After that, you'll be
                  charged for the first night of your stay plus taxes and fees. Times are based on the property’s local
                  time.`}
                </Typography>
              ) : (
                <Typography variant="bodySm">
                  If you change or cancel your booking you will not get a refund or credit to use for a future stay.
                  This policy will apply regardless of COVID-19, subject to any local consumer laws.
                </Typography>
              )}
            </Stack>
            <Divider />
            <Stack alignItems="center" pb={2}>
              <Link underline="none" onClick={handleClose}>
                Ok
              </Link>
            </Stack>
          </PopoverContent>
        </Popover>
      );
    };

    return addInfo.nonRefundable ? (
      <>
        <Flex
          sx={{ alignItems: 'center', cursor: 'pointer' }}
          data-testid="roomRefundable"
          onClick={(event) => handleOnOpen(event, false)}
        >
          <RefundText variant="captionMd" warning>
            Non refundable
          </RefundText>
          <Info warning />
        </Flex>
        {openNonRefundableRules && renderRefundRules()}
      </>
    ) : (
      <>
        <Flex
          sx={{ alignItems: 'center', flexWrap: 'wrap', cursor: 'pointer' }}
          onClick={(event) => handleOnOpen(event, true)}
        >
          <RefundText variant="captionMd">Fully refundable</RefundText>
          <Info />
          {addInfo.refundableDescription && (
            <BeforeDate variant="captionSm">
              {dayjs(addInfo.refundableDescription).format('DD MMM YYYY hh:mma')}
            </BeforeDate>
          )}
        </Flex>
        {openRefundableRules && renderRefundRules(true)}
      </>
    );
  }

  return <React.Fragment />;
};

const PopoverContent = styled(Box)(() => ({
  width: '320px',
}));

const Info = styled(InfoOutlined, { shouldForwardProp: (prop) => prop !== 'warning' })<{ warning?: boolean }>(
  ({ theme, warning }) => ({
    color: warning ? theme.token.color.warning.text[' '].value : theme.token.color.upsell.text[' '].value,
    fontSize: theme.spacing(4),
    marginLeft: theme.spacing(1),
  }),
);

const RefundText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'warning' })<{ warning?: boolean }>(
  ({ theme, warning }) => ({
    color: warning ? theme.token.color.warning.text[' '].value : theme.token.color.upsell.text[' '].value,
  }),
);

const BeforeDate = styled(Typography)(({ theme }) => ({
  color: theme.token.color.neutral.text.weak[' '].value,
  marginLeft: theme.spacing(2),
}));
