import React, { useMemo, useState } from 'react';

// local
import { Refundable } from '@stays/StayRoomOptions/StaysRoomOptionsCard/components/Refundable';
import { RoomAttributes } from '@stays/StayRoomOptions/StaysRoomOptionsCard/components/RoomAttributes';
import { Inclusions } from '@stays/StayRoomOptions/StaysRoomOptionsCard/components/Inclusions';
import RoomAmenitiesList from '@public/RoomDetails/components/RoomAmenitiesList';
import RoomDetailsInfo from '@public/RoomDetails/components/RoomDetailsInfo';

// mui
import { CardMedia, Typography, Grid, Card, Stack, Box } from '@mui/material';

// design system
import { Carousel, useBreakpointMapper } from '@fctg-ds/core';
import { ConditionallyFixed, BorderBox } from './RoomDetails.styles';
import type { ProductRoomChoice, ProductRoomRate, ProductStay } from '@src/types/StayDetailsResponse';
import PricingOverview from '@stays/StayRoomOptions/StaysRoomOptionsCard/components/PricingOverview';
import { OnActionType, ActionEvents } from '@src/types';
import { getCheapestRate, getRatesToDisplayByAttribute } from '@src/utils/calculateRoomAttributes';

export interface RoomDetailsProps {
  product: ProductStay;
  onAction: OnActionType;
  choiceKey?: string;
}

/**
 * @deprecated
 */
const RoomDetails: React.FC<RoomDetailsProps> = ({ onAction, product, choiceKey: specificChoiceKey }) => {
  const choiceKey = specificChoiceKey ?? product.rooms.selected[0].choiceKey;

  const selectedRoom: ProductRoomChoice =
    product.rooms.choices.find((room) => room.rates.some((rate) => rate.choiceKey === choiceKey)) ??
    product.rooms.choices[0];

  const [selectedRate, setSelectedRate] = useState<ProductRoomRate>(
    selectedRoom.rates.find((rate) => rate.choiceKey === specificChoiceKey) ?? getCheapestRate(selectedRoom.rates),
  );

  const mappedRates = useMemo(
    () => getRatesToDisplayByAttribute(selectedRoom.rates, selectedRate),
    [selectedRoom, selectedRate],
  );

  const [bpVal]: [
    { carouselHeight: number; isMobile: boolean; showAmenities: boolean; inclusionsLength: number },
    string,
  ] = useBreakpointMapper({
    xs: { carouselHeight: 162, isMobile: true, showAmenities: false, inclusionsLength: 20 },
    sm: { carouselHeight: 162, isMobile: true, showAmenities: false, inclusionsLength: 40 },
    md: { carouselHeight: 240, isMobile: false, showAmenities: false, inclusionsLength: 60 },
    lg: { carouselHeight: 240, isMobile: false, showAmenities: true, inclusionsLength: 90 },
    xl: { carouselHeight: 240, isMobile: false, showAmenities: true, inclusionsLength: 90 },
  });

  const onRateSelection = (selectedRate: ProductRoomRate) => {
    onAction({
      type: ActionEvents.STAY_ROOM_RATE_SELECTION,
      data: { productId: product.productId, choiceKey: selectedRate.choiceKey },
    });
  };

  return (
    <Box>
      {product.rooms.choices.length > 0 && (
        <Carousel>
          {selectedRoom.images.map((img, i) => (
            <Card key={`details-modal-room-img-${i}`}>
              <CardMedia component="img" height={bpVal.carouselHeight} image={img} loading="lazy" />
            </Card>
          ))}
        </Carousel>
      )}
      <Stack sx={{ margin: '1rem 0 0.5rem 0' }} spacing={1}>
        <Typography variant="bodyLgStrong" data-testid="room-details-header">
          {selectedRoom.typeName}
        </Typography>
        <RoomDetailsInfo isMobile={bpVal.isMobile} stay={selectedRoom} />
        {!bpVal.isMobile && <Inclusions rates={selectedRoom.rates} maxLength={bpVal.inclusionsLength} />}
        <Refundable room={selectedRoom} />
      </Stack>
      <Grid container spacing={1}>
        {bpVal.showAmenities && (
          <Grid item xs={12} lg={6}>
            <RoomAmenitiesList amenities={selectedRoom.amenities} />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          {!bpVal.isMobile && (
            <Typography
              component="h4"
              variant="bodyMdStrong"
              sx={{ margin: '1rem 0' }}
              data-testid="room-attributes-title"
            >
              Room Options
            </Typography>
          )}
          <BorderBox isMobile={bpVal.isMobile}>
            <RoomAttributes
              selected={selectedRate}
              attributes={mappedRates}
              onSelect={setSelectedRate}
              noTopBorder={!bpVal.isMobile}
            />
            <ConditionallyFixed isMobile={bpVal.isMobile} data-testid="conditionally-fixed-pricing">
              <PricingOverview
                roomRate={selectedRate}
                nights={product.trip.duration.value}
                onClick={() => onRateSelection(selectedRate)}
              />
            </ConditionallyFixed>
          </BorderBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoomDetails;
