import { GetEndpointsAPIClient } from '@endpoints/client/getEndpointsClient.types';
import getHTTPClient from '@libs/serverless-http-client/getHTTPClient';

const getEndpointsClient: GetEndpointsAPIClient = ({ url }) => ({
  config: async () => {
    const http = getHTTPClient({});
    const endpoint = new URL(`${url}/config`);
    return http
      .get(endpoint.href)
      .then((res) => res)
      .catch((err) => err.response);
  },
});

export default getEndpointsClient;
