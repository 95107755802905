export enum ActionEvents {
  STAY_ROOM_SELECTION = 'stay/room/selection',
  STAY_ROOM_RATE_SELECTION = 'stay/rate/selection',
  STAY_ROOM_RATE_CLOSE = 'stay/rate/close',
  STAY_ROOM_ATTRIBUTES_SELECTION = 'stays/roomDetails/attributeSelection',
  STAY_ROOM_OPEN = 'stays/roomDetails/open',
  STAY_ROOM_CLOSE = 'stays/roomDetails/close',
  STAY_DETAILS_CLOSE = 'stays/details/close',
  STAY_SUMMARY_DETAILS = 'stays/summary/details',
  STAY_SUMMARY_CHANGE = 'stays/summary/change',
  STAY_RETAIL_ALTERNATIVES_CLOSE = 'stays/retailAlternatives/close',
  STAY_RETAIL_ALTERNATIVES_SELECT = 'stays/retailAlternatives/select',
  EMPTY_STATE_BUTTON = 'stays/emptystate/actionBtn',
  EMPTY_STATE_FLIGHTS = 'stays/emptystate/flights',

  FLIGHT_INFO = 'flight/info',
  FLIGHT_CLICK = 'flight/click',
  FLIGHT_BAGGAGE_SUMMARY = 'flight/baggage/summary',

  RESULTS_CARD_SELECTION = 'results/card/selection',
  RESULTS_MAP_TILE_SELECTION = 'results/map/tile/selection',

  CUSTOMISETRIP_UPDATE_ORIGIN = 'customiseTrip/update/origin',
  CUSTOMISETRIP_UPDATE_CHECKIN = 'customiseTrip/update/checkin',
  CUSTOMISETRIP_UPDATE_CHECKOUT = 'customiseTrip/update/checkout',
  CUSTOMISETRIP_UPDATE_TRAVELLERS = 'customiseTrip/update/travellers',
  CUSTOMISETRIP_UPDATE_CABIN_CLASS = 'customiseTrip/update/cabinClass',
  CUSTOMISETRIP_UPDATE_NIGHTS = 'customiseTrip/update/nights',

  SEARCH_UPDATE_ORIGIN = 'stays/search/update/origin',
  SEARCH_UPDATE_DESTINATION = 'stays/search/update/destination',
  SEARCH_UPDATE_DATES = 'stays/search/update/dates',
  SEARCH_UPDATE_TRAVELLERS = 'stays/search/update/travellers',
  SEARCH_UPDATE_TRAVELLERS_SUBMIT = 'stays/search/update/travellers/submit',
  SEARCH_UPDATE_CABIN_CLASS = 'stays/search/update/cabinClass',
  SEARCH_SUBMIT = 'stays/search/submit',

  INTERSTITIAL_CLOSE = 'interstitial/close',
}
export interface OnActionArgs {
  type: ActionEvents;
  data?: any;
}

export type OnActionType = (action: OnActionArgs) => void;

export interface Coordinates {
  lat: string;
  lon: string;
}

interface TravellerInterface {
  adult: number;
  child: number;
  infant: number;
}

export interface StayFlowState {
  stayIds: string[];
  startDate?: string;
  nights?: number;
  departureAirport?: string;
  travellers: TravellerInterface;
  cabinClass: 'economy' | 'premiumEconomy' | 'businessClass' | 'firstClass';
}
