import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Star } from '@mui/icons-material';
import useStyles from './StarRating.styles';

export interface StarRatingProps {
  id: string;
  className?: string;
  value: number;
  max?: number;
}

const StarRating: React.FC<StarRatingProps> = ({ id, value, max = 5, className }) => {
  const { classes } = useStyles();

  const emptyStars = useMemo(() => Math.round(max) - Math.round(value), [max, value]);

  return (
    <Box id={id} data-testid={id} className={className || classes.wrapper}>
      {new Array(Math.round(value)).fill(0).map((_, i) => (
        <Star
          key={`${id}-filled-star-${i}`}
          data-testid={`${id}-filled-star`}
          fontSize="inherit"
          className={classes.filledStar}
        />
      ))}
      {emptyStars > 0 &&
        new Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <Star
              key={`${id}-empty-star-${i}`}
              data-testid={`${id}-empty-star`}
              fontSize="inherit"
              className={classes.emptyStar}
            />
          ))}
    </Box>
  );
};

export default StarRating;
