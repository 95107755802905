import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const equals = (a, b) => {
  if (a === b) return true;
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
  if (a.prototype !== b.prototype) return false;
  const keys = Object.keys(a);
  if (keys.length !== Object.keys(b).length) return false;
  return keys.every((k) => equals(a[k], b[k]));
};

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  const debounced = (...args: Parameters<F>) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const calculateReturnDate = (startDate: string, nights: number) => {
  if (!startDate || !nights || nights < 0) return undefined;

  const start = dayjs.utc(startDate, 'YYYY-MM-DD');
  if (!start.isValid()) return undefined;
  const diff = start.add(nights, 'days');
  if (!diff.isValid()) return undefined;

  return diff.toISOString();
};

export const stripHtmlTags = (input: string) => {
  return input.replace(/<\/?[^>]+(>|$)/g, '');
};
