import React, { useState } from 'react';
import { Modal, SliderModal } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
// TODO: replace from design system
import GalleryQuilt from '@components/ds-candidates/GalleryQuilt';
import ImageQuilt from '@components/ds-candidates/ImageQuilt';

import { Box, Card, CardMedia } from '@mui/material';

// singleton modal type here
export type TImage = {
  url: string;
  description: string;
};

export interface ImageGalleryModalProps {
  /**
   * images to be displayed
   */
  images: TImage[];
}

enum Modals {
  GALLERY_QUILT,
  GALLERY_SLIDER,
  CLOSED,
}

/**
 * @deprecated
 */
const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState<TImage>(undefined);
  const [state, setState] = useState<Modals>(Modals.CLOSED);

  const setActiveModal = (modalType: Modals, image?: TImage) => {
    if (image) {
      setSelectedImage(image);
    }
    setState(modalType);
  };

  React.useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (state === Modals.GALLERY_SLIDER) setState(Modals.GALLERY_QUILT);
        else setState(Modals.CLOSED);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  return (
    <>
      <ImageQuilt images={images} onImageSelect={(image: TImage) => setActiveModal(Modals.GALLERY_QUILT, image)} />

      <Modal fullScreen open={state === Modals.GALLERY_QUILT} onClose={() => setActiveModal(Modals.CLOSED)}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ maxWidth: fc.breakpoints.values['lg'], m: '48px 0px' }}>
            <GalleryQuilt
              images={images}
              onImageSelect={(image: TImage) => setActiveModal(Modals.GALLERY_SLIDER, image)}
              selectedImage={selectedImage}
            />
          </Box>
        </Box>
      </Modal>
      {state === Modals.GALLERY_SLIDER && (
        <SliderModal
          modalStyles={{ overflow: 'hidden' }}
          startIndex={images.findIndex((image: TImage) => image === selectedImage)}
          open={true}
          onClose={() => setActiveModal(Modals.GALLERY_QUILT)}
          withKeybindings
        >
          {images.map((image: TImage) => {
            return (
              <Card key={image.url}>
                <CardMedia component="img" image={image.url} />
              </Card>
            );
          })}
        </SliderModal>
      )}
    </>
  );
};

export default ImageGalleryModal;
