import React from 'react';
import { Divider, SkeletonLoader } from '@fctg-ds/core';
import { Flex } from '@common/Flex';

/**
 * @deprecated
 */
const StayCardSkeleton: React.FC = () => {
  return (
    <Flex column>
      <SkeletonLoader width={'270px'} height={'130px'} variant="rectangular" />
      <SkeletonLoader width={'150px'} height={'25px'} />
      <Flex sx={{ alignItems: 'center' }}>
        <SkeletonLoader width={'80px'} height={'25px'} />
        <Divider style={{ margin: 13 }} variant="dot" />
        <SkeletonLoader width={'60px'} height={'25px'} />
      </Flex>
      <SkeletonLoader width={'120px'} height={'25px'} />
      <SkeletonLoader width={'85px'} height={'25px'} />
    </Flex>
  );
};

export default StayCardSkeleton;
