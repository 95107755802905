import React, { Fragment, ReactElement } from 'react';
import { Amenity } from '@src/types/StayContent.types';
import {
  Accessible,
  BusinessCenterOutlined,
  Casino,
  ChildCare,
  DirectionsBike,
  FitnessCenter,
  GolfCourse,
  Language,
  LocalBar,
  LocalHotel,
  LocalLaundryService,
  LocalLibrary,
  LocalParking,
  MeetingRoom,
  OutdoorGrillOutlined,
  Phone,
  Pool,
  Restaurant,
  SmokeFree,
  Spa,
  SportsTennis,
  Wifi,
} from '@mui/icons-material';
import { Nightclub, Winery } from '@src/components/shared/custom-icons';

export const getIcon = (type: Amenity): ReactElement => {
  switch (type) {
    case 'Restaurant':
      return <Restaurant data-testid="restaurant-icon" />;
    case 'Fitness facilities':
      return <FitnessCenter data-testid="fitness-center-icon" />;
    case 'Pool':
      return <Pool data-testid="pool-icon" />;
    case 'Parking':
      return <LocalParking data-testid="parking-icon" />;
    case 'Free WiFi':
      return <Wifi data-testid="wifi-icon" />;
    case 'Laundry':
      return <LocalLaundryService data-testid="laundry-icon" />;
    case 'Child minding':
      return <ChildCare data-testid="child-minding-icon" />;
    case 'Bar':
      return <LocalBar data-testid="bar-icon" />;
    case 'Accessible':
      return <Accessible data-testid="accessible-icon" />;
    case 'Concierge services':
      return <Phone data-testid="concierge-icon" />;
    case 'Smoke free property':
      return <SmokeFree data-testid="smoke-free-icon" />;
    case 'Tennis court':
      return <SportsTennis data-testid="tennis-icon" />;
    case 'Casino':
      return <Casino data-testid="casino-icon" />;
    case 'Bicycle rentals':
      return <DirectionsBike data-testid="bicycle-icon" />;
    case 'Spa':
      return <Spa data-testid="spa-icon" />;
    case 'Library':
      return <LocalLibrary data-testid="library-icon" />;
    case 'Winery':
      return <Winery id="winery-icon" />;
    case 'BBQ facilities':
      return <OutdoorGrillOutlined data-testid="bbq-facility-icon" />;
    case 'Conference space':
      return <BusinessCenterOutlined data-testid="conference-space-icon" />;
    case 'Meeting rooms':
      return <MeetingRoom data-testid="meeting-room-icon" />;
    case 'Golf course':
      return <GolfCourse data-testid="golf-icon" />;
    case 'Multilingual staff':
      return <Language data-testid="language-icon" />;
    case 'Nightclub':
      return <Nightclub id="nightclub-icon" />;
    case 'Maid services':
      return <LocalHotel data-testid="maid-service-icon" />;
    default:
      return <Fragment />;
  }
};
