import React from 'react';
import ContentProvider from '@src/context/ContentContext';
import ShoppingProvider from '@src/context/ShoppingContext';
import StayDetailModal from './StaysDetailModal';
import { StayDetailModalContextProps } from './StayDetailModal.types';
import ContextToProps, { Contexts } from '@src/context/ContextToProps';

const StayDetailModalContainer: React.FC<StayDetailModalContextProps> = ({ stayId, stay, product, open, onAction }) => {
  return (
    <ShoppingProvider product={product}>
      <ContentProvider stayId={stayId} stayContent={stay}>
        <ContextToProps enabled={[Contexts.SHOPPING, Contexts.CONTENT]}>
          {({ content: { stayContent }, shopping: { product } }) => (
            <StayDetailModal stay={stayContent} product={product} open={open} onAction={onAction} />
          )}
        </ContextToProps>
      </ContentProvider>
    </ShoppingProvider>
  );
};

export default StayDetailModalContainer;
