import React from 'react';
import useStyles from './RoomInfo.style';

import { Box, Typography } from '@mui/material';
import IconAndLabel from '@src/components/ds-candidates/IconAndLabel';
import { Divider } from '@fctg-ds/core';

import { ProductRoomChoice } from '@src/types/StayDetailsResponse';
import { Hotel, Person } from '@mui/icons-material';

export interface RoomInfoProps {
  name?: string;
  room?: ProductRoomChoice;
  beds?: string;
  sleeps?: number;
}

const RoomInfo: React.FC<RoomInfoProps> = ({ name, room, beds, sleeps }) => {
  const { classes } = useStyles();

  return (
    <Box sx={{ width: '100%' }}>
      {name && (
        <Typography component="h5" variant="bodySmStrong">
          {name}
        </Typography>
      )}
      <Box className={classes.roomInfoContainer}>
        {beds && <IconAndLabel Icon={Hotel} label={beds} />}
        {sleeps && (
          <>
            <Divider style={{ margin: 7 }} variant="dot" />
            <IconAndLabel Icon={Person} label={`Sleeps ${sleeps}`} />
          </>
        )}
        {/* 
          This is commented out, this is due to the correct data model being significant to 
          what this component is trying to achieve. We will review this at a later date.
        */}
        {/* {room?.promotions?.length > 0 && (
          <>
            <Divider style={{ margin: 7 }} variant="dot" />
            <TruncatedTypography
              icon={Hotel} // replace with promos icon
              text={room?.promotions.join(', ')}
              initialWidth={186}
              variant={'button'}
            />
          </>
        )} */}
      </Box>
    </Box>
  );
};

export default RoomInfo;
