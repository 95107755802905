type CacheResult<T> = {
  data: T | undefined;
  isLoading: boolean;
  isError: boolean;
};

export const useSDKCache = <T>(req: any, params: Record<string, any>, condition: boolean) => {
  const state: CacheResult<T> = {
    data: undefined,
    isLoading: false,
    isError: false,
  };

  if (condition) {
    const response = req(params);
    state.data = response.data;
    state.isLoading = response.isLoading;
    state.isError = response.isError;
  }

  return [state.data, state.isLoading, state.isError] as [T, boolean, boolean];
};
