import React from 'react';
import { Amenity } from '@src/types/StayContent.types';
import { getIcon } from './iconMapper';
import { Typography } from '@mui/material';
import useStyles from './AmenityListItem.styles';

export interface AmenityListItemProps {
  amenity: Amenity;
  icon?: boolean;
}

const AmenityListItem: React.FC<AmenityListItemProps> = ({ amenity, icon = true }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {icon && getIcon(amenity)}
      <Typography variant="body1" className={classes.labelText}>
        {amenity}
      </Typography>
    </div>
  );
};

export default AmenityListItem;
