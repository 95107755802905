import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import type { RenderComponentFn } from '@libs/serverless-micro-ui/csr/renderComponent.types';
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '@src/utils/createEmotionCache';
import { FCThemeProvider } from '@fctg-ds/theme';

const renderComponent: RenderComponentFn =
  ({ components, store, clients }) =>
  (el, name, props) => {
    const Component = components[name];
    render(
      <Provider store={store}>
        <CacheProvider value={createEmotionCache()}>
          <FCThemeProvider cssBaseline={false}>
            <Component config={store.getState().config} clients={clients} {...props} />
          </FCThemeProvider>
        </CacheProvider>
      </Provider>,
      el,
    );
  };

export default renderComponent;
