import { styled } from '@fctg-ds/theme';
import { Typography } from '@mui/material';
import { Button } from '@fctg-ds/core';

export interface TextExpandStyleProps {
  maxRows?: number;
  isExpanded?: boolean;
}

const CustomTypography = styled(Typography, {
  shouldForwardProp: (props) => !['isExpanded', 'maxRows'].includes(props as string),
})<TextExpandStyleProps>(({ theme, isExpanded, maxRows }) => ({
  textAlign: 'justify',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: isExpanded ? undefined : `${maxRows}`,
  WebkitBoxOrient: 'vertical',
}));

const ReadMoreBtn = styled(Button)(({ theme }) => ({
  backgroundColor: `transparent !important`,
  paddingLeft: `${0}px !important`,
  paddingRight: `${0}px !important`,
}));

export { CustomTypography, ReadMoreBtn };
