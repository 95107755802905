import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { unmountComponentAtNode } from 'react-dom';

const win =
  typeof window !== 'undefined'
    ? window
    : {
        removeEventListener: () => {},
        addEventListener: () => {},
      };

type MicroComponentProps = {
  micro: {
    sdkName: string;
    componentName: string;
  };
  wrapper?: {
    type?: string;
    componentProps?: { [k: string]: any };
  };
  [k: string]: any;
};

const MicroComponent = ({
  micro: { sdkName, componentName },
  wrapper = {},
  ...componentProps
}: MicroComponentProps) => {
  const ref = useRef(null);
  const [ready, setReady] = useState(!!win?.[sdkName]);
  useEffect(() => {
    const onMicroFrontendLoaded = (e) => {
      if (e?.detail?.name === sdkName) setReady(true);
    };
    win.addEventListener('MicroFrontendLoaded', onMicroFrontendLoaded);
    return () => {
      win.removeEventListener('MicroFrontendLoaded', onMicroFrontendLoaded);
      if (ref.current) unmountComponentAtNode(ref.current);
    };
  }, []);
  useEffect(() => {
    if (!ready || !ref.current) return undefined;
    if (typeof win?.[sdkName] !== 'object') return undefined;
    const renderStrap = win?.[sdkName].render(ref.current, componentName, componentProps);
    return renderStrap;
  }, [ready, ref, componentProps]);
  return React.createElement(wrapper?.type || 'div', { ...(wrapper.componentProps || {}), ref }, null);
};

MicroComponent.propTypes = {
  micro: PropTypes.exact({
    sdkName: PropTypes.string.isRequired,
    componentName: PropTypes.string.isRequired,
  }).isRequired,
};

export default MicroComponent;
