import { SecondaryButton } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Stack, Typography } from '@mui/material';

const Frame = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  padding: `${theme.spacing(16)} !important`,
  borderRadius: '16px',
  border: '1px solid #E0E0E0',
  ['& span']: { textAlign: 'center' },
}));

const TitleText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.text.weak[' '].value,
}));

const SubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.token.color.text.weak[' '].value,
  whiteSpace: 'pre-line',
}));

const ActionButton = styled(SecondaryButton)(({ theme }) => ({
  marginLeft: 'auto !important',
  marginRight: 'auto !important',
}));

export { Frame, SubtitleText, ActionButton };
