import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    '& div': {
      color: `${theme.token.color.black[' '].value}`,
    },
  },
}));

export default useStyles;
