import axios from 'axios';
import { nanoid } from 'nanoid';
import { GetHTTPClient } from '@libs/serverless-http-client/getHTTPClient.types';

const getHTTPClient: GetHTTPClient = ({ correlationId = nanoid(), requestId = nanoid(), headers = {} }) =>
  axios.create({
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'X-Correlation-ID': correlationId,
      'X-Request-ID': requestId,
      ...headers,
    },
  });

export default getHTTPClient;
