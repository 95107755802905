import React from 'react';
import { StayResultsCardList } from '@stays/StaysResultsCardList';
import { OnActionType } from '@src/types';
import StayResultsCardSkeleton from '@stays/StaysResultsCardList/StayResultsCardSkeleton';
import { StayResultsCardListProps } from './StayResultsCardList.types';

export interface ResultCardListProps extends StayResultsCardListProps {
  isContentLoading: boolean;
  onAction: OnActionType;
}

/**
 * @deprecated
 */
const StaysResultsCardListContainer: React.FC<ResultCardListProps> = (props) => {
  return (
    <>
      <StayResultsCardSkeleton isContentLoading={props.isContentLoading} />
      <StayResultsCardList {...props} />
    </>
  );
};

export default StaysResultsCardListContainer;
