import { styled } from '@fctg-ds/theme';
import { Typography, Box } from '@mui/material';

export const SectionHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  textTransform: 'capitalize',
  fontWeight: 'bold',
}));

export const ProductDetailsBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(6)} ${theme.spacing(4)}`,
  },
}));

export const StyledList = styled('ul')(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(6),
}));
