import React, { useEffect, useState } from 'react';
import { Drawer, IconButton } from '@fctg-ds/core';
import { Close as CloseIcon } from '@mui/icons-material';
import StaysDetails from '@src/components/public/StaysDetails';
import AmenitiesInfo from '@shared/AmenitiesInfo';
import { ActionEvents } from '@src/types';
import { StayDetailDrawerProps } from './StayDetailDrawer.types';
import NotesFooter from '@src/components/shared/NotesFooter';
import NotesContent from '@src/components/shared/Notes/NotesContent';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const StaysDetailDrawer = ({ stay, open = false, onAction, product }: StayDetailDrawerProps) => {
  if (!stay) return <React.Fragment />;
  const [showNotes, setShowNotes] = useState(false);
  const isMbiStay = product?.summary?.pricing !== undefined;

  useEffect(() => {
    setShowNotes(false);
  }, [open]);

  const onOpen = () => {
    onAction({ type: ActionEvents.STAY_SUMMARY_DETAILS, data: {} });
  };
  const onClose = () => {
    onAction({ type: ActionEvents.STAY_DETAILS_CLOSE, data: {} });
  };

  const renderFooter = () => {
    if (isMbiStay && !showNotes) {
      return <NotesFooter product={product} action={() => setShowNotes(true)} />;
    }

    return null;
  };
  return (
    <Drawer
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      drawerHeaderTitle={showNotes ? 'Notes' : 'Stay'}
      leftActionBtn={
        showNotes ? (
          <IconButton onClick={() => setShowNotes(false)}>
            <ChevronLeftIcon />
          </IconButton>
        ) : (
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )
      }
      footer={renderFooter()}
    >
      {showNotes && isMbiStay ? (
        <NotesContent notes={product?.notes} />
      ) : (
        <>
          <StaysDetails stay={stay} />
          <AmenitiesInfo small amenities={stay.amenities} facilities={stay.facilities} />
        </>
      )}
    </Drawer>
  );
};

export default StaysDetailDrawer;
