import { ProductRoomRate } from '@src/types/StayDetailsResponse';

export enum ProductRoomAttributeKey {
  nonRefundable = 'nonRefundable',
  mealPlan = 'mealPlan',
}

export type CardState = Record<ProductRoomAttributeKey, ProductRoomRate[]>;

export type StayRoomOptionsProps = {
  fallbackImage?: string;
  redirectToCheckout: (sid: string) => void;
};
