import { styled } from '@fctg-ds/theme';

export interface StaySummaryStyleProps {
  fullWidth?: boolean;
  hasMargin?: boolean;
}

const Root = styled('div', {
  shouldForwardProp: (props) => !['fullWidth'].includes(props as string),
})<StaySummaryStyleProps>(({ theme, fullWidth, hasMargin }) => ({
  borderColor: theme.token.color.border.disabled.value,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.token.radius.base.value,
  backgroundColor: theme.token.color.neutral.background.weak[' '].value,

  maxWidth: fullWidth ? '100%' : theme.breakpoints.values.lg,
  marginTop: hasMargin ? theme.token.spacing[6].value : 0,
  [theme.breakpoints.down('md')]: {
    marginTop: hasMargin ? theme.token.spacing[2].value : 0,
    marginBottom: hasMargin ? theme.token.spacing[2].value : 0,
  },
}));

export { Root };
