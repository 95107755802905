import React from 'react';
import { CustomIconProps } from './index';

const Winery: React.FC<CustomIconProps> = ({ id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    data-testid={id}
  >
    <rect fill="none" height="24" width="24" />
    <path d="M6,3l0,6c0,2.97,2.16,5.43,5,5.91V19H8v2h8v-2h-3v-4.09c2.84-0.48,5-2.94,5-5.91l0-6H6z M16,8H8l0-3h8C16,5,16,8,16,8z" />
  </svg>
);

export default Winery;
