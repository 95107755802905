import React, { useEffect, useState } from 'react';
import { Modal } from '@fctg-ds/core';
import StaysDetails from '@src/components/public/StaysDetails';
import AmenitiesInfo from '@shared/AmenitiesInfo';
import RoomInfo from '@src/components/stays/RoomInfo';
import { Box } from '@mui/material';
import type { ProductRoomChoice } from '@src/types/StayDetailsResponse';
import { ActionEvents } from '@src/types';
import { StayDetailModalProps } from './StayDetailModal.types';
import NotesContent from '@src/components/shared/Notes/NotesContent';
import NotesFooter from '@src/components/shared/NotesFooter';

const StaysDetailModal = ({
  stay: stayContent,
  product,
  open = false,
  onAction = () => undefined,
}: StayDetailModalProps) => {
  const [showNotes, setShowNotes] = useState(false);
  const isMbiStay = product?.summary?.pricing !== undefined;

  useEffect(() => {
    setShowNotes(false);
  }, [open]);
  // room data requires the room to be passed.. we cannot guarantee redux state for this
  const roomData: ProductRoomChoice =
    product?.rooms.choices.find((room) =>
      room.rates.some((rate) => rate.choiceKey === product.rooms.selected[0].choiceKey),
    ) ?? product?.rooms.choices[0];

  if (!stayContent) return null;

  const onClose = () => {
    onAction({ type: ActionEvents.STAY_DETAILS_CLOSE, data: {} });
  };

  const renderFooter = () => {
    if (isMbiStay && !showNotes) {
      return <NotesFooter product={product} action={() => setShowNotes(true)} />;
    }

    return null;
  };

  return (
    <Modal
      title={showNotes ? 'Notes' : 'Stay and room'}
      open={open}
      onClose={onClose}
      maxWidth="md"
      maxHeight="75vh"
      noContentPadding
      onMoreClick={() => setShowNotes(false)}
      moreText={showNotes ? 'back' : ''}
      footer={renderFooter()}
    >
      {showNotes && isMbiStay ? (
        <NotesContent notes={product?.notes} />
      ) : (
        <Box sx={{ m: '0 24px' }}>
          <StaysDetails stay={stayContent} product={product} />
          <RoomInfo name={roomData?.typeName} room={roomData} />
          <AmenitiesInfo amenities={stayContent.amenities} facilities={stayContent.facilities} />
        </Box>
      )}
    </Modal>
  );
};

export default StaysDetailModal;
