import React, { useMemo } from 'react';
import MicroComponent from '@src/../dev/common/MicroComponent';
import { SkeletonLoader, useBreakpointMapper } from '@fctg-ds/core';
import { DynamicMapContainer } from './DynamicMapStyles';
import useWindowDimensions from '@src/hooks/useWindowDimensions';
import type { StayContent } from '@fctg-dc/fe-eventbus/dist/events/stays-product-mofo/types';
import { Coordinates, OnActionType } from '@src/types';
import { ITraveller } from '@src/context/FlowContext';
import { StaySearchProduct } from '@src/types/StaySearchProduct';

interface DynamicMapProps {
  stayContent: StayContent[];
  coords: Coordinates;
  geoId: number;
  dateView?: boolean;
  travellers?: ITraveller;
  availability?: StaySearchProduct[];
  nights?: number;
  onAction: OnActionType;
}

/**
 * @deprecated
 */
const DynamicMap: React.FC<DynamicMapProps> = ({
  stayContent,
  coords,
  geoId,
  dateView = false,
  travellers,
  availability,
  nights,
  onAction,
}) => {
  const { width } = useWindowDimensions();
  const [bpValues] = useBreakpointMapper({
    xs: { isMobile: true },
    sm: { isMobile: true },
    md: { isMobile: true },
    lg: { isMobile: false },
    xl: { isMobile: false },
  });
  const locations = useMemo(
    () =>
      stayContent &&
      stayContent.map((_stayContent) => ({
        id: _stayContent.hotelId,
        lat: _stayContent.location.coordinates.lat,
        lng: _stayContent.location.coordinates.lon,
        price: _stayContent.price,
        rating: _stayContent.rating,
        img: _stayContent.images[0],
        hotelName: _stayContent.hotelName,
        slug: _stayContent.slug,
      })),
    [stayContent],
  );
  const mapStyles = {
    borderRadius: bpValues.isMobile ? '0' : '20px',
  };

  return locations && locations.length > 0 ? (
    <DynamicMapContainer width={width}>
      <MicroComponent
        micro={{ sdkName: 'OmniMapMofo', componentName: 'DynamicMap' }}
        lat={coords.lat}
        lng={coords.lon}
        locations={locations}
        height={bpValues.isMobile ? '200px' : '600px'}
        width="100%"
        alt="Map image"
        zoom={14}
        styles={mapStyles}
        geoId={geoId}
        dateView={dateView}
        availability={availability}
        travellers={travellers}
        nights={nights}
        onAction={onAction}
      />
    </DynamicMapContainer>
  ) : (
    <SkeletonLoader variant="rectangular" width="55vw" height="600px" />
  );
};

export default DynamicMap;
