// Presentation Layer For Stay Ameneties
import React, { useState } from 'react';
import { Amenity, StayFacility } from '@src/types/StayContent.types';
import useStyles, { StayAmenitiesSubText, StayAmenitiesHeaderContainer } from './StayAmenities.styles';
import { Modal, ResponsiveList, useBreakpointMapper, ScrollList, Link } from '@fctg-ds/core';
import AmenitiesInfo from '@shared/AmenitiesInfo';
import AmenityListItem from '@shared/AmenityListItem';
import { isAllTruthy } from '@src/utils';
import { StayAmenitiesStyleProps } from './StayAmenities.types';
import { useContentContext } from '@context/ContentContext';
import { useMobileScreen } from '@src/hooks/useMobileScreen';
import { Typography } from '@mui/material';

const StayAmenities: React.FC<StayAmenitiesStyleProps> = ({ fullWidth }) => {
  const { stayContent } = useContentContext();
  const { classes } = useStyles({ fullWidth });
  const [hasModal, setHasModal] = useState(false);
  const [bpValues] = useBreakpointMapper({
    xs: { isMobile: true },
    sm: { isMobile: true },
    md: { isMobile: false },
    lg: { isMobile: false },
    xl: { isMobile: false },
  });

  const amenities: Amenity[] = stayContent?.amenities;
  const facilities: StayFacility[] = stayContent?.facilities;
  const isMobileScreen = useMobileScreen();

  const amenitiesList = () => {
    return amenities.map((amenity, i) => (
      <AmenityListItem key={`${amenity}-${i}`} amenity={amenity} icon={amenities.length >= 3} />
    ));
  };
  if (!isAllTruthy(amenities, facilities)) return <></>;

  return (
    <div data-testid="amenities" className={classes.root}>
      {isMobileScreen ? (
        <>
          <StayAmenitiesHeaderContainer>
            <Typography variant="headingMd">Stay Amenities</Typography>
            <StayAmenitiesSubText>
              <Typography variant="subheadingSm" onClick={() => setHasModal(true)}>
                See all amenities
              </Typography>
            </StayAmenitiesSubText>
          </StayAmenitiesHeaderContainer>
          <ScrollList ariaLabel="Spacing" itemsPerView={3}>
            {amenitiesList()}
          </ScrollList>
        </>
      ) : (
        <ResponsiveList
          title="Stay Amenities"
          showSeeMore
          seeMoreText="See all amenities"
          onSeeMoreClick={() => setHasModal(true)}
          displayXs={2}
          displaySm={3}
          displayLg={4}
          displayMd={4}
          displayXl={4}
        >
          {amenitiesList()}
        </ResponsiveList>
      )}

      <Modal title="Amenities" open={hasModal} onClose={() => setHasModal(false)} maxWidth="md">
        <AmenitiesInfo small={bpValues.isMobile} amenities={amenities} facilities={facilities} />
      </Modal>
    </div>
  );
};

export default StayAmenities;
