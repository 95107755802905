import React, { useState } from 'react';

import { useMediaQuery, Grid, Stack, Typography } from '@mui/material';
import { Link, Modal, SecondaryButton } from '@fctg-ds/core';
import useStyles from './InclusionsList.style';

import Box from '@mui/system/Box';
import { fc } from '@fctg-ds/theme';
import { getIcon } from './components/iconMapper';

export interface Inclusion {
  label: string;
  description: string;
  icon: InclusionType;
  learnMore?: string;
}

export enum InclusionType {
  'Stay',
  'Flights',
  'Transfers',
  'Experience',
  'COVID19',
  'Generic',
}

export interface InclusionsListProps {
  inclusions: Inclusion[];
}

const InclusionsList: React.FC<InclusionsListProps> = ({ inclusions }) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const openModal = () => setOpen(true);
  const onClosed = () => setOpen(false);

  const mobileView = useMediaQuery(fc.breakpoints.down('md'));
  const isUrlOrArchor = (text: string): string | undefined => (text.match(/^(http|#)/) ? text : undefined);

  const ListOfInclusions = ({ modalView }: { modalView: boolean }) => {
    const inclusionsFinal = [...inclusions];
    inclusionsFinal.sort((a, b) => a.icon - b.icon);
    modalView || inclusionsFinal.splice(8);

    return (
      <Grid rowSpacing={8} columnSpacing={2} container data-testid="inclusions" mt="0 !important">
        {inclusionsFinal.map((inclusion, index) => (
          <Grid key={`inclusion-${index}`} item xs={12} md={6}>
            <Stack direction="row" spacing={8}>
              {getIcon(inclusion.icon)}
              <Box className={classes.textBox}>
                <Typography data-testid="inclusion-label" variant="bodyMdStrong" className={classes.labelText}>
                  {inclusion.label}
                </Typography>
                <Box>
                  <Typography data-testid="inclusion-description" variant="bodySm" className={classes.descriptionText}>
                    {inclusion.description}
                  </Typography>
                  {inclusion.learnMore && isUrlOrArchor(inclusion.learnMore) && (
                    <Link className={classes.textClickable} size="small" href={inclusion.learnMore}>
                      Learn More
                    </Link>
                  )}
                </Box>
              </Box>
            </Stack>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <ListOfInclusions modalView={false} />
      {inclusions.length > 8 && (
        <>
          <SecondaryButton className={classes.seeAllInclusionsBtn} onClick={openModal} data-testid="DefaultButton">
            See all {inclusions.length} Inclusions
          </SecondaryButton>
          <Modal open={open} title="Inclusions" onClose={onClosed} fullScreen={mobileView} maxWidth="lg" slideUp>
            <ListOfInclusions modalView={true} />
          </Modal>
        </>
      )}
    </>
  );
};

export default InclusionsList;
