import React from 'react';
import { Tag } from '@fctg-ds/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Typography } from '@mui/material';

type PackagePriceTagProps = {
  formattedTotal: string;
  tagText: string;
};

const PackagePriceTag = ({ formattedTotal, tagText }: PackagePriceTagProps) => {
  return (
    <Stack gap={1} alignItems="flex-end">
      <Tag color="info" startIcon={<InfoOutlinedIcon />}>
        {tagText}
      </Tag>
      <Typography variant="captionMd" color="#757575">{`${formattedTotal} total`}</Typography>
    </Stack>
  );
};

export default PackagePriceTag;
