import bootstrap from '@libs/serverless-micro-ui/bootstrap/bootstrap';
import renderComponent from '@libs/serverless-micro-ui/csr/renderComponent';
import { CreateMicroFrontendFn } from '@libs/serverless-micro-ui/createMicroFrontend.types';

const createMicroFrontend: CreateMicroFrontendFn = (sdk, { store, clients, components, options = {} }) => {
  const { dispatch, subscribe, getState } = store;
  window[sdk] = {
    init: async () => {
      const config = await bootstrap(components, { clients, noConfig: options.noConfig });
      dispatch({ type: 'config/set', config: config.data });
    },
    render: renderComponent({ components, store, clients }),
    dispatch,
    subscribe,
    current: getState,
  };
  // MICRO FRONTEND SDK INITIALISATION
  // If the micro frontend sdk script has been loaded async we will want to init the bootstrap process immediately (think SPA)
  if (document.readyState === 'complete' || document.readyState === 'interactive') window[sdk].init();
  else document.addEventListener('DOMContentLoaded', window[sdk].init, false);
};

export default createMicroFrontend;
