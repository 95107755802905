import React from 'react';
import { Typography } from '@mui/material';
import { Flex } from '@common/Flex';
import { TChartData, TPriceRange, TPriceStats } from '@src/context/ShoppingContext';
import { TextField } from '@fctg-ds/core';
import PriceChart from './PriceChart/PriceChart';
import PriceSlider from './PriceChart/PriceSlider';
import { Dollar } from './Dollar';
import { BodyContainer } from './PriceFilterStyles.styles';
enum BOUNDS {
  MIN = 'min',
  MAX = 'max',
}

interface IProps {
  handleChange: (TPriceRange) => void;
  filterRange: TPriceRange;
  priceStats: TPriceStats;
  chartData: TChartData;
}

/**
 * @deprecated
 */
const PriceFilter: React.FC<IProps> = ({ handleChange, filterRange, priceStats, chartData }) => {
  const onChange = (bound: BOUNDS, value: string) => {
    const min = bound === BOUNDS.MIN ? parseInt(value, 10) : filterRange.min;
    const max = bound === BOUNDS.MAX ? parseInt(value, 10) : filterRange.max;
    handleChange({ min, max });
  };

  return (
    <BodyContainer data-testid={'price-filter-body'}>
      <Flex column sx={{ justifyContent: 'center', maxWidth: '328px' }}>
        <Typography variant="bodySm">
          The average price for a flight + stay is ${Math.ceil(priceStats.average)}
        </Typography>
        <Flex column style={{ marginBottom: '21px' }}>
          <PriceChart chartData={chartData} range={filterRange} />
          <PriceSlider min={priceStats.lowest} max={priceStats.highest} range={filterRange} onChange={handleChange} />
        </Flex>
        <Flex>
          <TextField
            data-testid={'price-filter-min-price-input'}
            label="Min price"
            onChange={(e) => onChange(BOUNDS.MIN, e.target.value)}
            value={filterRange.min.toString()}
            startAdornment={<Dollar />}
            type="number"
            sx={{ marginRight: '10px' }}
          />
          <TextField
            data-testid={'price-filter-max-price-input'}
            label="Max price"
            onChange={(e) => onChange(BOUNDS.MAX, e.target.value)}
            value={filterRange.max.toString()}
            startAdornment={<Dollar />}
            type="number"
          />
        </Flex>
      </Flex>
    </BodyContainer>
  );
};

export default PriceFilter;
