import React from 'react';
import { Box } from '@mui/material';
import StarBox from './StarBox';
import { Flex } from '@common/Flex';

interface IProps {
  onSelectionChange: (index: number) => void;
  rating: boolean[];
}

/**
 * @deprecated
 */
const StarFilter: React.FC<IProps> = ({ onSelectionChange, rating }) => {
  return (
    <Box flex={1} padding={'10px'} justifyContent={'center'} alignItems={'center'} sx={{ display: 'flex' }}>
      <Flex sx={{ flexWrap: 'wrap', maxWidth: '265px', width: '265px', justifyContent: 'center' }}>
        {rating?.map((_, idx: number) => {
          return (
            <StarBox key={`starbox-${idx}`} onClick={() => onSelectionChange(idx)} count={idx} selected={rating[idx]} />
          );
        })}
      </Flex>
    </Box>
  );
};

export default StarFilter;
