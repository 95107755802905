import React from 'react';
import { fc } from '@fctg-ds/theme';
import { Typography } from '@mui/material';
import { OnActionType } from '@src/types';
import { ActionButton, Frame, SubtitleText } from './RoomEmptyState.style';
import { ActionEvents } from '@src/types';

export interface RoomEmptyStateProps {
  title?: string;
  subtitle?: string[];
  btnText?: string;
  onAction?: OnActionType;
}

const RoomEmptyState: React.FC<RoomEmptyStateProps> = ({
  title = 'Add dates to view rooms',
  subtitle,
  btnText,
  onAction = () => undefined,
}) => {
  const onClick = () => onAction({ type: ActionEvents.EMPTY_STATE_BUTTON, data: null });

  return (
    <Frame spacing={fc.spacing(4)} data-testid="stays-empty-state">
      <Typography variant="headingMd">{title}</Typography>
      {subtitle && (
        <SubtitleText data-testid="emptystate-subtitle" variant="bodySm">
          {subtitle.join('\n')}
        </SubtitleText>
      )}
      {btnText && <ActionButton onClick={onClick}>{btnText}</ActionButton>}
    </Frame>
  );
};

export default RoomEmptyState;
