import { Box, SvgIconTypeMap, Typography, TypographyTypeMap } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { styled } from '@fctg-ds/theme';
import IconAndLabel from '../IconAndLabel';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type TruncatedTypographyProp = {
  text: string;
  initialWidth: number;
  singleLine?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
} & TypographyTypeMap['props'];

const GrowBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
}));

/**
 * @deprecated
 */
const TruncatedTypography: React.FC<TruncatedTypographyProp> = ({ text, initialWidth, icon, ...args }) => {
  const typographyRef = useRef<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({ width: initialWidth });

  let resize_timer: NodeJS.Timeout;
  const reset_timeout = 100;

  const updateDimensions = () => {
    if (typographyRef.current) {
      setDimensions({
        width: typographyRef.current.offsetWidth,
      });
    } else triggerDimensionUpdate();
  };

  const triggerDimensionUpdate = () => {
    clearInterval(resize_timer);
    resize_timer = setTimeout(updateDimensions, reset_timeout);
  };

  useLayoutEffect(updateDimensions, [typographyRef]);

  window.addEventListener('resize', triggerDimensionUpdate);

  const formatText = (str: string): string => {
    const size: number = dimensions.width / 9;

    if (str.length > size) return `${str.substring(0, size)}...`;
    else return str;
  };

  return (
    <GrowBox ref={typographyRef}>
      {icon ? (
        <IconAndLabel Icon={icon} grow={true} label={formatText(text)} />
      ) : (
        <CustomTypography {...args}>{formatText(text)}</CustomTypography>
      )}
    </GrowBox>
  );
};

export default TruncatedTypography;
