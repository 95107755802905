import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

export const BodyContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '20px 10px 20px 10px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
}));
