import React, { useState, useEffect } from 'react';
import { Chip, Popover, Drawer, IconButton, MenuItem } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import { Stack, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import type { SortTypes } from '@src/context/ShoppingContext';
import { FilterChipContainer } from '@src/components/stays/SortAndFilters/Shared';

const DEFAULT_SORT_STATE = 'recommended';

interface SortProps {
  sort: { sort: SortTypes };
  setSortType: (sortType: string) => void;
}

/**
 * @deprecated
 */
const Sort = ({ sort, setSortType }: SortProps) => {
  const [active, setActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [activeSort, setSort] = useState<string>(DEFAULT_SORT_STATE);

  const isMobile = useMediaQuery(fc.breakpoints.down('md'));

  useEffect(() => {
    if (sort) {
      const currentSort = sort.sort as unknown as string;
      setSort(currentSort);
    }
  }, [sort, active]);

  const handleOpen = (event: React.SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    setActive(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setActive(false);
    setAnchorEl(null);
  };

  const handleSelectionChange = (sortType: string) => {
    setSortType(sortType);
    handleClose();
  };

  const selections: { key: string; value: string; displayValue: string }[] = [
    { key: 'recommended', value: 'RECOMMENDED', displayValue: 'Recommended' },
    { key: 'lowest_price', value: 'LOWEST_PRICE', displayValue: 'Lowest price' },
    { key: 'highest_price', value: 'HIGHEST_PRICE', displayValue: 'Highest price' },
    { key: 'closest_to_airport', value: 'CLOSEST_TO_AIRPORT', displayValue: 'Closest to airport' },
    { key: 'closest_to_destination', value: 'CLOSEST_TO_DESTINATION', displayValue: 'Closest to destination' },
    { key: 'most_stars', value: 'MOST_STARS', displayValue: 'Most stars' },
  ];

  const activeSortLabel =
    selections.filter(({ value, key }) => value === activeSort || key === activeSort)?.[0] ?? selections[0];

  const show = Boolean(anchorEl);

  const SortMenuItem: React.FC<{ sortType: string; title: string }> = ({ sortType, title }) => (
    <MenuItem selected={activeSort === sortType} onClick={() => handleSelectionChange(sortType)}>
      {title}
    </MenuItem>
  );

  return (
    <FilterChipContainer>
      <Chip
        label={activeSort === 'recommended' ? 'Sort' : activeSortLabel.displayValue}
        isActive={active}
        onClick={handleOpen}
        endIcon={<ExpandMoreOutlinedIcon />}
      />
      {isMobile ? (
        <Drawer
          open={show}
          onOpen={() => undefined}
          onClose={handleClose}
          drawerHeaderTitle="Sort"
          leftActionBtn={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
          maxHeight={'80%'}
        >
          <Stack direction="column" spacing="2">
            <SortMenuItem title="Recommended" sortType="RECOMMENDED" />
            <SortMenuItem title="Lowest price" sortType="LOWEST_PRICE" />
            <SortMenuItem title="Highest price" sortType="HIGHEST_PRICE" />
            <SortMenuItem title="Closest to airport" sortType="CLOSEST_TO_AIRPORT" />
            <SortMenuItem title="Closest to destination" sortType="CLOSEST_TO_DESTINATION" />
            <SortMenuItem title="Most stars" sortType="MOST_STARS" />
          </Stack>
        </Drawer>
      ) : (
        <Popover
          open={show}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
        >
          <Stack direction="column" spacing="2">
            <SortMenuItem title="Recommended" sortType="RECOMMENDED" />
            <SortMenuItem title="Lowest price" sortType="LOWEST_PRICE" />
            <SortMenuItem title="Highest price" sortType="HIGHEST_PRICE" />
            <SortMenuItem title="Closest to airport" sortType="CLOSEST_TO_AIRPORT" />
            <SortMenuItem title="Closest to destination" sortType="CLOSEST_TO_DESTINATION" />
            <SortMenuItem title="Most stars" sortType="MOST_STARS" />
          </Stack>
        </Popover>
      )}
    </FilterChipContainer>
  );
};

export default Sort;
