import React from 'react';
import ContentProvider from '@src/context/ContentContext';
import StayAmenities from './StayAmenities';
import { StayAmenitiesStyleProps } from './StayAmenities.styles';
import { StayContent } from '@src/types/StayContent.types';

export interface StayAmenitiesProps extends StayAmenitiesStyleProps {
  stayId?: string;
  stay?: StayContent;
}

const StayAmenitiesContainer: React.FC<StayAmenitiesProps> = ({ stay, stayId, fullWidth }) => {
  return (
    <ContentProvider stayContent={stay} stayId={stayId}>
      <StayAmenities fullWidth={fullWidth} />
    </ContentProvider>
  );
};

export default StayAmenitiesContainer;
