import React, { useEffect, useRef } from 'react';
import { ImageList, ImageListItem } from '@mui/material';

import { TImage } from './GalleryQuilt.types';

export interface GalleryQuiltProps {
  /**
   * image data
   */
  images: TImage[];
  /**
   * the height to assign to a full row in px
   * @default 360px
   */
  rowHeight?: number;
  /**
   * size in px for the gap between the image elements
   * @default 6px
   */
  gap?: number;
  /**
   * the selected instance of TImage to scroll into view
   */
  selectedImage?: TImage;
  /**
   * click handler for each image in the component
   */
  onImageSelect?: (image: TImage) => void;
}

const DEFAULT_GAP = 6; // px
const DEFAULT_GRID_COLUMNS = 12;
const DEFAULT_ROW_HEIGHT = 224; // px

const configImages = (images: TImage[]) => {
  return images.map((image: TImage, idx: number) => {
    const halfWidth = 6;
    const fullWidth = 12;
    const halfHeight = 1;
    const fullHeight = 2;
    return {
      ...image,
      cols: idx % 3 > 0 ? halfWidth : fullWidth,
      rows: idx % 3 > 0 ? halfHeight : fullHeight,
      ref: useRef(null),
    };
  });
};

/**
 * @deprecated
 */
const GalleryQuilt: React.FC<GalleryQuiltProps> = (props: GalleryQuiltProps) => {
  const { images, rowHeight, gap, onImageSelect, selectedImage } = props;
  const data = configImages(images);

  const onClickHandler = (image: TImage) => {
    if (onImageSelect) {
      onImageSelect(image);
    }
  };

  useEffect(() => {
    // scroll to associated image
    if (selectedImage) {
      const _img = data.find((img: TImage) => img.url === selectedImage.url);
      _img.ref.current.scrollIntoView();
    }
  }, [selectedImage, data]);

  return (
    <ImageList gap={gap ?? DEFAULT_GAP} cols={DEFAULT_GRID_COLUMNS} sx={{ borderRadius: '16px' }}>
      {data.map((image: TImage, idx: number) => {
        return (
          <ImageListItem key={`gallery-${idx}`} cols={image.cols} rows={image.rows}>
            <img
              data-testid={'image-gallery-image'}
              ref={image.ref}
              src={image.url}
              style={{ objectFit: 'cover', height: (rowHeight ?? DEFAULT_ROW_HEIGHT) * image.rows, width: '100%' }}
              onClick={() => onClickHandler(images[idx])}
            />
          </ImageListItem>
        );
      })}
    </ImageList>
  );
};

export default GalleryQuilt;
