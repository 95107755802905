import { styled } from '@fctg-ds/theme';
import { Stack } from '@mui/material';

export interface StayDetailsInfoStyleProps {
  fullWidth?: boolean;
}

const Root = styled(Stack, {
  shouldForwardProp: (props) => !['fullWidth'].includes(props as string),
})<StayDetailsInfoStyleProps>(({ theme, fullWidth }) => ({
  maxWidth: fullWidth ? '100%' : theme.breakpoints.values.lg,
}));

export { Root };
