import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: theme.typography.h3.fontSize,
  },
  filledStar: {
    color: theme.token.color.neutral.icon[' '].value,
  },
  emptyStar: {
    color: theme.token.color.neutral.background.strong.hovered.value,
  },
}));

export default useStyles;
