import React from 'react';
import ContentProvider from '@src/context/ContentContext';
import StayDetails from './StaysDetails';
import { StayContent } from '@src/types/StayContent.types';
import { ProductStay } from '@src/types/StayDetailsResponse';
import ShoppingProvider from '@src/context/ShoppingContext';
import ContextToProps, { Contexts } from '@src/context/ContextToProps';

interface StayDetailsProps {
  hotelId?: string;
  stay?: StayContent;
  product?: ProductStay;
}

const StayDetailsContainer: React.FC<StayDetailsProps> = ({ stay, product, hotelId }) => {
  return (
    <ShoppingProvider product={product}>
      <ContentProvider stayContent={stay} stayId={hotelId}>
        <ContextToProps enabled={[Contexts.CONTENT]}>
          {({ content: { stayContent } }) => <StayDetails stayContent={stayContent} />}
        </ContextToProps>
      </ContentProvider>
    </ShoppingProvider>
  );
};

export default StayDetailsContainer;
