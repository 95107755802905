import React from 'react';
import MicroComponent from '@src/../dev/common/MicroComponent';
import { Box } from '@mui/material';
import { useContentContext } from '@src/context/ContentContext';
import MapLabel from '@stays/MapLabel';

/**
 * @deprecated
 */
const StaticMap: React.FC = () => {
  const { stayContent } = useContentContext();
  return (
    <Box sx={(theme) => ({ my: theme.spacing(10) })}>
      <MicroComponent
        micro={{ sdkName: 'OmniMapMofo', componentName: 'StaticMap' }}
        lat={stayContent.location.coordinates.lat}
        lng={stayContent.location.coordinates.lon}
        fullWidth
        height={300}
        alt="Map image"
      />
      <MapLabel />
    </Box>
  );
};

export default StaticMap;
