import React from 'react';
import { Typography } from '@mui/material';
import { Flex } from '@common/Flex';

interface DetailsLinkProps {
  onClick: () => void;
}

// This will need to have a conditional render based on available content

/**
 * @deprecated
 */
export const DetailsLink: React.FC<DetailsLinkProps> = ({ onClick }) => (
  <Flex sx={{ alignItems: 'center' }} data-testid="roomDetailsBtn">
    <Typography
      component="a"
      variant="bodySmStrong"
      onClick={onClick}
      sx={{
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      Room details
    </Typography>
  </Flex>
);
