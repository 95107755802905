import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const ConditionallyFixed = styled(Box, { shouldForwardProp: (prop) => prop !== 'isMobile' })<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    position: isMobile ? 'fixed' : 'inherit',
    bottom: 0,
    left: 0,
    right: 0,

    minHeight: isMobile ? theme.token.spacing[8].value : 0,
    padding: isMobile ? `${theme.token.spacing[1].value} ${theme.token.spacing[3].value}` : 0,

    borderColor: theme.token.color.neutral.border.disabled.value,
    borderStyle: isMobile ? 'solid' : 'none',
    borderTopWidth: 1,
  }),
);

const BorderBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isMobile' })<{ isMobile?: boolean }>(
  ({ theme, isMobile }) => ({
    padding: isMobile ? 0 : theme.token.spacing[4].value,
    borderColor: theme.token.color.neutral.border.disabled.value,
    borderStyle: isMobile ? 'none' : 'solid',
    borderWidth: 1,
    borderRadius: 20,
  }),
);

export { ConditionallyFixed, BorderBox };
