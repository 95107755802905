import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  labelText: {
    marginLeft: `${theme.spacing(2)} !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: `${theme.typography.bodySm.fontSize}px !important`,
    },
  },
}));

export default useStyles;
