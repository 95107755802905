import React from 'react';
import ContentProvider from '@src/context/ContentContext';
import StayDetailDrawer from './StaysDetailDrawer';
import { StayDetailDrawerContextProps } from './StayDetailDrawer.types';
import ContextToProps, { Contexts } from '@src/context/ContextToProps';
import ShoppingProvider from '@src/context/ShoppingContext';

const StayDetailDrawerContainer: React.FC<StayDetailDrawerContextProps> = ({
  stayId,
  stay,
  open,
  onAction,
  product,
}) => {
  return (
    <ShoppingProvider product={product}>
      <ContentProvider stayId={stayId} stayContent={stay}>
        <ContextToProps enabled={[Contexts.CONTENT]}>
          {({ content: { stayContent } }) => (
            <StayDetailDrawer open={open} product={product} onAction={onAction} stay={stayContent} />
          )}
        </ContextToProps>
      </ContentProvider>
    </ShoppingProvider>
  );
};

export default StayDetailDrawerContainer;
