import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  roomInfoContainer: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginTop: theme.token.spacing[1].value,
    marginBottom: theme.token.spacing[2].value,
    overflow: 'clip',
    width: '100%',
    ['p']: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  iconAndLabelWrapper: { flexGrow: 1 },
}));

export default useStyles;
