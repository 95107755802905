import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';

const RootBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    width: '564px',
    gap: '24px',
  },
}));

export { RootBox };
