import React, { useMemo, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Amenity, StayFacility } from '@src/types/StayContent.types';
import AmenityListItem from '@src/components/shared/AmenityListItem';
import { AmenitiesContainer, ReadMoreBtn } from './AmenitiesInfo.styles';

export interface AmenitiesInfoProps {
  amenities: Amenity[];
  facilities: StayFacility[];
  small?: boolean;
  icons?: boolean;
  truncatedLength?: number | false;
}

const AmenitiesInfo: React.FC<AmenitiesInfoProps> = ({
  amenities = [],
  facilities = [],
  small = false,
  icons = false,
  truncatedLength = 10,
}) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const amenityList = useMemo(
    () => amenities.map((amenity) => amenity.toString()).concat(facilities.map((facility) => facility.description)),
    [amenities, facilities],
  );

  return (
    <AmenitiesContainer>
      {icons && (
        <Grid container rowSpacing={1} marginY={(theme) => `${theme.spacing(2)} !important`}>
          {amenities.map((amenity) => (
            <Grid key={amenity} item xs={6}>
              <AmenityListItem amenity={amenity} />
            </Grid>
          ))}
        </Grid>
      )}
      <Box>
        <Typography component="h3" variant={small ? 'bodySmStrong' : 'bodyMdStrong'}>
          Stay Amenities
        </Typography>
        <Grid container component="ul" maxWidth={(theme) => theme.breakpoints.values.md}>
          {(truncatedLength !== false && isTruncated ? amenityList.slice(0, truncatedLength) : amenityList).map(
            (amenity, i) => (
              <Grid key={`amenity-${i}`} item xs={12} sm={6}>
                <Typography component="li" variant={small ? 'bodySm' : 'bodyMd'} key={`${amenity}-${i}`}>
                  {amenity}
                </Typography>
              </Grid>
            ),
          )}
        </Grid>
        {truncatedLength !== false && amenityList.length > truncatedLength && (
          <ReadMoreBtn
            data-testid={`amenities-read-button`}
            size={small ? 'small' : 'medium'}
            onClick={() => setIsTruncated(!isTruncated)}
          >
            {isTruncated ? 'Read More' : 'Read Less'}
          </ReadMoreBtn>
        )}
      </Box>
    </AmenitiesContainer>
  );
};

export default AmenitiesInfo;
