import { styled } from '@fctg-ds/theme';
import { Stack } from '@mui/material';

const SearchFormBanner = styled(Stack)(({ theme }) => {
  return {
    position: 'sticky',
    top: process.env.NODE_ENV === 'development' ? 64 : 0,
    backgroundColor: theme.token.color.background[' '].value,
    zIndex: 2,
    flex: 1,
    width: '100%',
  };
});

export { SearchFormBanner };
