import React from 'react';
import MapLabel, { MapLabelProps } from './MapLabel';
import ShoppingProvider from '@src/context/ShoppingContext';
import ContextToProps, { Contexts } from '@src/context/ContextToProps';
import ContentProvider from '@src/context/ContentContext';

/**
 * @deprecated
 */
const MapLabelContainer: React.FC<MapLabelProps> = (props) => {
  return (
    <ShoppingProvider preselectedFlight={props.preselectedFlight}>
      <ContentProvider stayContent={props.stayContent}>
        <ContextToProps enabled={[Contexts.SHOPPING, Contexts.CONTENT]}>
          {({ content: { stayContent }, shopping: { preselectedFlight: flight } }) => {
            return <MapLabel preselectedFlight={flight} stayContent={stayContent} />;
          }}
        </ContextToProps>
      </ContentProvider>
    </ShoppingProvider>
  );
};

export default MapLabelContainer;
