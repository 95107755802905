import { styled } from '@fctg-ds/theme';
import { Stack, Typography } from '@mui/material';

const SelectedBorder = styled(Stack, { shouldForwardProp: (prop) => prop !== 'hasBorder' })<{ hasBorder?: boolean }>(
  ({ theme, hasBorder }) => ({
    position: 'relative',
    borderColor: hasBorder ? 'transparent' : theme.token.color.border.active.value,
    borderStyle: hasBorder ? 'none' : 'solid',
    borderWidth: 2,
    borderRadius: theme.token.radius.base.value,
    height: '100%',
  }),
);

const SelectedLabel = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  display: 'block',
  top: 0,
  left: 0,
  right: 0,
  color: 'white',
  padding: theme.spacing(1),
  background: theme.token.color.border.active.value,
  borderRadius: `${theme.token.radius.md.value} ${theme.token.radius.md.value} 0 0`,
}));

export { SelectedBorder, SelectedLabel };
