import React, { useState, useRef } from 'react';
import { Typography, Stack, Box } from '@mui/material';
import StaysRoomOptionsCard from './StaysRoomOptionsCard';
import { Carousel, Arrows, ConditionalWrapper, SkeletonLoader } from '@fctg-ds/core';
import { Flex } from '@common/Flex';
import { useBreakpointMapper } from '@fctg-ds/core';
import { ProductRoomChoice } from '@src/types/StayDetailsResponse';

import { NoAvailabiltyBox } from './StayRoomOptions.styles';
import { ActionEvents, OnActionArgs } from '@src/types';
import { useShoppingContext, useShoppingAPI } from '@context/ShoppingContext';
import { StayRoomOptionsProps } from './StaysRoomOptions.types';

/**
 * @deprecated
 */
const StayRoomOptions: React.FC<StayRoomOptionsProps> = ({ redirectToCheckout, fallbackImage }) => {
  const { product } = useShoppingContext();
  const { addOfferingToCart } = useShoppingAPI();

  const carouselRef = useRef(null);
  const [index, setIndex] = useState(0);
  const [breakpointValues, breakpoint] = useBreakpointMapper({
    xs: { direction: 'column', spacing: 4 },
    sm: { direction: 'row', spacing: 2 },
    md: { peek: 5, itemsPerView: 2 },
    lg: { peek: 7, itemsPerView: 3 },
    xl: { peek: 10, itemsPerView: 3 },
  });

  const addToCart = async (data) => {
    const { offeringId, choiceKey } = data;
    const sid = await addOfferingToCart(offeringId, choiceKey);
    redirectToCheckout(sid);
  };

  const cardOnAction = async ({ type, data }: OnActionArgs) => {
    switch (type) {
      case ActionEvents.STAY_ROOM_RATE_SELECTION:
        await addToCart({ choiceKey: data.choiceKey, offeringId: product.offeringId });
        break;

      default:
        break;
    }
  };

  return (
    <Box data-testid="stays-room-options" sx={{ width: '100%' }}>
      <Flex sx={{ alignItems: 'center', marginBottom: '30px' }}>
        <Typography id="room-options" sx={{ flex: 1 }} variant="headingMd">
          Room Options
        </Typography>
        {!['xs', 'sm'].includes(breakpoint) && (
          <Arrows
            carouselRef={carouselRef}
            itemIndex={index}
            numItems={product?.rooms?.choices.length}
            itemsPerView={2}
          />
        )}
      </Flex>
      {product?.rooms?.choices.length > 0 ? (
        <ConditionalWrapper
          condition
          wrapper={(children) => {
            if (['xs', 'sm'].includes(breakpoint)) {
              return (
                <Stack spacing={breakpointValues.spacing} direction={breakpointValues.direction}>
                  {children}
                </Stack>
              );
            } else {
              return (
                <Carousel
                  ref={carouselRef}
                  itemsPerView={breakpointValues.itemsPerView}
                  peek={breakpointValues.peek}
                  onMove={(carousel) => setIndex(carousel.index)}
                  hidePagination
                  hideArrows
                  alignItems={'flex-start'}
                >
                  {children}
                </Carousel>
              );
            }
          }}
        >
          {product.rooms.choices.map((room: ProductRoomChoice) => (
            <StaysRoomOptionsCard
              key={room.roomId}
              stayRoom={room}
              nights={product.trip.duration.value}
              onAction={cardOnAction}
              fallbackImage={fallbackImage}
              initalSelection={product.rooms.selected[0]}
            />
          ))}
        </ConditionalWrapper>
      ) : product ? (
        <NoAvailabiltyBox>
          <Typography variant="headingSm">No availability</Typography>
        </NoAvailabiltyBox>
      ) : (
        <SkeletonLoader variant="rectangular" height={400} width={'100%'} />
      )}
    </Box>
  );
};

export default StayRoomOptions;
