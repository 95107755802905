import { Hotel, Flight, CompareArrows, LocalActivity, Coronavirus, Check } from '@mui/icons-material';
import React, { Fragment, ReactElement } from 'react';
import { InclusionType } from '../InclusionsList';

export const getIcon = (type: InclusionType): ReactElement => {
  switch (type) {
    case InclusionType.Stay:
      return <Hotel data-testid="hotel-icon" />;
    case InclusionType.Flights:
      return <Flight sx={{ transform: 'rotate(45deg)' }} data-testid="flight-icon" />;
    case InclusionType.Transfers:
      return <CompareArrows data-testid="transfers-icon" />;
    case InclusionType.Experience:
      return <LocalActivity data-testid="experience-icon" />;
    case InclusionType.COVID19:
      return <Coronavirus data-testid="coronavirus-icon" />;
    case InclusionType.Generic:
      return <Check data-testid="check-icon" />;
    default:
      return <Fragment />;
  }
};
