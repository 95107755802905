import { SkeletonLoader } from '@fctg-ds/core';
import RoomEmptyState, { RoomEmptyStateProps } from '@stays/RoomEmptyState';
import { ActionEvents, OnActionType } from '@src/types';
import React from 'react';

export interface FlightStateProps {
  hasAirports: boolean;
  hasDates: boolean;
  onAction: OnActionType;
  isFetchingPreselectedFlight: boolean;
  hasPreselectedFlight: boolean;
}

/**
 * @deprecated
 */
const FlightState: React.FC<FlightStateProps> = ({
  hasAirports,
  hasDates,
  isFetchingPreselectedFlight,
  hasPreselectedFlight,
  onAction,
}) => {
  const handleClick: OnActionType = ({ type, data }) => {
    switch (type) {
      case ActionEvents.EMPTY_STATE_BUTTON:
        onAction({ type: ActionEvents.EMPTY_STATE_FLIGHTS });
        break;

      default:
        break;
    }
  };

  let content: Omit<RoomEmptyStateProps, 'onAction'> = {
    title: 'No flights included',
    subtitle: [
      'Bundle your stay with return flights and save!',
      'Simply add your dates then departure airport to include return flights.',
    ],
    btnText: 'Check availability',
  };

  if (isFetchingPreselectedFlight) {
    return <SkeletonLoader data-testid="stays-empty-state-loading" variant="rectangular" width="100%" height={400} />;
  } else if (!isFetchingPreselectedFlight && !hasPreselectedFlight && hasAirports && hasDates) {
    content = {
      title: 'No flight availability',
      subtitle: ['Try selecting new dates next time.'],
      btnText: 'Try new dates',
    };
  } else if (hasAirports) {
    content = {
      title: 'Add dates to see flights',
      subtitle: ['Simply add your dates to find return flights.'],
      btnText: 'Check availability',
    };
  } else if (hasDates) {
    content = {
      title: 'No flights included',
      subtitle: ['Bundle your stay with return flights and save!'],
      btnText: 'Add flights to your holiday',
    };
  }

  return <RoomEmptyState {...content} onAction={handleClick} />;
};

export default FlightState;
