import React from 'react';
import { styled } from '@fctg-ds/theme';

interface IProps {
  maxCount: number;
  value: number;
  inRange: boolean;
}

const BAR_COUNT = 20;

interface IBar {
  height: number;
  width: number;
  inRange: boolean;
}

const Bar = styled('div')<IBar>(({ theme, height, width, inRange }) => ({
  height: `${height}%`,
  width: `${width}%`,
  borderRadius: `5px 5px 0px 0px`,
  backgroundColor: inRange
    ? theme.token.color.neutral.border[' '].value
    : theme.token.color.neutral.background.medium[' '].value,
}));

/**
 * @deprecated
 */
const ChartBar: React.FC<IProps> = ({ maxCount, value, inRange }) => {
  const height = (value / maxCount) * 100;
  const width = 100 / BAR_COUNT;
  return (
    <Bar data-testid={'chart-bar'} title={`Hotel count: ${value}`} height={height} width={width} inRange={inRange} />
  );
};

export default ChartBar;
