import { Flight } from '@src/types/flights.types';

interface Coordinates {
  lat: number;
  lon: number;
}

export const getDestinationAirportCoordinates = (flight: Flight): Coordinates => {
  const destination = flight.summary.destination.city;
  const destinationWithCoordinates = flight.airportsMap.find((airport) => {
    return airport.cityCode === destination;
  });

  return {
    lat: destinationWithCoordinates.latitude,
    lon: destinationWithCoordinates.longitude,
  };
};

export const getDistanceFromLatLonInKm = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};
