import React from 'react';
import { Flex } from '@common/Flex';
import { PrimaryButton, SkeletonLoader } from '@fctg-ds/core';
import { ProductRoomRate } from '@src/types/StayDetailsResponse';
import { PriceSummary } from './PriceSummary';
import DoneIcon from '@mui/icons-material/Done';

interface PricingOverviewProps {
  roomRate?: ProductRoomRate;
  nights: number;
}

/**
 * @deprecated
 */
const PricingOverview: React.FC<PricingOverviewProps> = ({ roomRate, nights }) => {
  if (!roomRate) return <SkeletonLoader variant="rectangular" height={20} width={'100%'} />;
  return (
    <Flex
      sx={{
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        padding: (theme) => `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)}`,
      }}
    >
      <PriceSummary pricing={roomRate.pricing} nights={nights} priceDifference={roomRate.choicePriceDifference} />
    </Flex>
  );
};

export default PricingOverview;
