import { Divider, PrimaryButton } from '@fctg-ds/core';
import { Box, Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { formatPrice } from '../StayRoomOptions/StaysRoomOptionsCard/helpers/utils';
import { ProductRoomRate } from '@src/types/StayDetailsResponse';
import { ActionEvents, OnActionType } from '@src/types';
import useSDK from '@src/hooks/useSDK';

type RoomOptionsFooterProps = {
  selected: ProductRoomRate;
  onAction: OnActionType;
  pendingChange: boolean;
};

const Sticky = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.token.color.white[' '].value,
  marginTop: theme.token.spacing[6].value,
}));

const Bar = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: `${theme.token.spacing[2].value}`,
}));

const RoomOptionsFooter = ({ selected, pendingChange, onAction }: RoomOptionsFooterProps) => {
  const [_, checkoutState] = useSDK('checkout');

  const onSelect = () => onAction({ type: ActionEvents.STAY_ROOM_RATE_SELECTION, data: selected });
  const onClose = () => onAction({ type: ActionEvents.STAY_ROOM_RATE_CLOSE });

  const {
    choicePriceDifference,
    pricing: { currency },
  } = selected;

  const totalPackagePrice = checkoutState?.cart?.summary?.total + choicePriceDifference;

  return (
    <Sticky>
      <Divider />
      <Bar direction="row">
        <Stack>
          <Typography variant="priceLg">{formatPrice(totalPackagePrice, currency, 'never')}</Typography>
          <Typography variant="captionSm" sx={(theme) => ({ color: theme.token.color.neutral.text.weak[' '].value })}>
            Total Package Price
          </Typography>
        </Stack>
        <PrimaryButton onClick={pendingChange ? onSelect : onClose}>
          {pendingChange ? 'Confirm change' : 'Done'}
        </PrimaryButton>
      </Bar>
    </Sticky>
  );
};

export default RoomOptionsFooter;
