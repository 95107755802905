import React from 'react';

import type { StaysCardProps } from './StaysCard.types';
import { Box, SvgIcon, Typography } from '@mui/material';
import { Flight } from '@mui/icons-material';
import { StayContainer, StayContent, StayHeroImage } from './StaysCard.styles';
import { isAllTruthy } from '@src/utils';
import { Divider, SkeletonLoader } from '@fctg-ds/core';
import StarRating from '@components/ds-candidates/StarRating';
import SelectedStayWrapper from '@shared/SelectedStayWrapper';

const FlightIcon = () => {
  return <SvgIcon component={Flight} sx={{ transform: 'rotate(45deg)', fontSize: '14px' }} data-testid="flight-icon" />;
};

/**
 * @deprecated
 */
const StaysCard: React.FC<StaysCardProps> = ({
  onAction,
  travelInfo,
  content,
  availability,
  availabilityLoading,
  isAvailable,
  stayOnly = true,
  hasDates = true,
  selected,
}) => {
  const price = availability?.summary.pricing.total.toLocaleString('au', {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: availability?.summary?.pricing.currency,
  });

  if (!content) availabilityLoading = true;

  return (
    <StayContainer
      className="stays-container"
      onClick={() => (!availabilityLoading ? onAction?.(availability?.staySearchOfferId) : undefined)}
    >
      <SelectedStayWrapper selected={selected}>
        <Box>
          {content?.images.length > 0 ? (
            <StayHeroImage src={content?.images[0].url} alt={content?.images[0].description} selected={selected} />
          ) : (
            <Box style={{ width: '200px', height: '130px' }}>&nbsp;</Box>
          )}
        </Box>
        <StayContent>
          <Typography variant="h6">{content?.hotelName}</Typography>
        </StayContent>
        <StayContent isSupportingText>
          <StarRating id="stay-details-rating" value={content?.rating} />
          <Divider style={{ margin: 13 }} variant="dot" />
          <Typography variant="h6">{content?.address.city}</Typography>
        </StayContent>
        {hasDates && (
          <StayContent isSupportingText isTravellerDetails>
            {!stayOnly && (
              <>
                <FlightIcon /> + &nbsp;
              </>
            )}
            <Typography variant="caption">
              stay {travelInfo.nights || 'x'} nights, {travelInfo.adults || 2} adults
            </Typography>
          </StayContent>
        )}
        <StayContent>
          {!hasDates && <Typography variant="subtitle2">Add dates for prices</Typography>}
          {availabilityLoading && hasDates && <SkeletonLoader variant="rectangular" height={30} width={'60%'} />}
          {!isAllTruthy(isAvailable, availability) && hasDates && !availabilityLoading && (
            <Typography color={'red'}>No Availability</Typography>
          )}
          {isAllTruthy(!availabilityLoading, isAvailable, availability, hasDates) && (
            <Typography variant="subtitle2">{price}</Typography>
          )}
        </StayContent>
        {!hasDates && (
          <StayContent isSupportingText isTravellerDetails>
            {!stayOnly && (
              <>
                <FlightIcon /> + &nbsp;
              </>
            )}
            <Typography variant="caption">stay rates available</Typography>
          </StayContent>
        )}
      </SelectedStayWrapper>
    </StayContainer>
  );
};

export default StaysCard;
