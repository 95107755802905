import { ProductRoomRate } from '@src/types/StayDetailsResponse';
import dayjs from 'dayjs';
import { ProductRoomAttributeKey } from '../../StaysRoomOptions.types';

const getFullRefundInfo = (stringDate: string) =>
  stringDate ? `Fully refundable by ${dayjs(stringDate).format('DD MMM')}` : 'Fully refundable';

export const refundDate = (dt: string): string => dayjs(dt).format('ddd D MMM');

export const getLabel = (val: ProductRoomRate, key: ProductRoomAttributeKey): string => {
  switch (key) {
    case ProductRoomAttributeKey.nonRefundable:
      return val.additionalInfo.nonRefundable
        ? 'Non-refundable'
        : getFullRefundInfo(val.additionalInfo.refundableDescription);

    case ProductRoomAttributeKey.mealPlan:
      return val.mealPlan;

    default:
      throw Error(`Unknown label: ${key}`);
  }
};

export const formatPrice = (
  price: number,
  currency: string,
  signDisplay: 'exceptZero' | 'auto' | 'never' | 'always' = 'exceptZero',
) => {
  if (typeof price !== 'number' || Number.isNaN(price)) {
    return '';
  }

  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency || 'AUD',
    currencyDisplay: 'narrowSymbol',
    signDisplay: signDisplay,
  }).format(price);
};
