import { ProductRoomAttributeKey } from '@stays/StayRoomOptions/StaysRoomOptions.types';
import { ProductRoomRate } from '@src/types/StayDetailsResponse';
import dayjs from 'dayjs';

const attributeKey: ProductRoomAttributeKey[] = [
  ProductRoomAttributeKey.nonRefundable,
  ProductRoomAttributeKey.mealPlan,
];

export interface RoomRateWithSelected {
  selected?: ProductRoomRate;
  rates: ProductRoomRate[];
}

export const getCheapestRate = (rates: ProductRoomRate[]): ProductRoomRate =>
  rates.reduce((acc, curr) => (acc.pricing.total > curr.pricing.total ? curr : acc), rates[0]);

const getValidSelections = (
  attributeKey: ProductRoomAttributeKey,
  roomRates: ProductRoomRate[],
  selected: ProductRoomRate,
) =>
  roomRates.filter((rate) => {
    return ['nonRefundable', 'mealPlan'].every((key) => {
      if (rate.rateId === selected.rateId || key === attributeKey) return true;

      if (key === 'nonRefundable') return selected.additionalInfo.nonRefundable === rate.additionalInfo.nonRefundable;
      else return selected[key] === rate[key];
    });
  });

export const getFullRefundInfo = (stringDate: string) => {
  const genericResponse = 'Fully refundable';
  if (!stringDate) return genericResponse;

  const date = dayjs(stringDate).format('DD MMM');
  if (date === 'Invalid Date') return genericResponse;

  return `Fully refundable by ${dayjs(stringDate).format('DD MMM')}`;
};

export const getLabel = (val: ProductRoomRate, key: ProductRoomAttributeKey): string => {
  switch (key) {
    case 'nonRefundable':
      return val.additionalInfo.nonRefundable
        ? 'Non-refundable'
        : getFullRefundInfo(val.additionalInfo.refundableDescription);

    case 'mealPlan':
      return val.mealPlan;

    default:
      throw Error(`Unknown label: ${key}`);
  }
};

const getCheapestValidSelections = (
  attributeKey: ProductRoomAttributeKey,
  roomRates: ProductRoomRate[],
  selected: ProductRoomRate,
): ProductRoomRate[] => {
  const validSelections = getValidSelections(attributeKey, roomRates, selected);

  const uniqueLabels = new Set<string>();
  // return only the cheapest of all valid selections
  return validSelections.reduce((t, c) => {
    const label = getLabel(c, attributeKey);

    if (!uniqueLabels.has(label)) {
      t.push(
        validSelections.reduce((acc, curr) => {
          if (label !== getLabel(curr, attributeKey)) return acc;
          return acc.pricing.total > curr.pricing.total || getLabel(acc, attributeKey) !== label ? curr : acc;
        }, selected),
      );
      uniqueLabels.add(label);
    }

    return t;
  }, [] as ProductRoomRate[]);
};

export const getRatesToDisplayByAttribute = (
  rates: ProductRoomRate[],
  selected: ProductRoomRate,
): Record<ProductRoomAttributeKey, ProductRoomRate[]> => {
  const value = attributeKey.map((key) => getCheapestValidSelections(key, rates, selected));
  return value.reduce((acc, curr, index) => ({ ...acc, [attributeKey[index]]: curr }), {
    nonRefundable: [],
    mealPlan: [],
  });
};
