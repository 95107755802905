import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

type TOfferingProvider = {
  offerId?: string;
  offering?: any;
};

export type TOfferingState = {
  offering: any;
};

export type TOfferingAPI = {
  fetchOffering: (offerId: string) => any;
};

/**
 * @deprecated
 */
const OfferingProvider: React.FC<TOfferingProvider> = ({ children, offerId }) => {
  // this will need to come from frank
  const [offering, setOffering] = useState(undefined);

  useEffect(() => {
    if (offering) {
      setOffering(offering);
    }
  }, [offering]);

  const fetchOffering = (offerId: string) => {
    // hit up frank to get the offering
    setOffering('');
    return '';
  };

  const api = useMemo<TOfferingAPI>(() => {
    return {
      fetchOffering,
    };
  }, []);

  const state = useMemo<TOfferingState>(() => {
    return {
      offering,
    };
  }, []);

  return (
    <OfferingStateAPI.Provider value={api}>
      <OfferingStateContext.Provider value={state}>{children}</OfferingStateContext.Provider>
    </OfferingStateAPI.Provider>
  );
};

const OfferingStateAPI = createContext<TOfferingAPI>(undefined);
const OfferingStateContext = createContext<TOfferingState>(undefined);

export const useOfferingContext = () => useContext(OfferingStateContext);
export const useOfferingAPI = () => useContext(OfferingStateAPI);

export default OfferingProvider;
