import React from 'react';
import { styled } from '@fctg-ds/theme';

const DollarContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '9px',
  marginRight: '2px',
}));

export const Dollar = () => <DollarContainer>$</DollarContainer>;
