import React, { useEffect, useRef, useMemo } from 'react';
import { Chip, Carousel } from '@fctg-ds/core';
import { Flex } from '@src/components/common/Flex';
import { TFilters } from '@src/context/ShoppingContext';

interface IProps {
  hasContent: boolean;
  setAmenities: (amenity: string) => void;
  getWeightedAmenities: () => string[];
  filters: TFilters;
}

const EMPTY_AMENITIES_DATA: string[] = ['test'];

const AmenetiesFilter: React.FC<IProps> = ({ setAmenities, filters, hasContent, getWeightedAmenities }) => {
  const weightedAmenities: string[] = useMemo(
    () => getWeightedAmenities() ?? EMPTY_AMENITIES_DATA,
    [getWeightedAmenities, hasContent],
  );

  const handleSelect = (label) => {
    setAmenities(label);
  };

  const hasAmenities = weightedAmenities.length;

  const carouselRef = useRef(null);

  useEffect(() => {
    if (carouselRef.current) {
      const splide = carouselRef.current?.splide;
      splide.destroy();
      splide.options = { autoWidth: true };
      splide.mount();
    }
  }, [carouselRef, weightedAmenities]);

  return hasAmenities ? (
    <Flex style={{ width: '100px' }}>
      <Carousel
        data-testid={'amenities-filter-carousel'}
        hideArrows
        style={{ padding: '4px', overflowX: 'auto' }}
        spacing={2}
        ref={carouselRef}
        hidePagination
        peekFade
      >
        {weightedAmenities.map((amenity, i) => (
          <Chip
            data-testid={'amenities-filter-chip'}
            key={i}
            isActive={filters.amenities.includes(amenity)}
            label={amenity}
            onClick={() => handleSelect(amenity)}
          />
        ))}
      </Carousel>
    </Flex>
  ) : null;
};

export default AmenetiesFilter;
