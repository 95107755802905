// Context to handle the content fetch / state

import React, { createContext, useContext, useEffect, useMemo } from 'react';
import useSDK from '@src/hooks/useSDK';
import { useSDKCache } from '@src/hooks/useSDKCache';
import { StayContent } from '@src/types/StayContent.types';
import { isAllTruthy } from '@src/utils';
import { useShoppingContext } from './ShoppingContext';

// TYPES
export type TContentState = {
  isLoading: boolean;
  loaded: boolean;
  stayContent: StayContent;
};

export type TContentProvider = {
  stayId?: string;
  stayContent?: StayContent;
};

// PROVIDER
const ContentProvider: React.FC<TContentProvider> = ({ children, stayId, stayContent }) => {
  const [stapiSdk, stapiState, stapiReady] = useSDK('stapi');
  const shoppingContext = useShoppingContext();

  if (shoppingContext && shoppingContext.product && !stayId) {
    stayId = shoppingContext.product.stayId;
  }

  const [cachedStayContent, isLoading] = shoppingContext?.usingExpedia
    ? useSDKCache<StayContent>(
        stapiSdk.getContentById,
        { seoUrl: stayContent?.slug },
        isAllTruthy(stapiReady, stayContent?.slug !== undefined, !stayContent),
      )
    : useSDKCache<StayContent>(
        stapiSdk.getContentById,
        { hotelId: stayId },
        isAllTruthy(stapiReady, stayId !== undefined, !stayContent),
      );

  const _stayContent = stayContent ?? cachedStayContent;

  const state = useMemo<TContentState>(() => {
    return {
      stayContent: _stayContent,
      loaded: !!_stayContent,
      isLoading: isLoading,
    };
  }, [_stayContent, cachedStayContent, isLoading]);

  return <ContentStateContext.Provider value={state}>{children}</ContentStateContext.Provider>;
};

// CONTEXT
const ContentStateContext = createContext<TContentState>(undefined);

// HOOKS / EXPORTS
export const useContentContext = () => useContext(ContentStateContext);
export default ContentProvider;
