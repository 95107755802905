import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface StickyBoxProps {
  offsetY?: number;
  children: React.ReactNode;
}

/**
 * @deprecated
 */
const StickyBox: React.FC<StickyBoxProps> = ({ offsetY, children }) => {
  let stickyProp = null;
  if (typeof CSS !== 'undefined' && CSS.supports) {
    if (CSS.supports('position', 'sticky')) stickyProp = 'sticky';
    else if (CSS.supports('position', '-webkit-sticky')) stickyProp = '-webkit-sticky';
  }

  const registerNode = (node: HTMLElement, offsetY?: number) => {
    const offsetTop = offsetY ?? 20;
    let latestScrollY = window.scrollY;

    const unsubs = [];
    let mode: 'relative' | 'stickyTop' | 'stickyBottom';
    let offset: number;
    let nodeHeight: number;
    let naturalTop: number;
    let parentHeight: number;

    const getCurrentOffset = () => {
      switch (mode) {
        case 'relative':
          return offset;

        case 'stickyTop':
          return Math.max(0, latestScrollY - naturalTop + offsetTop);

        case 'stickyBottom':
          return Math.max(0, latestScrollY + window.innerHeight - (naturalTop + nodeHeight));
      }
    };

    const changeToStickyBottomIfBoxTooLow = () => {
      const screenPosBottom = window.scrollY + window.innerHeight;
      const nodePosBot = naturalTop + nodeHeight + offset;
      if (screenPosBottom >= nodePosBot) {
        changeMode('stickyBottom');
      }
    };

    const changeMode = (newMode: typeof mode) => {
      mode = newMode;

      switch (newMode) {
        case 'relative':
          node.style.position = 'relative';
          node.style.top = `${offset}px`;
          break;

        case 'stickyBottom':
        case 'stickyTop':
          node.style.position = stickyProp;
          node.style.top = newMode === 'stickyBottom' ? `${window.innerHeight - nodeHeight}px` : `${offsetTop}px`;
          break;
      }

      offset = getCurrentOffset();
    };

    const initial = () => {
      if (mode !== 'stickyTop') changeMode('stickyTop');
    };

    const addListener = (event: string, handler: EventListenerOrEventListenerObject, options?: boolean) => {
      window.addEventListener(event, handler, options);
      unsubs.push(() => window.removeEventListener(event, handler));
    };

    const handleScroll = () => {
      if (window.scrollY === latestScrollY) return;
      if (nodeHeight + offsetTop <= window.innerHeight) {
        initial();
        latestScrollY = window.scrollY;
        return;
      }
      const scrollDelta = window.scrollY - latestScrollY;
      offset = getCurrentOffset();
      const screenPosBottom = window.scrollY + window.innerHeight + offsetTop;
      if (scrollDelta > 0) {
        // scroll down
        if (mode === 'stickyTop') {
          if (window.scrollY + offsetTop > naturalTop) {
            const nodePosBot = naturalTop + nodeHeight + offset;

            changeMode(screenPosBottom <= nodePosBot ? 'relative' : 'stickyBottom');
          }
        } else if (mode === 'relative') changeToStickyBottomIfBoxTooLow();
      } else {
        // scroll up
        const nodePosTop = naturalTop + offset;
        if (mode === 'stickyBottom') {
          if (screenPosBottom < naturalTop + parentHeight)
            changeMode(window.scrollY >= nodePosTop ? 'relative' : 'stickyTop');
        } else if (mode === 'relative') {
          if (window.scrollY < nodePosTop) changeMode('stickyTop');
        }
      }

      latestScrollY = window.scrollY;
    };

    const handleNodeResize = ({ initial: initialArg } = { initial: undefined }) => {
      const prevHeight = nodeHeight;
      nodeHeight = node.getBoundingClientRect().height;
      if (!initialArg && prevHeight !== nodeHeight) {
        if (nodeHeight + offsetTop <= window.innerHeight) {
          changeMode('stickyTop');
          return;
        }

        const lowestPossiblePos = parentHeight - nodeHeight;
        const nextOffset = Math.min(lowestPossiblePos, getCurrentOffset());
        offset = Math.max(0, nextOffset);
        if (mode !== 'stickyBottom') changeMode('relative');
      }
    };

    const addResizeObserver = (n, handler) => {
      const ro = new ResizeObserver(handler);
      ro.observe(n);
      unsubs.push(() => ro.disconnect());
    };

    addListener('scroll', handleScroll, true);
    addListener('wheel', handleScroll, true);
    addListener('resize', handleScroll);
    handleScroll();

    addResizeObserver(node, handleNodeResize);
    handleNodeResize({ initial: true });

    initial();

    return () => unsubs.forEach((fn) => fn());
  };

  const useStickyBox = ({ offsetY }) => {
    const [node, setNode] = useState(null);
    useEffect(() => {
      if (!node) return;
      return registerNode(node, offsetY);
    }, [node]);
    return setNode;
  };

  const ref = useStickyBox({ offsetY });
  return <Box ref={ref}>{children}</Box>;
};

export default StickyBox;
