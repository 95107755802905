import React from 'react';
import { Bar } from '@fctg-ds/core';
import { Stack, Typography } from '@mui/material';
import { PriceSummary } from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/components';
import { ProductStay } from '@src/types/StayDetailsResponse';

interface NotesFootersProps {
  product: ProductStay;
  action: () => void;
}
const NotesFooter = ({ product, action }: NotesFootersProps) => {
  const hasNotes = product?.notes?.length > 0;
  return (
    <Bar actionText="View notes" action={action} buttonProps={{ disabled: !hasNotes }}>
      <Stack alignItems="start" data-testid="view-notes-wrapper">
        <PriceSummary pricing={product?.summary?.pricing} priceDifference={undefined} isRetailStay />
        <Typography variant="captionMd">
          {`${product?.travellerIds?.length} ${product?.travellerIds?.length > 1 ? 'travellers' : 'traveller'}`}
        </Typography>
      </Stack>
    </Bar>
  );
};

export default NotesFooter;
