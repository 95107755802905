import React from 'react';
import { GreenTick } from '@common/GreenTick';
import { ProductRoomRate, ProductRoomSelection } from '@src/types/StayDetailsResponse';
import TruncatedTypography from '@src/components/ds-candidates/TruncatedTypography';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
interface InclusionsProps {
  rates: ProductRoomRate[];
  maxLength?: number;
  initialSelection?: ProductRoomSelection;
  displayOnly?: boolean;
}

/**
 * @deprecated
 */
export const Inclusions: React.FC<InclusionsProps> = ({
  rates,
  maxLength = 35,
  initialSelection,
  displayOnly = false,
}) => {
  const theme = useTheme();
  const str = [];

  if (rates.every((r, i, arr) => arr[0].mealPlan === r.mealPlan)) {
    if (rates[0].mealPlan !== 'NA') str.unshift(rates[0].mealPlan);
  }

  if (displayOnly && initialSelection && str.length === 0) {
    const selectedRate = rates.find((rate) => rate.rateId === initialSelection.rateId);
    if (selectedRate?.mealPlan) str.push(selectedRate?.mealPlan);
  }

  if (str.length < 1) return <React.Fragment />;

  return (
    <Stack direction="row" data-testid="roomInclusions">
      <GreenTick />
      <TruncatedTypography
        variant="body2"
        color={theme.token.color.neutral.text[' '].value}
        text={str.join(', ')}
        initialWidth={300}
      />
    </Stack>
  );
};
