import React from 'react';
import { TPriceRange } from '@src/context/ShoppingContext';
import { Slider } from '@mui/material';
import { styled } from '@fctg-ds/theme';
import { formatPrice } from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/helpers/utils';
interface IProps {
  range: TPriceRange;
  onChange: (range: TPriceRange) => void;
  min: number;
  max: number;
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  height: '1px',
  color: theme.token.color.neutral.border[' '].value,
  padding: '0px !important',
  '& .MuiSlider-thumb': {
    height: '21px',
    width: '21px',
    color: theme.token.color.neutral.background[' '].value,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: theme.token.color.neutral.border[' '].value,
  },
}));

/**
 * @deprecated
 */
const PriceSlider: React.FC<IProps> = ({ range, onChange, min, max }) => {
  const handleChange = (event: Event, newValue: number[]) => {
    onChange({ min: newValue[0], max: newValue[1] });
  };

  const getValueText = (value: number) => {
    return formatPrice(value, 'AUD', 'always');
  };

  return (
    <StyledSlider
      min={min}
      max={max}
      value={[range.min, range.max]}
      onChange={handleChange}
      getAriaValueText={getValueText}
    />
  );
};

export default PriceSlider;
