import React, { useMemo } from 'react';
import Typography from '@mui/material/Typography';
import StarRating from '@components/ds-candidates/StarRating';
import CheckInOut from '@components/ds-candidates/CheckInOut';
import Box from '@mui/material/Box';
import { Root } from './StaysDetailsInfo.styles';
import TextExpand from '@components/ds-candidates/TextExpand';
import { Stack } from '@mui/material';
import { Divider, useBreakpointMapper } from '@fctg-ds/core';
import IconAndLabel from '@src/components/ds-candidates/IconAndLabel';
import NightsIcon from '@mui/icons-material/DarkModeOutlined';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useContentContext } from '@context/ContentContext';
import { useShoppingContext } from '@context/ShoppingContext';
import { StayDetailsInfoProps } from './StaysDetailsInfo.types';
import FmdGoodIcon from '@mui/icons-material/FmdGoodOutlined';

const StayDetailsInfo: React.FC<StayDetailsInfoProps> = ({ fullWidth }) => {
  const { stayContent } = useContentContext();
  const { product } = useShoppingContext();
  const [renderFullDetails] = useBreakpointMapper({ xs: false, sm: false, md: true, lg: true, xl: true });

  const nights = useMemo(
    () => (product?.trip ? dayjs(product?.trip.endDate).diff(product?.trip.startDate, 'days') : undefined),
    [product],
  );
  const formattedStartDate = product?.trip ? dayjs(product?.trip.startDate).format('DD MMM YYYY') : undefined;
  const formattedEndDate = product?.trip ? dayjs(product?.trip.endDate).format('DD MMM YYYY') : undefined;

  return (
    <Root
      data-testid="stay-details-info"
      gap={(theme) => theme.spacing(2)}
      fullWidth={fullWidth}
      marginTop={(theme) => theme.spacing(6)}
    >
      <Box>
        <Typography component="h4" variant="bodyMdStrong" data-testid="stay-details-header">
          {stayContent.hotelName}
        </Typography>
      </Box>
      {renderFullDetails ? (
        <>
          <Stack direction="row" alignItems="center">
            <StarRating id="stay-details-rating" value={stayContent.rating} />
            {nights && (
              <>
                <Divider style={{ margin: 7 }} variant="dot" />
                <IconAndLabel Icon={NightsIcon} label={`${nights} nights`} />
              </>
            )}

            {formattedStartDate && formattedEndDate && (
              <>
                <Divider style={{ margin: 7 }} variant="dot" />
                <IconAndLabel Icon={DateRangeIcon} label={`Stay ${formattedStartDate} - ${formattedEndDate}`} />
              </>
            )}
          </Stack>
          <Stack direction="row" alignItems="center">
            {stayContent?.address?.city && stayContent?.address?.state && (
              <>
                <IconAndLabel Icon={FmdGoodIcon} label={`${stayContent.address.city}, ${stayContent.address.state}`} />
                <Divider style={{ margin: 7 }} variant="dot" />
              </>
            )}
            <CheckInOut
              id="stay-details-timings"
              checkInTime={stayContent.checkInTime}
              checkOutTime={stayContent.checkOutTime}
            />
          </Stack>
        </>
      ) : (
        <>
          <Stack direction="row" alignItems="center">
            <StarRating id="stay-details-rating" value={stayContent.rating} />
            {nights && (
              <>
                <Divider style={{ margin: 7 }} variant="dot" />
                <IconAndLabel Icon={NightsIcon} label={`${nights} nights`} />
              </>
            )}
          </Stack>
          <CheckInOut
            id="stay-details-timings"
            checkInTime={stayContent.checkInTime}
            checkOutTime={stayContent.checkOutTime}
          />

          {formattedStartDate && formattedEndDate && (
            <IconAndLabel Icon={DateRangeIcon} label={`Stay ${formattedStartDate} - ${formattedEndDate}`} />
          )}
        </>
      )}
      <Box mt={(theme) => theme.spacing(6)}>
        <TextExpand
          id="description"
          maxRows={3}
          content={stayContent.descriptions.map((d) => d.detail)}
          variant="bodySm"
        />
      </Box>
    </Root>
  );
};

export default StayDetailsInfo;
