/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import '@fctg-dc/fe-eventbus';
import { Divider } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import IconAndLabel from '@components/ds-candidates/IconAndLabel';
import { Typography, useMediaQuery, Stack } from '@mui/material';
import HotelIcon from '@mui/icons-material/Hotel';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { GreenTick } from '@common/GreenTick';
import { Refundable } from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/components';
import { IconAndLabelProps } from '@src/components/ds-candidates/IconAndLabel/IconAndLabel';
import { ProductRoomChoice, ProductRoomRate } from '@src/types/StayDetailsResponse';

interface StaysSummaryInclusionsProps {
  stayRoom: ProductRoomChoice;
  selectedRate: ProductRoomRate;
}

export const StaysSummaryInclusions: React.FC<StaysSummaryInclusionsProps> = ({
  stayRoom,
  selectedRate,
}: StaysSummaryInclusionsProps) => {
  const screenXl = useMediaQuery(fc.breakpoints.up('lg'));

  const values: IconAndLabelProps[] = [
    { Icon: HotelIcon, label: stayRoom?.bedding === 'unknown' ? undefined : stayRoom?.bedding },
    { Icon: AspectRatioIcon, label: stayRoom?.inclusions?.roomSize },
  ];

  // We don't want to display meal plans such as "Room Only"
  const displayMealPlan = (mealPlan: string) => {
    const mealPlanStr = mealPlan.toLowerCase();
    const keywords = ['breakfast', 'continental', 'all inclusive', 'full board', 'half board'];
    return keywords.some((keyword) => mealPlanStr.includes(keyword));
  };

  const mealPlanInfo = { Icon: GreenTick, label: selectedRate.mealPlan.slice(0, -3).trim() };
  if (displayMealPlan(selectedRate.mealPlan) && screenXl) values.push(mealPlanInfo);

  const filteredValues = values.filter((value) => value.label !== undefined);

  return (
    <Stack direction="column" gap={(theme) => theme.spacing(1)} data-testid="StaysSummaryInclusions">
      <Typography variant="bodySmStrong">{stayRoom.typeName}</Typography>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        {filteredValues.map((roomInfoValue, index) => (
          <React.Fragment key={`details-modal-info-${index}`}>
            {index !== 0 && <Divider variant="dot" style={{ margin: 7 }} />}
            <IconAndLabel {...roomInfoValue} />
          </React.Fragment>
        ))}
      </Stack>
      {!screenXl && displayMealPlan(selectedRate.mealPlan) && <IconAndLabel {...mealPlanInfo} />}
      <Refundable room={stayRoom} />
    </Stack>
  );
};
