import { Box, Typography } from '@mui/material';
import React from 'react';

interface IRoomAmenities {
  title: string;
  values: string[];
}

export interface RoomAmenitiesListProps {
  amenities?: IRoomAmenities[];
}

/**
 * @deprecated
 */
const RoomAmenitiesList: React.FC<RoomAmenitiesListProps> = ({ amenities = [] }) => {
  return (
    <>
      <Typography
        component="h4"
        variant="bodyMdStrong"
        sx={{ margin: '1rem 0' }}
        data-testid="room-attributes-list-title"
      >
        Room amenities
      </Typography>
      {amenities.map((amenity, index) => (
        <React.Fragment key={`details-modal-amenities-${index}`}>
          <Typography component="h5" variant="bodySmStrong" data-testid="room-attributes-list-subtitle">
            {amenity.title}
          </Typography>
          <Box component="ul">
            {amenity.values.map((value, valIndex) => (
              <Box
                component="li"
                key={`details-modal-amenities-${index}-item-${valIndex}`}
                data-testid="room-attributes-list-item"
              >
                {value}
              </Box>
            ))}
          </Box>
        </React.Fragment>
      ))}
    </>
  );
};

export default RoomAmenitiesList;
