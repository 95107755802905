import React from 'react';
import { Title } from './Title';
import { Stack, Typography } from '@mui/material';
interface CancellationProps {
  title: string;
  description: string;
}
export const Cancellation = (props: CancellationProps) => {
  const { title, description } = props;

  return (
    <Stack spacing={1} data-testid="stays-tc-desc-cancellation">
      <Title title={title} />
      <Typography variant={'bodySm'}>{description}</Typography>
    </Stack>
  );
};
