import { makeStyles } from 'tss-react/mui';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/system';
import Link from '@mui/material/Link';
export interface StayAmenitiesStyleProps {
  fullWidth?: boolean;
}

const useStyles = makeStyles<StayAmenitiesStyleProps>()((theme, { fullWidth }) => ({
  root: {
    maxWidth: fullWidth ? '100%' : theme.breakpoints.values.lg,
  },
}));

export const StayAmenitiesHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing(6),
}));

export const StayAmenitiesSubText = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecorationColor: 'inherit',
  marginTop: theme.spacing(1),
}));

export default useStyles;
