import React from 'react';
import { StayContent } from '@src/types/StayContent.types';
import { StaySearchProduct } from '@src/types/StaySearchProduct';
import StaysCard from './StaysCard';
import { ActionEvents } from '@src/types';
import { RootBox } from './StaysResultsCardList.styles';
import { StayResultsCardListProps } from './StayResultsCardList.types';

type StayContentAvailability = StayContent & { isAvailable: boolean };

/**
 * @deprecated
 */
const StaysResultsCardList: React.FC<StayResultsCardListProps> = ({
  onAction,
  selectedStayId,
  startDate,
  nights,
  travellers,
  stayContent,
  availability,
  isContentLoading,
  isAvailabilityLoading,
  preselectedFlight,
}) => {
  if (isContentLoading) return <></>;

  return (
    <RootBox>
      {stayContent &&
        stayContent.length > 0 &&
        stayContent.map((content: StayContentAvailability) => {
          // frank provides 'Internal server error' under [message] and travelbox connection errors under [errors]
          return (
            <StaysCard
              onAction={(offerId?: string) => {
                onAction({
                  type: ActionEvents.RESULTS_CARD_SELECTION,
                  data: {
                    hotelId: content.hotelId,
                    startDate,
                    adults: travellers.adult,
                    nights,
                    offerId,
                    slug: content.slug,
                    fareKey: preselectedFlight?.frankFlightObject?.fare?.fareKey ?? '',
                  },
                });
              }}
              travelInfo={{
                adults: travellers.adult ?? 2,
                startDate: startDate,
                nights: nights,
              }}
              key={content.hotelId}
              content={content}
              selected={selectedStayId === content.hotelId}
              availability={availability?.find((ad: StaySearchProduct) => ad.stayId === content.hotelId)}
              isAvailable={content.isAvailable}
              availabilityLoading={isAvailabilityLoading}
            />
          );
        })}
    </RootBox>
  );
};

export default StaysResultsCardList;
