import React from 'react';
import { TChartData, TPriceRange } from '@src/context/ShoppingContext';
import ChartBar from './ChartBar';
import { styled } from '@fctg-ds/theme';

interface IProps {
  chartData: TChartData;
  range: TPriceRange;
}

/**
 * @deprecated
 */
const PriceChart: React.FC<IProps> = ({ chartData, range }) => {
  const maxCount = Math.max.apply(
    null,
    chartData.map((d) => d.count),
  );

  const isInRangeBounds = (label: number): boolean => {
    const { max, min } = range;
    return label >= min && label <= max;
  };

  return (
    <ChartContainer data-testid={'chart-bar-container'}>
      {chartData.map((data) => (
        <ChartBar key={data.label} value={data.count} maxCount={maxCount} inRange={isInRangeBounds(data.label)} />
      ))}
    </ChartContainer>
  );
};

export default PriceChart;

const ChartContainer = styled('div')(() => ({
  alignItems: 'flex-end',
  height: '60px',
  display: 'flex',
  marginTop: '10px',
}));
