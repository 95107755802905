import { Divider } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';
import { Box } from '@mui/material';
import IconAndLabel from '@src/components/ds-candidates/IconAndLabel';
import React from 'react';
import { Hotel as BedsIcon, AspectRatio as Aspect, SmokeFree, BlindsClosed as Blinds } from '@mui/icons-material';
import { ProductRoomChoice } from '@src/types/StayDetailsResponse';

interface RoomDetailsInfoProps {
  stay: ProductRoomChoice;
  isMobile: boolean;
}

/**
 * @deprecated
 */
const RoomDetailsInfo: React.FC<RoomDetailsInfoProps> = ({ stay, isMobile }) => {
  const values = [
    { icon: BedsIcon, label: stay.inclusions?.bedding },
    { icon: Aspect, label: stay.inclusions?.roomSize },
    { icon: SmokeFree, label: stay.inclusions?.nonSmoking },
    { icon: Blinds, label: stay.inclusions?.view },
  ];

  return (
    <FlexBox>
      {values.map((roomInfoValue, index) => (
        <React.Fragment key={`details-modal-info-${index}`}>
          {(!isMobile || index === 0) && roomInfoValue.label && (
            <>
              {index !== 0 && <Divider variant="dot" style={{ margin: 7 }} />}
              <IconAndLabel Icon={roomInfoValue.icon} label={roomInfoValue.label} />
            </>
          )}
        </React.Fragment>
      ))}
    </FlexBox>
  );
};

export default RoomDetailsInfo;

const FlexBox = styled(Box)({
  display: 'inline-flex',
  flexWrap: 'nowrap',
  overflow: 'clip',
});
