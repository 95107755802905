import { Flex } from '@src/components/common/Flex';
import React from 'react';

/**
 * @deprecated
 */
const SortAndFilterBar: React.FC = ({ children }) => {
  return <Flex>{React.Children.map(children, (child) => child)}</Flex>;
};

export default SortAndFilterBar;
