import React, { useMemo, useState } from 'react';
import { Divider, useBreakpointMapper } from '@fctg-ds/core';
import { Stack } from '@mui/material';

import { SortAndFilterBar, StarFilter, PriceFilter, AmenitiesFilter } from '@src/components/stays/SortAndFilters';
import { SearchFormBanner } from './ResultsPage.styles';
import StaysResultsCardList from '@stays/StaysResultsCardList';
import FlightInfo from '@wrappers/FlightInfo';
import DynamicMap from '@wrappers/DynamicMap';
import ResultsSearch from '@wrappers/ResultsSearch';
import Sort from '@src/components/stays/SortAndFilters/Sort';
import { ActionEvents, Coordinates, OnActionType } from '@src/types';
import { StayContent } from '@fctg-dc/fe-eventbus/dist/events/stays-product-mofo/types';
import { TFilters, TPriceRange, TSort, TPriceStats, TChartData } from '@src/context/ShoppingContext';
import { ITraveller, TFlowAPI } from '@src/context/FlowContext';
import { StaySearchProduct } from '@src/types/StaySearchProduct';
import { Flex } from '@src/components/common';
import { TFlightData } from '@src/types/flights.types';

export interface ResultsPageProps extends TFlowAPI {
  onAction: OnActionType;
  // Dynamic Map
  stayContent: StayContent[];
  isContentLoading: boolean;
  geoId: number;
  travellers: ITraveller;
  availability: StaySearchProduct[];
  // FlightInfo
  preselectedFlight: TFlightData;
  departureAirport: string;
  destination: string;
  startDate: string;
  nights: number;
  coords: string;
  isFetchingPreselectedFlight: boolean;
  selectedStayId: string;
  isAvailabilityLoading: boolean;
  // Filters
  filters: TFilters;
  // StarFilter
  getStarFilterResultCount: (selected: boolean[]) => number;
  setFilterStars: (selected: boolean[]) => void;
  // PriceFilter
  getPriceFilterResultCount: (range: TPriceRange) => number;
  setFilterPriceRange: (range: TPriceRange) => void;
  getFilterPriceStats: () => TPriceStats;
  getChartData: () => TChartData;
  // AmenitiesFilter
  getWeightedAmenities: () => string[];
  setAmenities: (amenities: string[]) => void;
  // Sort
  sort: TSort;
  setSortType: (sortType: string) => void;
}

/**
 * @deprecated
 */
const ResultsPage: React.FC<ResultsPageProps> = ({
  onAction,
  stayContent,
  coords,
  isContentLoading,
  preselectedFlight,
  departureAirport,
  destination,
  startDate,
  nights,
  isFetchingPreselectedFlight,
  filters,
  getStarFilterResultCount,
  setFilterStars,
  getPriceFilterResultCount,
  setFilterPriceRange,
  getFilterPriceStats,
  getChartData,
  getWeightedAmenities,
  setAmenities,
  sort,
  setSortType,
  setDepartureAirport,
  setStartDate,
  setEndDate,
  setNights,
  setTravellers,
  setCabinClass,
  geoId,
  travellers,
  availability,
  selectedStayId,
  isAvailabilityLoading,
}) => {
  const [bpValues] = useBreakpointMapper({
    xs: { isMobile: true },
    sm: { isMobile: true },
    md: { isMobile: true },
    lg: { isMobile: false },
    xl: { isMobile: false },
  });
  const [emptyFlightBtnClickWhileNoOriginAndDates, setEmptyFlightBtnClickWhileNoOriginAndDates] = useState(false);
  let parsedCoords: Coordinates;
  const _coords = coords?.split(',');
  if (_coords?.length !== 2) {
    parsedCoords = undefined;
  } else {
    parsedCoords = {
      lat: _coords[0],
      lon: _coords[1],
    };
  }

  const onEmptyFlightStateBtnClick: OnActionType = ({ type, data }) => {
    switch (type) {
      case ActionEvents.EMPTY_STATE_FLIGHTS:
        if (!departureAirport && !(startDate && nights)) {
          setEmptyFlightBtnClickWhileNoOriginAndDates(true);
        }
        if (!departureAirport)
          document.getElementById('omni-search-mofo/stay-search-form-fixed/origin-textfield')?.click();
        else if (!destination)
          document.getElementById('omni-search-mofo/stay-search-form-fixed/destination-textfield')?.click();
        else document.getElementById('omni-search-mofo/stay-search-form-fixed/date-textfield')?.click();
        break;

      default:
        break;
    }
  };

  const hasAvailability = useMemo(() => availability && availability.length > 0, [availability]);
  const hasContent = useMemo(() => stayContent && stayContent.length > 0, [stayContent]);

  return (
    <Stack>
      <SearchFormBanner>
        <ResultsSearch
          setDepartureAirport={setDepartureAirport}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setNights={setNights}
          setTravellers={setTravellers}
          travellers={travellers}
          setCabinClass={setCabinClass}
          departureAirport={departureAirport}
          startDate={startDate}
          nights={nights}
          isFetchingPreselectedFlight={isFetchingPreselectedFlight}
          emptyFlightBtnClickWhileNoOriginAndDates={emptyFlightBtnClickWhileNoOriginAndDates}
          emptyFlightBtnCallback={() => setEmptyFlightBtnClickWhileNoOriginAndDates(false)}
        />
      </SearchFormBanner>
      <SortAndFilterBar>
        <Sort sort={sort} setSortType={setSortType} />
        <StarFilter
          defaultLabel="Star filter"
          filters={filters}
          getResultCount={getStarFilterResultCount}
          setStars={setFilterStars}
        />
        <PriceFilter
          hasAvailability={hasAvailability}
          defaultLabel="Price"
          filters={filters}
          getResultCount={getPriceFilterResultCount}
          updateRange={setFilterPriceRange}
          getPriceStats={getFilterPriceStats}
          getChartData={getChartData}
        />
        <Flex style={{ flex: 0, alignItems: 'center', justifyContent: 'center' }}>
          <Divider style={{ margin: '10px' }} variant="dot" />
        </Flex>
        <AmenitiesFilter
          filters={filters}
          hasContent={hasContent}
          getWeightedAmenities={getWeightedAmenities}
          setAmenities={setAmenities}
        />
      </SortAndFilterBar>
      <Divider style={{ marginBottom: '0px' }} />
      <Stack sx={{ marginTop: '10px' }} direction={bpValues.isMobile ? 'column-reverse' : 'row'}>
        <Stack direction="column" margin="0 24px" spacing={4}>
          <FlightInfo
            onAction={onAction}
            preselectedFlight={preselectedFlight}
            departureAirport={departureAirport}
            destination={destination}
            startDate={startDate}
            nights={nights}
            isFetchingPreselectedFlight={isFetchingPreselectedFlight}
            onEmptyFlightStateBtnClick={onEmptyFlightStateBtnClick}
          />
          <StaysResultsCardList
            onAction={onAction}
            isContentLoading={isContentLoading}
            selectedStayId={selectedStayId}
            startDate={startDate}
            nights={nights}
            travellers={travellers}
            stayContent={stayContent}
            availability={availability}
            isAvailabilityLoading={isAvailabilityLoading}
            preselectedFlight={preselectedFlight}
          />
        </Stack>
        {parsedCoords && (
          <DynamicMap
            stayContent={stayContent}
            coords={parsedCoords}
            geoId={geoId}
            dateView={!!startDate && !!nights}
            travellers={travellers}
            availability={availability}
            nights={nights}
            onAction={onAction}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ResultsPage;
