/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import '@fctg-dc/fe-eventbus';
import { Root, StaySummaryStyleProps } from './StaysSummary.styles';
import { Carousel, Divider, IconButton, SkeletonLoader, SecondaryButton, LoadingButton } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import { Box, Typography, Card, CardMedia, useMediaQuery, Stack, Grid } from '@mui/material';
import { ActionEvents, OnActionType } from '@src/types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { StayContent } from '@src/types/StayContent.types';
import { ProductRoomRatePricing } from '@src/types/StayDetailsResponse';
import { ProductStay, ProductStayWithAlternative } from '@src/types/StayDetailsResponse';
import { v4 } from 'uuid';
import { StaysSummaryDetails } from './StaysSummaryDetails';
import { StaysSummaryInclusions } from './StaysSummaryInclusions';
import SelectedStayWrapper from '@shared/SelectedStayWrapper';
import { PriceSummary } from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/components';
import { Tag } from '@fctg-ds/core';
import PackagePriceTag from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard/components/PriceTag';
import { formatPrice } from '@components/stays/StayRoomOptions/StaysRoomOptionsCard/helpers/utils';
export interface StaySummaryProps extends StaySummaryStyleProps {
  onAction: OnActionType;
  fullWidth?: boolean;
  features?: Record<string, boolean>;
  stayContent: StayContent;
  product: ProductStayWithAlternative;
  statusTag?: {
    color: React.ComponentProps<typeof Tag>['color'];
    text: string;
  };
  showPricing?: boolean;
  hasMargin?: boolean;
  isRetailAlternative?: boolean;
  isSelectedStay?: boolean;
  onStayDetailsOpen?: (stay: StayContent, product: ProductStay) => void;
  onSelect?: (altOfferingId?: string) => () => void;
  loading?: boolean;
  pricing?: ProductRoomRatePricing;
  isConfirmationPage?: boolean;
}

const StaySummary: React.FC<StaySummaryProps> = ({
  fullWidth,
  onAction,
  stayContent,
  product,
  statusTag,
  features,
  showPricing,
  hasMargin,
  isRetailAlternative,
  onStayDetailsOpen,
  onSelect,
  isSelectedStay,
  loading,
  pricing,
  isConfirmationPage,
}) => {
  const isMbiStays = product?.summary?.pricing !== undefined;
  const screenLg = useMediaQuery(fc.breakpoints.up('md'));
  const roomImageWidth = screenLg ? 100 : 78;
  const roomImageHeight = screenLg ? 100 : 78;
  const PackageTotal = formatPrice(pricing?.price, pricing?.currency, 'never');

  const roomAltsFeatureFlag = features?.roomAlternatives;

  const showLoaders: boolean = !stayContent || !product;

  const selectedRoom = useMemo(() => {
    const selectedChoiceKey = product?.rooms?.selected[0]?.choiceKey;
    return product?.rooms?.choices.find((room) => room.rates.some((rate) => rate.choiceKey === selectedChoiceKey));
  }, [product, stayContent]);

  const selectedRate = selectedRoom?.rates.find((rate) => rate.choiceKey === product?.rooms?.selected[0]?.choiceKey);

  const roomImage = selectedRoom?.images?.[0] ?? stayContent?.images?.[1]?.url;

  const openStayDetails = (data) => {
    if (roomAltsFeatureFlag) {
      const { stayId, stay, product } = data;
      fcEventBus.emit('stays-mofo:stay-details:v1', {
        corelationId: v4(),
        payload: {
          stayId,
          stayContent: stay,
          product,
        },
      });
    } else {
      // This is for backwards compatibility during the transition to singleton.
      onAction({
        type: ActionEvents.STAY_SUMMARY_DETAILS,
        data: {
          productId: product.productId,
          trackLabel: isMbiStays ? 'viewAdditionalItems/fromQuoteBody/stay' : '',
        },
      });
    }
  };
  const openRoomOptions = (data) => {
    const { product, stayContent } = data;
    fcEventBus.emit('stays-mofo:room-options:v1', {
      corelationId: v4(),
      payload: {
        product,
        stayContent: stayContent,
      },
    });
  };

  const displayChangeRoomBtn = !isConfirmationPage && roomAltsFeatureFlag && product?.rooms?.choices?.length > 1;
  const ChangeRoomBtn = () => (
    <SecondaryButton fullWidth onClick={() => openRoomOptions({ product, stayContent })}>
      Change room
    </SecondaryButton>
  );

  return (
    <>
      {!showLoaders ? (
        <Root fullWidth={fullWidth} data-testid="stay-details" hasMargin={hasMargin}>
          <SelectedStayWrapper selected={isSelectedStay} hasBorder={isRetailAlternative}>
            {isRetailAlternative ? (
              <Card
                sx={(theme) => ({
                  borderRadius: `${theme.token.radius.md.value} ${theme.token.radius.md.value} 0 0`,
                })}
              >
                <CardMedia component="img" height="185" image={stayContent?.images[0]?.url} loading="lazy" />
              </Card>
            ) : (
              <Carousel>
                {stayContent.images.map((img, i) => (
                  <Card
                    key={`stay-summary-img-${i}`}
                    sx={(theme) => ({
                      borderRadius: `${theme.token.radius.md.value} ${theme.token.radius.md.value} 0 0`,
                    })}
                  >
                    <CardMedia component="img" height={screenLg ? '240' : '185'} image={img.url} loading="lazy" />
                  </Card>
                ))}
              </Carousel>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              padding={(theme) => theme.spacing(6)}
              onClick={() =>
                !isRetailAlternative
                  ? openStayDetails({ product, stayId: stayContent.hotelId, stay: stayContent })
                  : onStayDetailsOpen(stayContent, product)
              }
              sx={{ cursor: 'pointer' }}
            >
              <StaysSummaryDetails isRetailAlternative={isRetailAlternative} />
              {onAction && (
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
              )}
            </Stack>
            <Box marginX={(theme) => theme.spacing(6)}>
              <Divider variant="line" />
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              padding={(theme) => theme.spacing(6)}
              gap={(theme) => theme.spacing(4)}
              pb={product.summary.pricing ? 0 : 6}
              wrap="nowrap"
            >
              {roomImage && (
                <Grid item xs="auto">
                  <Card sx={{ minWidth: roomImageWidth, maxWidth: roomImageWidth, boxShadow: 0 }}>
                    <CardMedia
                      component="img"
                      alt={product.stay.name}
                      image={roomImage}
                      sx={{ minHeight: roomImageHeight, maxHeight: roomImageHeight }}
                    />
                  </Card>
                </Grid>
              )}
              <Grid item flexGrow={1}>
                <StaysSummaryInclusions stayRoom={selectedRoom} selectedRate={selectedRate} />
              </Grid>
              {screenLg && displayChangeRoomBtn && (
                <Grid item xs="auto">
                  <ChangeRoomBtn />
                </Grid>
              )}
            </Grid>
            {!screenLg && displayChangeRoomBtn && (
              <Box margin={6} marginTop={0}>
                <ChangeRoomBtn />
              </Box>
            )}
            {showPricing &&
              (isMbiStays ? (
                <Box
                  justifyContent={product?.altProductPriceDifference ? 'space-between' : 'flex-end'}
                  display={screenLg || product?.altProductPriceDifference ? 'flex' : 'block'}
                  alignItems="center"
                >
                  <Stack
                    direction={!screenLg && statusTag ? 'row' : 'column'}
                    justifyContent="space-between"
                    alignItems="flex-end"
                    p={4}
                  >
                    {statusTag && (
                      <Box mb={screenLg ? 4 : 2} data-testid="booking-status-tag">
                        <Tag strong={statusTag.color === 'danger'} color={statusTag.color}>
                          {statusTag.text}
                        </Tag>
                      </Box>
                    )}
                    <Stack alignItems="end">
                      <PriceSummary
                        pricing={product.summary.pricing}
                        priceDifference={product?.altProductPriceDifference}
                        isRetailStay
                      />
                      <Typography variant="captionMd">{`${product?.travellerIds?.length} ${
                        product?.travellerIds?.length > 1 ? 'travellers' : 'traveller'
                      }`}</Typography>
                    </Stack>
                  </Stack>
                  {isRetailAlternative && product?.altProductPriceDifference && (
                    <Stack sx={{ paddingRight: '16px' }}>
                      <LoadingButton
                        variant="primary"
                        data-testid="alternative-select-button"
                        loadingText="Loading..."
                        loading={loading}
                        onClick={onSelect(product.offeringId)}
                      >
                        Select
                      </LoadingButton>
                    </Stack>
                  )}
                </Box>
              ) : (
                <Stack pb={4} pr={4}>
                  <PackagePriceTag formattedTotal={PackageTotal} tagText="Package inclusion" />
                </Stack>
              ))}
          </SelectedStayWrapper>
        </Root>
      ) : (
        <Box mt={(theme) => theme.spacing(6)}>
          <SkeletonLoader
            width="25%"
            height="40px"
            variant="text"
            sx={{ borderRadius: 0, transform: 'none', marginBottom: '24px' }}
          />
          <SkeletonLoader
            height="308px"
            variant="text"
            sx={{ borderRadius: 0, transform: 'none', marginBottom: '16px' }}
          />
          <SkeletonLoader
            width="35%"
            height="24px"
            variant="text"
            sx={{ borderRadius: 0, transform: 'none', marginBottom: '16px' }}
          />
          <SkeletonLoader
            width="65%"
            height="24px"
            variant="text"
            sx={{ borderRadius: 0, transform: 'none', marginBottom: '16px' }}
          />
          <SkeletonLoader
            width="65%"
            height="24px"
            variant="text"
            sx={{ borderRadius: 0, transform: 'none', marginBottom: '16px' }}
          />
        </Box>
      )}
    </>
  );
};

export default StaySummary;
