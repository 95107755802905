import React from 'react';
import { Box } from '@mui/material';
import { Button, PrimaryButton } from '@fctg-ds/core';
import { styled } from '@fctg-ds/theme';

interface IProps {
  onFilterResults: () => void;
  onFilterReset: () => void;
  resultsCount: number;
  hasFilters: boolean;
}

/**
 * @deprecated
 */
const StarFooter: React.FC<IProps> = ({ onFilterResults, onFilterReset, resultsCount, hasFilters }) => {
  const StyledBox = styled(Box)(({ theme }) => {
    return {
      borderTop: `1px solid ${theme.token.color.border[' '].value}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.token.spacing[3].value,
    };
  });

  const formatResultCount = (count: number) => {
    return count > 300 ? `300+` : `${count}`;
  };

  return (
    <StyledBox>
      {hasFilters && (
        <Button sx={{ marginRight: '5px' }} onClick={onFilterReset}>
          Clear filters
        </Button>
      )}
      <PrimaryButton onClick={onFilterResults}>Show {formatResultCount(resultsCount)} stays</PrimaryButton>
    </StyledBox>
  );
};

export default StarFooter;
