import React from 'react';
import { Box } from '@mui/material';
import AmenitiesInfo from '@shared/AmenitiesInfo';
import StaysDetails from '@src/components/public/StaysDetails';
import { StayDetailsViewProps } from './StayDetailsView.types';
import StaysRoomOptionsCard from '@src/components/stays/StayRoomOptions/StaysRoomOptionsCard';
import { Divider, useBreakpointMapper } from '@fctg-ds/core';

const StayDetailsView: React.FC<StayDetailsViewProps> = ({ stay, product }) => {
  if (!stay || !product) return <React.Fragment />;
  // const screenLg = useMediaQuery(fc.breakpoints.up('md'));
  const [responsiveImgHeight] = useBreakpointMapper({
    xs: 120,
    sm: 120,
    md: 300,
    lg: 300,
    xl: 300,
  });
  const productRoomSelection = product.rooms.selected?.[0];
  const selectedRoom = product.rooms.choices.find((room) =>
    room.rates.some((rate) => rate.choiceKey === productRoomSelection.choiceKey),
  );

  return (
    <Box>
      <StaysDetails stay={stay} product={product} />
      <Box my={(theme) => theme.spacing(6)}>
        <Divider />
      </Box>
      <AmenitiesInfo small amenities={stay.amenities} facilities={stay.facilities} />
      {selectedRoom && (
        <>
          <Box mt={(theme) => theme.spacing(6)} mb={(theme) => theme.spacing(12)}>
            <Divider />
          </Box>
          <Box mb={(theme) => theme.spacing(6)}>
            <StaysRoomOptionsCard
              fallbackImage={stay.images?.[0].url}
              nights={product.trip.duration.value}
              initalSelection={productRoomSelection}
              stayRoom={selectedRoom}
              selectable={false}
              selectedBorder={false}
              displayOnly={true}
              onAction={() => undefined}
              imgHeight={responsiveImgHeight}
              fullWidth
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default StayDetailsView;
