import React, { useState, useMemo, useEffect } from 'react';
import { Chip, Popover, Drawer, IconButton } from '@fctg-ds/core';
import { fc } from '@fctg-ds/theme';
import { useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PriceFilterFooter from '@stays/SortAndFilters/PriceFilter/PriceFilterFooter';
import PriceFilterBody from '@stays/SortAndFilters/PriceFilter/PriceFilterBody';
import { TChartData, TFilters, TPriceRange, TPriceStats } from '@src/context/ShoppingContext';
import { FilterChipContainer } from '@src/components/stays/SortAndFilters/Shared';

const DEFAULT_FILTER_STATE: TPriceRange = { min: undefined, max: undefined };
const EMPTY_CHART_DATA: TChartData = [{ label: 1, count: 1 }];
const EMPTY_PRICE_STATS: TPriceStats = { lowest: 1, highest: 1, average: 1 };

export type PriceFilterProps = {
  hasAvailability: boolean;
  defaultLabel: string;
  filters: TFilters;
  getResultCount: (range: TPriceRange) => number;
  updateRange: (range: TPriceRange) => void;
  getPriceStats: () => TPriceStats;
  getChartData: () => TChartData;
};

/**
 * @deprecated
 */
const PriceFilter: React.FC<PriceFilterProps> = ({
  hasAvailability,
  defaultLabel,
  filters,
  getResultCount,
  updateRange,
  getPriceStats,
  getChartData,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element>(null);
  const [labelText, setLabelText] = useState<string>(defaultLabel);

  const [range, setRange] = useState<TPriceRange>(DEFAULT_FILTER_STATE);

  const isMobile = useMediaQuery(fc.breakpoints.down('md'));

  const resultsCount: number = useMemo(() => getResultCount(range) ?? 0, [range, active, getResultCount]);
  const priceStats: TPriceStats = useMemo(() => getPriceStats() ?? EMPTY_PRICE_STATS, [active, getPriceStats]);
  const chartData: TChartData = useMemo(() => getChartData() ?? EMPTY_CHART_DATA, [active, getChartData]);

  const hasRange: boolean = priceStats ? range.max !== priceStats.highest || range.min !== priceStats.lowest : false;

  useEffect(() => {
    if (filters && Object.values(filters.price).every((f) => f !== undefined)) {
      setRange(filters.price);
    }
  }, []);

  useEffect(() => {
    if (!range.min && !range.max && priceStats) {
      setRange({ min: priceStats.lowest, max: priceStats.highest });
    }
  }, [priceStats]);

  const handleOpen = (event: React.SyntheticEvent<HTMLDivElement, MouseEvent>) => {
    setActive(true);
    setAnchorEl(event.currentTarget);
    setRange(filters.price);
  };

  const handleClose = () => {
    setActive(false);
    setAnchorEl(null);
  };

  const handleChange = (range: TPriceRange) => {
    setRange(range);
  };

  const generateLabel = (range: TPriceRange): string | null => {
    if (Object.values(range).some((val) => !val)) return null;
    if (range.min === priceStats.lowest && range.max === priceStats.highest) return null;
    return `$${range.min} - $${range.max}`;
  };

  const handleFilterResults = () => {
    handleClose();
    updateRange(range);
    setLabelText(generateLabel(range) ?? defaultLabel);
  };

  const handleFilterReset = () => {
    if (priceStats) {
      setRange({ min: priceStats.lowest, max: priceStats.highest });
    }
  };

  const show = Boolean(anchorEl);

  return (
    <FilterChipContainer>
      <Chip
        data-testid={'price-filter-chip'}
        label={labelText}
        disabled={!hasAvailability}
        isActive={active}
        onClick={hasAvailability ? handleOpen : undefined}
        endIcon={<ExpandMoreOutlinedIcon />}
      />
      {isMobile ? (
        <Drawer
          open={show}
          onOpen={() => undefined}
          onClose={handleClose}
          drawerHeaderTitle="Total Price"
          leftActionBtn={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
          maxHeight={'80%'}
          footer={
            <PriceFilterFooter
              onFilterResults={handleFilterResults}
              onFilterReset={handleFilterReset}
              resultsCount={resultsCount}
              hasFilters={hasRange}
            />
          }
        >
          {chartData && (
            <PriceFilterBody
              priceStats={priceStats}
              chartData={chartData}
              filterRange={range}
              handleChange={handleChange}
            />
          )}
        </Drawer>
      ) : (
        <Popover
          open={show}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          onClose={handleClose}
          footer={
            <PriceFilterFooter
              onFilterResults={handleFilterResults}
              onFilterReset={handleFilterReset}
              resultsCount={resultsCount}
              hasFilters={hasRange}
            />
          }
          sx={{
            '& .MuiPopover-paper': {
              height: 'unset',
            },
          }}
        >
          <PriceFilterBody
            priceStats={priceStats}
            chartData={chartData}
            filterRange={range}
            handleChange={handleChange}
          />
        </Popover>
      )}
    </FilterChipContainer>
  );
};

export default PriceFilter;
